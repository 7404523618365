// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import posed from 'react-pose';
import Loading from 'react-loading';
import { toast } from 'react-toastify';

import ProjectsPortal from './ProjectsPortal';

import '../styles/JobManager.css';

import api from '../api';

const UPDATE_INTERVAL_MS = 3000; // ms

const JobManagerPosed = posed.div({
    searchEnabled: { paddingTop: '34px' },
    searchDisabled: { paddingTop: '0px' }
});

const bots = {
    segment: {
        name: '3D Segmentation',
        killable: true
    },
    radiologyMRI: {
        name: 'MRI Radiology',
        killable: true
    },
    radiologyRWR: {
        name: 'RWR Radiology'
    },
    injury: {
        name: 'Injury Applicator'
    },
    renderer: {
        name: '3D Renderer',
        killable: true
    }
};

export default class JobManager extends Component {

    state = {
        expanded: {},
        generatingReport: false,
        fetchingRWRMeta: false
    }


    constructor(props) {

        super(props);

        this.refreshJob = setInterval(() => {
            this.props.updateJob();
        }, UPDATE_INTERVAL_MS);

    }


    componentWillUnmount = () => {
        clearInterval(this.refreshJob);
    }


    generateReport = async () => {

        await this.setState({ generatingReport: true });

        const res = await api.post('generateRadiologyReport', {
            pId: this.props.pId
        });

        if (res && res.success)
            toast.success("Radiology Report generated and placed in project case files directory. (RWR*-___.pdf)");

        else
            toast.error("Unable to generate report: " + res.error);

        this.setState({ generatingReport: false });

    }


    getRWRForProject = async (pId) => {

        await this.setState({ fetchingRWRMeta: true });

        const res = await api.post('getProject', { pId });

        if (res && res.success) {

            const p = res.data;

            for (let file of p.files)
                if (file.docRef.includes("RWR - " + p.studyType)) {
                    this.setState({ fetchingRWRMeta: false });
                    return String(p.case) + file.path;
                }

        }

        else
            toast.error("Unable to fetch project data: " + res.error);

        this.setState({ fetchingRWRMeta: false });
        return String(this.props.project.case) + "/" + String(this.props.project.studyType);

    }


    getStatusDiv = (status) => {

        let style = {
            color: 'white'
        };

        switch (status) {

            case 'Ready':
                style.backgroundColor = 'rgb(80,80,80)';
                break;

            case 'Started':
                style.backgroundColor = 'rgb(240,200,0)';
                style.color = 'black';
                break;

            case 'Working':
                style.backgroundColor = 'rgb(240,200,0)';
                style.color = 'black';
                break;

            case 'Finished':
                style.backgroundColor = 'rgb(0,180,100)';
                break;

            case 'Error':
                style.backgroundColor = 'rgb(240,80,80)';
                break;

            case 'Killed':
                style.backgroundColor = 'rgb(240,80,80)';
                break;

            case 'Killing':
                style.backgroundColor = 'rgb(240,140,80)';
                break;

            default: // Queued
                style.backgroundColor = 'rgb(0,140,220)';
        }

        return (<div className="jobStatus" style={style}>{status}</div>);

    }

    
    getInjuryStatusIcon = (injuries) => {

        const appliedTrue = injuries.filter(obj => obj.applied === true);
        const appliedFalse = injuries.filter(obj => obj.applied === false);

        if (appliedTrue.length === injuries.length)
            return (<img className="injuryStatusIcon" src="/resources/icons/confirm.png" alt="Success" title="Successfully applied all identified injuries" />);

        else if (appliedFalse.length === injuries.length)
            return (<img className="injuryStatusIcon" src="/resources/icons/reject.png" alt="Failed" title="Failed to apply any of the identified injuries" />);

        else if (appliedFalse.length > 0)
            return (<img className="injuryStatusIcon" src="/resources/icons/alert.png" alt="Alert" title={"Failed to apply: " + appliedFalse.map(obj => obj.type).join(", ")} />);

        else
            return (<div></div>);

    }


    render = () => {

        return (

            <div className="jobManagerContainer" onClick={() => this.props.closeJobManager()} style={{
                left: this.props.opened ? 0 : '100%'
            }}>
                <div className="jobManager" style={{
                    right: this.props.opened ? 0 : '-350px'
                }} onClick={(e) => e.stopPropagation()}>

                    <JobManagerPosed pose={this.props.searchEnabled ? 'searchEnabled' : 'searchDisabled'}>

                        <div className="jobManagerTitle">AI Task Manager</div>
                        
                        <span>Project ID: {this.props.pId}</span>
                        <br/>


                        {/* For each job type */}
                        {Object.keys(bots).map((key,idx) => {
                            
                            if (this.props.job && this.props.job[key] && (this.props.job[key].status || key === "injury")) {

                                const job = this.props.job[key];

                                return (<div key={idx} className="jobManagerTask" onClick={() => {

                                    // this.setState({
                                    //     expanded: {
                                    //         ...this.state.expanded,
                                    //         [key]: job.state !== 'Queued' ? (this.state.expanded[key] ? false : true) : false
                                    //     }
                                    // });
                                        
                                }} style={{
                                    // height: this.state.expanded[key] ? 'auto' : '20px'
                                    height: 'auto'
                                }}>

                                    <div className="jobType">{bots[key].name}{this.getStatusDiv(job.kill ? 'Killing' : key === "injury" && !job.status ? "Ready" : job.status)}
                                    {['Started', 'Working'].includes(job.status) && bots[key].killable && !job.kill ? <div className="jobStatus killJob" onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.killTask(key);
                                    }}>Kill</div> : ""}</div>

                                    {['Started', 'Working'].includes(job.status) && job.progress ? <div className="progressBarContainer">
                                        <div className="progressBar" style={{
                                            width: job.progress + '%'
                                        }}></div>
                                    </div> : ""}

                                    {job.status !== 'Queued' && job.startTime ? <div className="jobField">
                                        <span className="jobLabel">Started at:</span>
                                        <span className="jobValue">{ProjectsPortal.formatDate(job.startTime)} - {ProjectsPortal.formatTime(job.startTime)}</span>
                                    </div> : ""}

                                    {['Finished', 'Error', 'Killed'].includes(job.status) && job.endTime ? <div className="jobField">
                                        <span className="jobLabel">Ended at:</span>
                                        <span className="jobValue">{ProjectsPortal.formatDate(job.endTime)} - {ProjectsPortal.formatTime(job.endTime)}</span>
                                    </div> : ""}

                                    {job.state ? <div className="jobField">
                                        <span className="jobLabel">Status:</span>
                                        <span className="jobValue" title={job.state}>{job.state}</span>
                                    </div> : ""}

                                    {job.error && job.status === 'Error' ? <div className="jobField">
                                        <span className="jobLabel" style={{ color: 'red' }}>Error:</span>
                                        <span className="jobValue" style={{ color: 'red' }} title={job.error}>{job.error}</span>
                                    </div> : ""}

                                    {job.worker ? <div className="jobField">
                                        <span className="jobLabel">Worker:</span>
                                        <span className="jobValue">{job.worker}</span>
                                    </div> : ""}

                                    {key === 'renderer' ? <div className="jobField">
                                        <span className="jobLabel">Assignee:</span>
                                        <span className="jobValue">{this.props.users[this.props.project.ai.assigningUser] ? this.props.users[this.props.project.ai.assigningUser].name : "Undefined" }</span>
                                    </div> : ""}

                                    {key === 'renderer' ? <div className="jobField">
                                        <span className="jobLabel">Project ID:</span>
                                        <span className="jobValue">{this.props.project._id}</span>
                                    </div> : ""}

                                    {key === 'injury' && job.injuries ? <div className="jobField" style={{ height: 'auto' }}>
                                        <span className="jobLabel">Injuries:</span>
                                        {job.injuries.length === 0 ? <span className="jobValue" style={{ color: 'rgb(140,140,140)' }}><i>No Pathologies Identified</i></span> : <div className="jobValueBlock">
                                            {job.injuries.map((injuryLevel, idx) => <div
                                                key={idx}
                                                className="jobValueLine"
                                                title={"[" + Array.isArray(injuryLevel.objects) && injuryLevel.objects?.length ? injuryLevel.objects.map(obj => obj.type).join(', ') + "]" + (injuryLevel.summary ? " " + injuryLevel.summary : ""): injuryLevel.summary}
                                            >{injuryLevel.objects?this.getInjuryStatusIcon(injuryLevel.objects):""} {injuryLevel.location} 
                                            <u>({injuryLevel.objects?.length})</u>
                                             {injuryLevel.summary ? <img className="injuryStatusIcon injurySummaryExistsIcon" src="/resources/icons/text.png" alt="[Summary]" /> : ""}
                                            </div>)
                                            }
                                        </div>}
                                        
                                    </div> : ""}

                                    {job.source ? <div className="jobField">
                                        <span className="jobLabel">Source:</span>
                                        <span className="jobValue">{job.source}</span>
                                    </div> : ""}

                                    {key === "injury" ? <div className="jobField">
                                        <span className="jobLabel">Review:</span>
                                        {this.state.fetchingRWRMeta ? <div
                                            className="jobActionLoading"
                                        >
                                            <Loading type="spin" color="rgba(30,155,230,0.5)" height={'16px'} width={'16px'} />
                                        </div> : <div
                                            className="jobAction jobReview"
                                            onClick={async (e) => {

                                                e.stopPropagation();

                                                const rwr = await this.getRWRForProject(this.props.pId);
                                                
                                                window.open('/files/' + rwr, '_blank');
                                            }}
                                        >Review Injuries</div>}
                                    </div> : ""}

                                    {key === "injury" && job.source === "AI:Radiology (MRI)" ? <div className="jobField">
                                        <span className="jobLabel">Actions:</span>
                                        {this.state.generatingReport ? <div
                                            className="jobActionLoading"
                                        >
                                            <Loading type="spin" color="rgba(230,155,30,0.5)" height={'16px'} width={'16px'} />
                                        </div> : <div
                                            className="jobAction"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.generateReport()
                                            }}
                                        >Generate Report</div>}
                                    </div> : ""}

                                </div>);

                            }

                            return "";
                        })}

                    </JobManagerPosed>

                </div>
            </div>

        )

    }
}