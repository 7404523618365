import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'
// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
 
ReactDOM.render(<App />, document.getElementById('root'));