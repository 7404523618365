// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import api from '../api';

import '../styles/Login.css';

export default class Login extends Component {

    state = {
        error: '',
        email: '',
        password: ''
    }

    onLoginSubmit = async (event) => {
        event.preventDefault();

        let res = await api.login(this.state.email, this.state.password);

        if (res && res.success && res.auth) {

            this.props.onUserChange(res.auth);

            // Direct user to the correct portal
            switch (res.auth.type) {

                case 'lead':
                    return this.props.history.push('/lead');

                case 'center':
                    return this.props.history.push('/center');
                
                case 'endUser':
                    return this.props.history.push('/centerenduser');

                case 'ops':
                    return this.props.history.push('/');

                default:
                    return this.props.history.push('/review');

            }
        }

        this.setState({
            error: res.error,
            password: ''
        });
    }

    onForgotUserPasswordClick(event) {
        event.preventDefault();
    }

    render() {
        return (
            <div className="loginContainer">

                {/* Multus Medical Advertising */}
                <div className="loginAdvertising">
                    <img src="/resources/multus/cervical.png" style={{
                        width: '80%',
                        height: 'auto',
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%,-50%)',
                    }} alt="Multus Medical Advertising" />

                    <div style={{
                        backgroundImage: 'url("/resources/logo.png")',
                        backgroundSize: '40px 40px',
                        backgroundPosition: '10px 10px',
                        backgroundRepeat: 'no-repeat',
                        height: '40px',
                        padding: '10px',
                        borderRadius: '10px',
                        backgroundColor: 'rgba(80,80,80,0.7)',
                        paddingLeft: '60px',
                        textTransform: 'uppercase',
                        fontSize: '24px',
                        lineHeight: '40px',
                        letterSpacing: '2px',
                        position: 'absolute',
                        color: 'rgb(220,220,220)',
                        width: '212px',
                        left: '50%',
                        marginLeft: '-160px',
                        top: '45%'
                    }}><span style={{ fontWeight: 500 }}>Multus</span>Medical</div>
                </div>

                {/* Multus Medical Login */}
                <div className="loginForm">
                    <h1><span style={{ fontWeight: 500 }}>Multus</span>Portal Login</h1>

                    <div ref="loginError" className="loginError">{this.state.error}</div>

                    <form method="POST" onSubmit={this.onLoginSubmit}>
                        <input type="text" name="email" id="email" placeholder="Email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />

                        <input type="password" name="password" id="password" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} />
                        
                        <input type="submit" value="Login" />
                    </form>

                    <div className="link" onClick={this.onForgotUserPasswordClick}>Forgot email or password?</div>
                </div>

            </div>
        );
    }

}