// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component, useCallback } from 'react';
import posed from 'react-pose';
import Loading from 'react-loading';

import SearchBar from '../../SearchBar';
import DataHeader from '../../DataHeader';
// import OpsTasks from './OpsTasks';

import api from '../../../api';

import '../../../styles/StatsPortal.css';
import { toast } from 'react-toastify';

import Dropzone from 'react-dropzone'



// Posed div for search bar
const TasksPosed = posed.div({
    searchEnabled: { paddingTop: '34px' },
    searchDisabled: { paddingTop: '0px' }
});

const TODAY = new Date();





export default class StatsPortal extends Component {

    constructor(props) {

        super(props);

        // Initial state
        this.state = {
            center:"multus",
            statsing:false
        };

        this.state.stats = {

            
        }
        // // Bind child functions
        // this.onHeaderFieldClick = this.onHeaderFieldClick.bind(this);
        // this.onSearchEvent = this.onSearchEvent.bind(this);
        // this.toggleSearch = this.toggleSearch.bind(this);

        // this.iconOffset = '0px';

    }

  





 handleStats = async (acceptedFiles) => {
                    
    
    
    }




    fetchStats = async (acceptedFiles) => {
                    
    
        // Get stats
        let res = await api.post('getStarkStats');

        if (!res || !res.data)
            return;

        await this.setState({
            stats: res.data
        });
    
    }


    componentDidMount = async () => {

        // Fetch stats
        let res = await this.fetchStats();

        // if (!res || !res.auth)
        //     window.location = "/login";

        // if (!res.auth._id)
        //     window.location = "/login";
        
        // else if (res.auth.type !== 'ops')
        //     window.hi
        
        // story.back();
    }

    render() {


        // const onDrop = useCallback(acceptedFiles => {
        //     // Do something with the files
        //   }, [])
        //   const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    



        return (


            <div className="statsRootContainer">
            <div className="statsContainer">
                <div className="statsUI">
          stats go here
 </div>
           
           
           </div>
   

            
                </div>
        );

    }

}