// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import './style.css';

// Transition system "borrowed" from Stax.ai (dev & owned by Aptus)

export default class OnePortalAccessStudy extends React.Component {

    componentDidMount = () => {
        if (this.props.project === undefined) {
            this.props.unsetOverlay()
        }
    }

    render = () => {

        // Don't render if no project attached
        if (this.props.project === undefined) {
            return <div></div>
        }


        // Thumnail for colorized illustrations
        let renderColorizedIllustrations = false;
        if (this.props.project && 
            this.props.project.files && 
            Array.isArray(this.props.project.files) && 
            this.props.project.studyType
        ) {

            // Find a colorized illustration
            const found = this.props.project.files.find(fileData => {

                if (fileData && fileData.path && fileData.path.includes(this.props.project.studyType + "Colorized"))
                    return true;
                return false;

            });

            if (found)
                renderColorizedIllustrations = true;

        }


        return (<div className="modal-container">
            <div className="modal-content">
                
                <div className="study-menu">
                    <div className="study-menu-option"
                        onClick={async () => {
                            this.props.history.push(this.props.buildURLPathToFile(this.props.project.videoBlobPath))
                            this.props.unsetOverlay()
                        }}
                    >
                        <div
                            className="study-menu-3d-bg"
                            style={{
                                backgroundImage: "url('/thumb/" + this.props.studyType + ".png')"
                            }}
                        ><img src="/resources/icons/fileTypes/mp4.png" alt="Video" /></div>
                        <h1>3D Demonstration Video</h1>
                    </div>
                    
                    {/* Animation Video Link *** Disabled due to higher-level check -BK */}
                    {/* {this.props.project.animVideoBlobPath !== undefined &&
                        <div className="study-menu-option"
                            onClick={() => {
                                this.props.history.push(this.props.buildURLPathToFile(this.props.project.animVideoBlobPath))
                                this.props.unsetOverlay()
                            }}
                        >
                            <img
                                src="/thumb/Animation.png"
                                alt="Video"
                            /><br />
                            <h1>3D Surgical Animation Video</h1>
                        </div>
                    } */}

                    <div className="study-menu-option"
                        onClick={() => {
                            this.props.history.push(this.props.buildURLPathToFolder("Images"))
                            this.props.unsetOverlay()
                        }}
                    >
                        <div
                            className="study-menu-3d-bg"
                            style={{
                                backgroundImage: "url('/thumb/" + this.props.studyType + ".png')"
                            }}
                        ><img src="/resources/icons/fileTypes/png.png" alt="Captures" /></div>
                        <h1>Captured Key Images</h1>
                    </div>

                    {renderColorizedIllustrations && <div className="study-menu-option"
                        onClick={() => {
                            this.props.unsetOverlay()
                        }}
                    >
                        <div
                            className="study-menu-3d-bg"
                            style={{
                                backgroundImage: "url('/thumb/" + this.props.studyType + ".png')"
                            }}
                        ><img src="/resources/icons/fileTypes/png.png" alt="Colorized" /></div>
                        <h1>Colorized Illustrations</h1>
                    </div>}

                    {this.props.project && this.props.project.viewer3d && <div className="study-menu-option"
                        onClick={() => {
                            this.props.history.push(this.props.buildURLPathToFile("view.3d"))
                            this.props.unsetOverlay()
                        }}
                    >
                        <div
                            className="study-menu-3d-bg"
                            style={{
                                backgroundImage: "url('/thumb/" + this.props.studyType + ".png')"
                            }}
                        ><img src="/thumb/3d.png" alt="3D" /></div>
                        <h1>Interactive 3D Viewer</h1>
                    </div>}

                    {this.props.rwrPath && <div className="study-menu-option"
                        onClick={() => {
                            this.props.history.push(this.props.rwrPath);
                            this.props.unsetOverlay();
                        }}
                    >
                        <img
                            src="/thumb/Report.png"
                            alt="RWR"
                        /><br />
                        <h1>Radiology Report</h1>
                    </div>}

                    <div style={{ clear: 'both', height: 150 }} />

                    <div className="case-menu-options">
                        <div
                            className="case-menu-link"
                            onClick={() => {
                                let locationArray = document.location.href.split("/")
                                this.props.history.push("/files/" + locationArray[locationArray.length - 2])
                            }}
                        >Back to Study Types</div>

                        <div
                            className="case-menu-link"
                            onClick={this.props.unsetOverlay}
                        >Navigate Case Files</div>
                    </div>
                </div>

            </div>
        </div>);
    }

};