// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import '../styles/ProjectsPortal.css';

export default class DataHeader extends Component {


    render() {


        // Set the sorting arrow orientation
        let arrow = '⬇ ';
        if (this.props.currentSort.direction === 1) {
            arrow = '⬆ ';
        }

        // Define the arrow indicators
        var arrows = {};

        Object.keys(this.props.columns).forEach( (field) => {
            if (field === this.props.currentSort.field)
                arrows[field] = arrow;

            else
                arrows[field] = '';
        })

        // Note: Only implement onclick for the elements in the non disabledColumns

        const page = this.props.page;
        const show = this.props.numInPage;
        const total = this.props.totalCount;

        return (

            <div className="projectsHeader">

                <div style={{
                    width: 'calc(100% - ' + this.props.iconOffset + ')'
                }}>

                    {/* Field Names */}
                    {Object.keys(this.props.columns).map((key, idx) => <div key={'header-'+key} className="projectsHeaderItem" onClick={(e) => this.props.clickHandler(e,key)} style={{
                        width: this.props.columns[key].width + "%",
                        maxWidth: this.props.columns[key].maxWidth + "px"
                    }}>
                        {arrows[key]} {this.props.columns[key].label}
                    </div>)}

                </div>
                
                {/* Search Bar Icon */}
                <div className="projectsHeaderItemOther" style={{float: 'right'}} >
                    <div className="paging">
                        {page > 0 ? <div className="pagingArrow" onClick={() => this.props.setPage(page-1)}>« </div> : ''}
                        {page*show+1} - {Math.min((page+1)*show,total)} of {total}
                        {page*(show+1) < total ? <div className="pagingArrow" onClick={() => this.props.setPage(page+1)}> »</div> : ''}
                    </div>
                    <img onClick={() => this.props.toggleSearch()} src="/resources/icons/search.png" alt="Search" />
                </div>

            </div>

        )

    }
}

DataHeader.defaultProps = {
    currentSort: {field: "", direction: 0},
    columns: {},
    disabledColumns: [],
    clickHandler: () => {return;},
    toggleSearch: () => {return;}

}