// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import posed from 'react-pose';

import '../styles/SearchBar.css';

// Display search bar
const SearchBarPosed = posed.div({
    disabled: { height: '0px' },
    enabled: { height: '34px' }
});

export default class SearchBar extends Component {

    // Creat input box
    createInput = (label, width, maxWidth) => {
        return <input type='text' name={label} onChange = {(e) => this.props.searchHandler(e)} 
        
        
        onKeyDown={(e) => {
            

            if (e.key === 'Enter') {
                //e.preventDefault();
                  
            this.props.searchAllHandler(e, label)
                  e.target.blur(); 
                //  saveProcedure()
                // Do code here
              }

              
        }
        
        }
          />
    }

    render() {

        return (
        
        <SearchBarPosed className="searchBar" pose={this.props.searchEnabled ? 'enabled' : 'disabled'}>

            <div style={{
                width: 'calc(100% - ' + this.props.iconOffset + ')',
                float: 'left'
            }}>

                {/* Render Input Boxes */}
                {Object.keys(this.props.columns).map((key, idx) => (
                    <div className="searchBarField" key={key} style={{
                        width: "calc(" + this.props.columns[key].width + "% - 2px)",
                        maxWidth: "calc(" + this.props.columns[key].maxWidth +"px - 2px)", 
                        display: "inline-block", 
                        height: "80%"}}>
                        {this.createInput(key, this.props.columns[key]["width"], this.props.columns[key]["maxWidth"])}
                    </div>
                ))}

            </div>

            <div title="Coming soon! :)" className="advancedSearch">Advanced Search</div>

        </SearchBarPosed>);
    }
}

SearchBar.defaultProps = {
    searchEnabled: false,
    columns: {},
    searchHandler: () => {return;}
}
