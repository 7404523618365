// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Switch from 'react-switch';


import '../../../styles/Users.css';

const endUserTypes = {
    "all": "Show all",
    "physician": "Physician",
    "attorney": "Attorney"
};

export default class UserCard extends Component {

    state = {
        expanded: false,
        newPassword: null
    }

    getUserCardStyleModifier = (userType) => {

        let style = {};

        if (this.state.expanded) {

            if (userType === 'radiologist')
                style.width = '900px';
            
            else if (userType === 'endUser')
                style.width = '650px';

            else
                style.width = '536px';
        }
        
        else
            style.width = '345px';
        
        return style;
    }

    getUserDataStyleModifier = () => {
        
        let style = {
            opacity: this.state.expanded ? '1' : '0'
        };

        return style;
    }

    uploadImageAsBase64URL = async (field) => {

        // Field is either "signature" or "logo"

        let fileUpload = document.createElement('input');
        fileUpload.setAttribute('type', 'file');
        fileUpload.setAttribute('name', 'caseFile');
        fileUpload.setAttribute('accept', 'image/jpeg,image/gif,image/png');

        fileUpload.onchange = async (e) => {

            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);

            reader.onload = async () => {
                
                if (reader.result.length > 60000)
                    return this.props.toast.error("Selected file is too large!");
                
                this.props.updateUserData(this.props.user._id, field, reader.result);
            }

        }

        // Trigger on upload
        fileUpload.click();

    }

    renderUserDataDiv = () => {

        const user = this.props.user;

        if (user.type === "radiologist") {

            return (
                <div className="userDataContent">
                    <div className="userDataLabel">Title Line 1</div>
                    <input type="text" name="radDisplayName" placeholder="Dr. Hello World, M.D" value={user.data.nameWithTitle} onChange={(e) => this.props.updateUserData(user._id, "nameWithTitle", e.target.value)} /><br />

                    <div className="userDataLabel">Title Line 2</div>
                    <input type="text" name="radDisplayTitle" placeholder="XYZ Radiology Center" value={user.data.qualifications} onChange={(e) => this.props.updateUserData(user._id, "qualifications", e.target.value)} /><br />

                    <div className="userDataLabel">Education</div>
                    <input type="text" name="radDisplayName" placeholder="Paragraph describing medical education history" value={user.data.education} style={{ width: '436px' }} onChange={(e) => this.props.updateUserData(user._id, "education", e.target.value)} /><br />

                    <div className="userDataLabel">Experience</div>
                    <input type="text" name="radDisplayName" placeholder="Paragraph describing medical experience history" value={user.data.experience} style={{ width: '436px'}} onChange={(e) => this.props.updateUserData(user._id, "experience", e.target.value)} /><br />

                    <div className="radImage" style={{
                        left: '260px',
                        backgroundImage: 'url("' + user.data.signature + '")'
                    }}>
                        <div className="radImageOverlay" onClick={() => this.uploadImageAsBase64URL("signature")}>Signature</div>
                    </div>

                    <div className="radImage" style={{
                        left: '400px',
                        backgroundImage: 'url("' + user.data.logo + '")'
                    }}>
                        <div className="radImageOverlay" onClick={() => this.uploadImageAsBase64URL("logo")}>Logo</div>
                    </div>
                </div>
            );

        }

        else if (user.type === "center") {

            return (
                <div className="userDataContent">
                    <div className="userDataLabel" style={{ width: '200px' }}>Center Group</div>
                    <input type="text" name="centerGroup" placeholder="Center Group Code" value={user.data.group} onChange={(e) => this.props.updateUserData(user._id, "group", e.target.value)} />
                    <br />
                    <div className="switchContainer" style={{paddingTop: "5px"}}>
                        <Switch className="switchCheckbox" onChange={checked => this.props.updateUserData(user._id, "caseFileAccess", checked)} checked={typeof user.data.caseFileAccess !== 'undefined' ? user.data.caseFileAccess : false} height={20} width={40} />
                        <div className="switchLabel">Case file access</div>
                    </div>
                </div>
            );

        }

        else if (user.type === "client") {

            return (
                <div className="userDataContent">
                    <div className="userDataLabel" style={{ width: '200px' }}>Client Account</div>
                    <select className="userDataSelect" value={user.data.client} onChange={(e) => this.props.updateUserData(user._id, "client", e.target.value)}>
                        <option value="">Select Client</option>
                        {this.props.clients.map((client,idx) => 
                            <option key={idx} value={client._id}>{client.name}</option>
                        )}
                    </select>
                </div>
            );

        }

        else if (user.type === "endUser") {

            
            // Center View:
            if (this.props.viewMode === 'center') {
                // No need to show extra data
            }

            // Ops View: 
            else {
                return (
                    <div className="userDataContent">
                        <div className="userDataLabel" >Group</div>
                        <input type="text" name="centerGroup" placeholder="Center Group Code" value={user.data.group} disabled />
                        <br />
                        <div className="userDataLabel">Type</div>
                        <input type="text" name="type" value={endUserTypes[user.data.type]} disabled style={{ marginBottom: 0 }} />
                    </div>

                );
            }
        }

        // No user data for other types

    }

    render() {

        const user = this.props.user;

        return (
            <div className={"userCard" + (this.props.changed ? " userInfoChanged" : "")} style={this.getUserCardStyleModifier(user.type)}>

                <div className="userCardLabel">Name</div>
                <input type="text" name="name" value={user.name} placeholder="Enter full name" onChange={(e) => this.props.updateUserInfo(user._id, "name", e.target.value)} /><br />

                <div className="userCardLabel">E-mail</div>
                <input type="text" name="email" value={user.email} placeholder="Enter e-mail address" onChange={(e) => this.props.updateUserInfo(user._id, "email", e.target.value)} /><br />

                <div className="userCardLabel">Password</div>
                {this.state.newPassword !== null ? <input type="password" name="password" value={this.state.newPassword} onChange={(e) => {
                    this.setState({ newPassword: e.target.value });
                }} placeholder="Enter new password" /> : 
                <input type="password" name="password" value="secretPassword" onChange={(e) => {}} placeholder="Enter new password" />}<br />

                <div className="userCardLabel">User Type</div>
                <input type="text" name="type" value={(this.props.viewMode === 'center' && user.type === 'endUser'? endUserTypes[user.data.type]: this.props.userTypes[user.type])} disabled style={{ marginBottom: 0 }} />

                {this.state.newPassword === null ? <div className="resetPasswordButton" onClick={() => this.setState({ newPassword: '' })}>Change Password</div> : ''}

                <div className="userDataContainer" style={this.getUserDataStyleModifier()}>
                    {this.renderUserDataDiv()}
                </div>

                {this.state.expanded ? 
                <div className="userButton userExpandButton" onClick={() => this.setState({ expanded: false })}>«&nbsp;&nbsp;Less</div> : ''}

                {!this.state.expanded && (user.type === 'center' || user.type === 'radiologist' || user.type === 'client' || (this.props.viewMode !== 'center' && user.type === 'endUser')) ? <div className="userButton userExpandButton" onClick={() => this.setState({ expanded: true })}>More&nbsp;&nbsp;»</div> : ''}
                
                {!this.state.newPassword && this.props.changed ? <div className="userButton userSaveButton" onClick={() => this.props.saveUserInfo(user._id)}>Save</div> : ''}

                {this.state.newPassword ? <div className="userButton userConfirmButton" onClick={async () => {
                    await this.props.resetUserPassword(user._id, this.state.newPassword);
                    this.setState({ newPassword: null });
                }}>Confirm</div> : ''}
                
                <div className="userButton userDeleteButton" onClick={() => this.props.deleteUser(user._id)}>Delete</div>
            </div>
        );
    }

}