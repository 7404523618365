

export default (date, showSeconds=true) => {

    if (!showSeconds) {
        return new Date(Date.parse(date)).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        });
    }
    else {
        return new Date(Date.parse(date)).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    }
}