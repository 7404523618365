// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../../api';

import '../../../styles/Console.css';
import '../../../styles/Users.css';



export default class UsersConsole extends Component {

    state = {
        filterNameEmail: '',
        filterType: 'all',
        newUser: null

    }

    componentDidMount = async () => {

        await this.props.fetchUsers();
        await this.applyUsersFilter();

    }
 

    applyUsersFilter = async () => {

        let unfiltered = [...Array(this.props.users.length).keys()];

        let filtered = [];

        for (let userIdx of unfiltered) {

            let user = this.props.users[userIdx];

            if ((this.state.filterType === "all" || this.state.filterType === user.type) &&
                (user.name.toLowerCase().includes(this.state.filterNameEmail) || 
                user.email.toLowerCase().includes(this.state.filterNameEmail)))
                filtered.push(userIdx);
        }

        this.props.setFilteredUsers(filtered);

    }

    createNewUser = async () => {

        let newUserName = this.state.newUser.name;

        // Create new user in the parent
        await this.props.createNewUser(this.state.newUser);

        this.setState({
            newUser: null
        });

        this.applyUsersFilter();

        toast.info('Created new user: ' + newUserName);

    }


    resetUserPassword = async (userId, password) => {

        // Call api reset password
        let res = await api.post('resetPassword', {
            userId: userId,
            password: password
        });

        if (!res)
            return
            
        if (!res.success)
            return toast.error(res.error);

        toast.info('Reset password for: ' + this.props.users.find(user => user._id === userId).name);
    }


    deleteUser = async (userId) => {

        let user = {...this.props.users.find(user => user._id === userId)};

        // Delete in API
        let res = await api.post('deleteUser', {
            userId: userId
        });

        if (!res)
            return
            
        if (!res.success)
            return toast.error(res.error);

        // Remove the user from users
        let users = [...this.props.users];

        for (let idx=0; idx<users.length; idx++) {
            if (users[idx]._id === userId) {
                users.splice(idx,1);
                break;
            }
        }

        // Temp filtered users
        await this.props.setFilteredUsers([]);

        // Set the new users
        await this.props.updateUsers(users, null)

        // Refilter users
        this.applyUsersFilter()


        toast.info('Deleted user: ' + user.name);

    }

    // TODO @Marcel: The following functions may be able to live within this component... 
    saveUserInfo = async (userId) => {

        let user = this.props.users.find(user => user._id === userId);

        // Call the prop function
        await this.props.saveUserInfo(userId);

        toast.info('Saved user: ' + user.name);
    }


    updateUserData = async (userId, field, value) => {

        let users = [...this.props.users];

        let user = users.find(user => user._id === userId);
        user.data[field] = value;

        // Update prop users
        await this.props.updateUsers(users, userId);

    }

    updateUserInfo = async (userId, field, value) => {

        console.log("Inside the innner update user info!")

        let users = [...this.props.users];

        let user = users.find(user => user._id === userId);
        user[field] = value;

        // Update prop users
        await this.props.updateUsers(users, userId);

    }


    render () {

        // UserCard props
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                // Functions to handle UserCard functionality generically before passing to parent
                resetUserPassword: this.resetUserPassword,
                updateUserInfo: this.updateUserInfo,
                updateUserData: this.updateUserData,
                deleteUser: this.deleteUser
            })
        })


        return (
            <div>
                
                <div className="consoleHeader">
                    <select className="userFilterType" value={this.state.filterType} onChange={async (e) => {
                        await this.setState({ filterType: e.target.value });
                        this.applyUsersFilter();
                    }}>
                        {Object.keys(this.props.userTypes).map((key,idx) => 
                            <option key={idx} value={key}>{this.props.userTypes[key]}</option>
                        )}
                    </select>

                    <input className="userFilterSearch" type="text" name="filter" placeholder="Filter..." value={this.state.filterNameEmail} onChange={async (e) => {
                        await this.setState({ filterNameEmail: e.target.value });
                        this.applyUsersFilter();
                    }} />

                    {this.state.newUser ? '' : <div className="consoleHeaderButton" onClick={() => this.setState({
                        newUser: {
                            name: "",
                            email: "",
                            password: "",
                            type: Object.keys(this.props.userTypes)[1]
                        }
                    })}>New User</div>}
                </div>

                <div className="consoleContainer">

                    {/* All children from wrapper component */}
                    {children ? children : ""}


                    {/* New user card */}
                    {this.state.newUser ? <div className="userCard">

                        <div className="userCardLabel">Name</div>
                        <input type="text" name="newUserName" placeholder="Enter full name" value={this.state.newUser.name} onChange={(e) => this.setState({ newUser: {...this.state.newUser, name: e.target.value} })} /><br />

                        <div className="userCardLabel">E-mail</div>
                        <input type="text" name="newUserEmail" placeholder="Enter e-mail address" value={this.state.newUser.email} onChange={(e) => this.setState({ newUser: {...this.state.newUser, email: e.target.value} })} /><br />

                        <div className="userCardLabel">Password</div>
                        <input type="password" name="newUserPassword" placeholder="Enter password" value={this.state.newUser.password} onChange={(e) => this.setState({ newUser: {...this.state.newUser, password: e.target.value} })} /><br />

                        <div className="userCardLabel">User Type</div>
                        <select className="userTypeSelect" value={this.state.newUser.type} onChange={(e) => this.setState({ newUser: {...this.state.newUser, type: e.target.value} })}>
                            {Object.keys(this.props.userTypes).map((type,idx) => type !== "all" ?
                                <option key={idx} value={type}>{this.props.userTypes[type]}</option> : ''
                            )}
                        </select>
                        
                        <div className="userButton userSaveButton" onClick={() => this.createNewUser()}>Create</div>
                        <div className="userButton userDeleteButton" onClick={() => this.setState({ newUser: null })}>Cancel</div>
                    </div> : ''}


                </div>

            </div>

            
            
        )
    }


}