// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import '../../../styles/InjuryEditor.css'

const discInjuries = {
    'Impingement': {
        props: {
            severity: ["Not Found", "mild", "moderate", "severe"],
            acuity: [true, false],
            herniation: [true, false]
        },
        default: {
            type: "Impingement",
            severity: "Not Found",
            acuity: true,
            herniation: true
        }
    },
    'ForaminalStenosis': {
        props: {
            severity: ["Not Found", "mild", "moderate", "severe"],
            side: ["biforaminal", "left", "right"]
        },
        default: {
            type: "ForaminalStenosis",
            severity: "Not Found",
            side: "biforaminal"
        }
    },
    'AnnularTear': {
        props: {
            direction: ["central", "left", "right", "bilateral"],
            side: ["posterior", "anterior", "none"]
        },
        default: {
            type: "AnnularTear",
            direction: "central",
            side: "posterior"
        }
    }
};


const shoulderMuscularInjuries = {
    'Impingement': {
        props: {
            severity: ["tendinosis", "full", "partial"],
            // acuity: [true, false],
            // herniation: [true, false]
        },
        default: {
            type: "Impingement",
            severity: "tendinosis",
            // acuity: true,
            // herniation: true
        }
    }
};

const shoulderLabrumInjuries = {
    'Impingement': {
        props: {
            severity: ["slap", "fraying"]
        },
        default: {
            type: "Impingement",
            severity: "slap"
        }
    }
};

const shoulderBursaInjuries = {
    'Bursitis': {
        props: {
            severity: ["Not Found", "mild", "moderate", "severe"],
        },
        default: {
            type: "Bursitis",
            severity: "Not Found"
        }
    }
};


const shoulderBicepsInjuries = {
    'Tendinosis': {
        props: {
            severity: ["Not Found", "mild", "moderate", "severe"],
        },
        default: {
            type: "Tendinosis",
            severity: "Not Found"
        }
    }
};

const shoulderAcromioclavicularInjuries = {
    'Tendinosis': {
        props: {
            severity: ["Not Found", "mild", "moderate", "severe"],
        },
        default: {
            type: "Tendinosis",
            severity: "Not Found"
        }
    }
};

const globalInjuries = {
    'CustomInjury': {
        props: {},
        default: {
            type: "CustomInjury"
        }
    },
    // 'Hyperlordosis': {
    //     props: {},
    //     default: {
    //         type: "Hyperlordosis"
    //     }
    // }
};

// Display aliases for injury properties
const injuryAliases = {

    'Impingement': {

        acuity: {
            alias: 'cord color', 
            valueAliases: {true: "red", false: "yellow"}
        }
    },
}

// Summary Paste Regex
const breakCharRegex = /\r?\n|\r/g
const whiteSpaceRegex = /\s+/g;


class InjuryLocation extends Component {
    
    state = {
        opened: true
    }

    render = () => {

        const injuryGroup = this.props.level.includes('Disc') ? 
        discInjuries :
        (this.props.level.includes('Supraspinatus') || this.props.level.includes('Infraspinatus') || this.props.level.includes('Subscapularis')) ?
        shoulderMuscularInjuries : 
        (this.props.level.includes('Labrum')) ?
        shoulderLabrumInjuries : 
        (this.props.level.includes('Bursa')) ?
        shoulderBursaInjuries : 
        (this.props.level.includes('Biceps')) ?
        shoulderBicepsInjuries : 
        (this.props.level.includes('Acromioclavicular')) ?
        shoulderAcromioclavicularInjuries : 
        globalInjuries;
        
        return (<div
            className="injuryLocation"
            style={!this.state.opened ? {
                height: '18px'
            } : {}}
        >

            <div className="locationHeader"
                onClick={() => this.setState({ opened: !this.state.opened })}
                style={this.state.opened ? {
                    backgroundColor: 'rgb(60,60,60)'
                } : {}}
            >{this.state.opened ? '▾' : '▸' }&nbsp;&nbsp;{this.props.level} <div style={{ float: 'right' }}>{this.props.injuries && this.props.injuries.objects ? "(" + String(this.props.injuries.objects.length) + ")" : "" }</div></div>

            {Object.keys(injuryGroup).map((injuryType,idx) => {
                
                const injury = this.props.injuries && this.props.injuries.objects && this.props.injuries.objects.find(i => i.type === injuryType);
                const propertyAliases = Object.keys(injuryAliases).includes(injuryType) ? injuryAliases[injuryType] : {};
                return (<div
                    key={idx}
                    className="injuryObject"
                >

                    <div
                        className={"injuryHeader" + (injury ? " injuryHeaderActive" : "")}
                    >
                        {injuryType}
                        <img
                            onClick={() => {

                                // Delete injury if already exists
                                if (injury) {

                                    let injuries = this.props.injuries;
                                    let injuryIdx = -1;

                                    for (let i=0; i<injuries.objects.length; i++)
                                        if (injuries.objects[i].type === injuryType) {
                                            injuryIdx = i;
                                            break;
                                        }

                                    if (injuryIdx >= 0)
                                        injuries.objects.splice(injuryIdx, 1);

                                    this.props.editInjuries(this.props.level, injuries);

                                }

                                // Add new injury if doesn't exist
                                else {

                                    let injuries = this.props.injuries ? this.props.injuries : {
                                        location: this.props.level,
                                        summary: "",
                                        objects: []
                                    };

                                    this.props.editInjuries(this.props.level, {
                                        ...injuries,
                                        objects: [
                                            ...(injuries.objects ? injuries.objects : []),
                                            {...injuryGroup[injuryType].default}
                                        ]
                                    });
                                }

                            }}
                            src={"/resources/icons/" + (injury ? "reject" : "add") + ".png"} alt={injury ? "Delete" : "Add"}
                        />
                    </div>

                    {injury && Object.keys(injuryGroup[injuryType].props).map((prop, idx) => <div
                        key={idx}
                        className="injuryProp"
                        style={idx === 0 ? {
                            marginTop: '5px'
                        } : {}}
                    >
                        <div className="injuryPropLabel">
                            {(propertyAliases[prop] && propertyAliases[prop].alias)? propertyAliases[prop].alias: prop}
                        </div>

                        <select
                            className="injuryPropSelect"
                            value={String(injury[prop])}
                            onChange={(e) => {

                                const injObjects = this.props.injuries.objects;
                                
                                for (let i=0; i<injObjects.length; i++)
                                    if (injObjects[i].type === injury.type) {
                                        injObjects[i][prop] = e.target.value
                                        break;
                                    }

                                this.props.editInjuries(this.props.level, {
                                    ...this.props.injuries,
                                    injObjects
                                });
                            }}
                        >
                            {injuryGroup[injuryType].props[prop].map((option,idx) => <option
                                key={idx}
                                value={option}
                            >{ ( propertyAliases[prop] && propertyAliases[prop].valueAliases && propertyAliases[prop].valueAliases[String(option)]) ? propertyAliases[prop].valueAliases[String(option)] : String(option)}</option>)}
                        </select>
                    </div>)}

                </div>);
            })}

            {<textarea
                ref='summaryTxtArea'
                value={this.props.injuries && this.props.injuries.summary}
                onChange={async (e) => {

                    const value = e.target.value;
                    const area = this.refs.summaryTxtArea;
                    let leftSection = value.substr(0, area.selectionStart)
                    let rightSection = value.substr(area.selectionEnd)

                    // replace newLine chars and multiple white spaces
                    leftSection = leftSection.replace(breakCharRegex, ' ').replace(whiteSpaceRegex, ' ');
                    rightSection = rightSection.replace(breakCharRegex, ' ').replace(whiteSpaceRegex, ' ');
                    
                    if (leftSection.charAt(leftSection.length-1) === ' ' && rightSection.charAt(0) === ' ')
                        rightSection = rightSection.substr(1)
                    
                    const newValue = leftSection + rightSection;
                    
                    await this.props.editInjuries(this.props.level, {
                        ...this.props.injuries,
                        summary: newValue,
                        location: this.props.level
                    },false )

                    area.selectionStart = leftSection.length;
                    area.selectionEnd = leftSection.length;
                }}
                
                onKeyDown={async (e) => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                        e.preventDefault();
                        await this.props.editInjuries(this.props.level, this.props.injuries, true);
                    }
                }}

                className="injurySummary"
            ></textarea>}

        </div>);

    }

}

export default InjuryLocation;