// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

import ClientCard from './ClientCard';

import api from '../../../api';

import '../../../styles/Console.css';
import '../../../styles/Clients.css';

export default class ClientsConsole extends Component {

    state = {
        clients: [],
        clientChanged: {},
        newClient: null
    }

    componentDidMount = async () => {

        await this.fetchClients();

    }

    fetchClients = async () => {

        // Get clients
        let res = await api.post('getClients');

        if (!res || !res.data)
            return;
        
        this.setState({
            clients: res.data
        });

    }

    updateClientInfo = async (clientId, field, value) => {

        let clients = [...this.state.clients];

        let client = clients.find(client => client._id === clientId);

        switch (field) {

            case "contactName":
                client.contact.name = value;
                break;
            
            case "contactEmail":
                client.contact.email = value;
                break;
            
            case "addressLine1":
                client.address.line1 = value;
                break;

            case "addressLine2":
                client.address.line2 = value;
                break;

            default:
                client[field] = value;

        }

        this.setState({
            clients: clients,
            clientChanged: {
                ...this.state.clientChanged,
                [clientId]: true
            }
        });

    }

    saveClientInfo = async (clientId) => {

        if (!this.state.clientChanged[clientId])
            return;

        let client = this.state.clients.find(client => client._id === clientId);

        let res = await api.post('syncClient', {
            clientId: clientId,
            clientDiff: client
        });

        if (!res || !res.data)
            return;
        
        let clients = [...this.state.clients];

        for (let idx=0; idx<clients.length; idx++) {
            if (clients[idx]._id === clientId) {
                clients[idx] = res.data;
                break;
            }
        }

        this.setState({
            clients: clients,
            clientChanged: {
                ...this.state.clientChanged,
                [clientId]: null
            }
        });

        toast.info('Saved client: ' + client.name);

    }

    deleteClient = async (clientId) => {

        let client = {...this.state.clients.find(client => client._id === clientId)};

        let res = await api.post('deleteClient', {
            clientId: clientId
        });

        if (!res)
            return;

        let clients = [...this.state.clients];

        for (let idx=0; idx<clients.length; idx++) {
            if (clients[idx]._id === clientId) {
                clients.splice(idx,1);
                break;
            }
        }

        this.setState({
            clients: clients
        });

        toast.info('Deleted client: ' + client.name);

    }

    createNewClient = async () => {

        let newClientName = this.state.newClient.name;

        await api.post('createClient', {
            name: this.state.newClient.name,
            address: this.state.newClient.address,
            cost: this.state.newClient.cost,
            contact: this.state.newClient.contact,
            displayAllCases: this.state.newClient.displayAllCases,
            showCostInDelivery: this.state.newClient.showCostInDelivery,
            logo: this.state.newClient.logo
        });

        this.setState({
            newClient: null
        });

        this.fetchClients();

        toast.info('Created new client: ' + newClientName);

    }

    uploadLogoAsBase64URL = async () => {

        // Field is either "signature" or "logo"

        let fileUpload = document.createElement('input');
        fileUpload.setAttribute('type', 'file');
        fileUpload.setAttribute('name', 'clientImage');
        fileUpload.setAttribute('accept', 'image/jpeg,image/gif,image/png');

        fileUpload.onchange = async (e) => {

            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);

            console.log(e.target.files[0]);

            reader.onload = async () => {
                
                if (reader.result.length > 20000)
                    return this.props.toast.error("Selected file is too large!");

                this.setState({
                    newClient: {
                        ...this.state.newClient,
                        logo: reader.result
                    }
                })
            }

        }

        // Trigger on upload
        fileUpload.click();

    }

    render() {
        return (
            <div>
                <div className="consoleHeader">
                    {this.state.newClient ? '' : <div className="consoleHeaderButton" onClick={() => this.setState({
                        newClient: {
                            name: "",
                            address: {
                                line1: "",
                                line2: ""
                            },
                            cost: "",
                            contact: {
                                name: "",
                                email: ""
                            },
                            displayAllCases: false,
                            showCostInDelivery: false,
                            logo: ""
                        }
                    })}>New Client</div>}
                </div>

                <div className="consoleContainer">
                    {/* All other clients */}
                    {this.state.clients.map((client, idx) =>
                        <ClientCard 
                            key={idx}
                            client={client}
                            changed={this.state.clientChanged[client._id]}
                            updateClientInfo={this.updateClientInfo}
                            saveClientInfo={this.saveClientInfo}
                            deleteClient={this.deleteClient}
                        />
                    )}

                    {/* New client card */}
                    {this.state.newClient ? <div className="clientCard">

                        <div className="clientCardLabel">Name</div>
                        <input type="text" name="newClientName" placeholder="Enter Client Name" value={this.state.newClient.name} onChange={(e) => this.setState({ newClient: {...this.state.newClient, name: e.target.value} })} /><br />

                        <div className="clientCardLabel">Cost</div>
                        <input type="text" name="newClientCost" placeholder="Cost Per Scan ($)" value={this.state.newClient.cost} onChange={(e) => this.setState({ newClient: {...this.state.newClient, cost: e.target.value} })} /><br />

                        <div className="clientCardLabel">Contact</div>
                        <input type="text" name="newClientContactName" placeholder="Contact's full name" value={this.state.newClient.contact.name} onChange={(e) => this.setState({
                            newClient: {
                                ...this.state.newClient,
                                contact: {
                                    ...this.state.newClient.contact,
                                    name: e.target.value,
                                }
                            }
                        })} /><br />

                        <div className="clientCardLabel" style={{ opacity:0 }}>_</div>
                        <input type="text" name="newClientContactEmail" placeholder="Contact's E-mail address" value={this.state.newClient.contact.email} onChange={(e) => this.setState({
                            newClient: {
                                ...this.state.newClient,
                                contact: {
                                    ...this.state.newClient.contact,
                                    email: e.target.value,
                                }
                            }
                        })} /><br />

                        <div className="clientCardLabel">Address</div>
                        <input type="text" name="newClientAddressLine1" placeholder="Street Address, Unit #" value={this.state.newClient.address.line1} onChange={(e) => this.setState({
                            newClient: {
                                ...this.state.newClient,
                                address: {
                                    ...this.state.newClient.address,
                                    line1: e.target.value,
                                }
                            }
                        })} /><br />

                        <div className="clientCardLabel" style={{ opacity:0 }}>_</div>
                        <input type="text" name="newClientAddressLine2" placeholder="City, State, ZIP, Country" value={this.state.newClient.address.line2} onChange={(e) => this.setState({
                            newClient: {
                                ...this.state.newClient,
                                address: {
                                    ...this.state.newClient.address,
                                    line2: e.target.value,
                                }
                            }
                        })} /><br />

                        <div className="clientCardLabel">Access</div>
                        <div className="switchContainer">
                            <Switch className="switchCheckbox" onChange={checked => this.setState({
                                newClient: {
                                    ...this.state.newClient,
                                    displayAllCases: checked
                                }
                            })} checked={this.state.newClient.displayAllCases} height={20} width={40} />
                            <div className="switchLabel">Show all cases</div>
                        </div><br />

                        <div className="clientCardLabel">Delivery</div>
                        <div className="switchContainer">
                            <Switch className="switchCheckbox" onChange={checked => this.setState({
                                newClient: {
                                    ...this.state.newClient,
                                    showCostInDelivery: checked
                                }
                            })} checked = {this.state.newClient.showCostInDelivery} height={20} width={40} />
                            <div className="switchLabel">Show costs in delivery</div>
                        </div><br />

                        <div className="clientCardLabel">Logo</div>
                        <div className="clientLogo" style={{
                            width: this.state.newClient.logo ? '125px' : '175px',
                            backgroundImage: 'url("' + this.state.newClient.logo + '")'
                        }}>
                            <div className="clientLogoOverlay" onClick={() => this.uploadLogoAsBase64URL()}>{this.state.newClient.logo ? 'Change' : 'Upload'}</div>
                        </div>

                        {this.state.newClient.logo ? <div className="clientLogoClear" onClick={() => this.props.updateClientInfo(this.state.newClient._id, "logo", null)}>Clear</div> : ''}
                        
                        <div className="clientButton clientSaveButton" onClick={() => this.createNewClient()}>Create</div>
                        <div className="clientButton clientDeleteButton" onClick={() => this.setState({ newClient: null })}>Cancel</div>
                    </div> : ''}
                </div>
            </div>
        );
    }

}