// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Loading from 'react-loading';
import posed from 'react-pose';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import moment from 'moment';

import TasksPortal from './TasksPortal';

import '../../../styles/OpsTasks.css';

const NotesPosed = posed.div({
    searchEnabled: { paddingTop: '34px' },
    searchDisabled: { paddingTop: '0px'}
});

class OpsTasks extends Component {

    state = {
        loading: false,
        calendarDialogOpen: false,
        calendarDate: moment(this.props.task.deliveryData.followUpDate),
        mouseHovered: false,
    }

    followUpDateClickHandler = () => {
        
        // Set the initial focused date
        if (!this.state.calendarDialogOpen) {

            this.setState(prevState => ({
                calendarDialogOpen: !prevState.calendarDialogOpen
            }))
        }
    }
    
    setFollowUpDate = async (date) => {

        await this.setState({
            calendarDate: date
        })

        if (!date)
            return;

        else
            this.props.onUpdateTasks(this.props.task._id, 'followUpDate', date._d);

    }

    onFollowUpDatesFocusChange = async (focusedInput) => {

        if (focusedInput.focused === false)
            this.setState({calendarDialogOpen: false});
    }

    onMouseEnter = () => {
        this.setState({mouseHovered: true});
    }

    onMouseLeave = () => {
        this.setState({mouseHovered: false})
    }

    getFieldDiv = (field) => {

        switch (field) {

            case 'invoiceNumber':
                return (
                    <div className="taskInvoiceNo">
                        <input type="text" name="invoiceNumber" className="taskInvoiceNumberInput" placeholder="Invoice #" value={this.props.task.deliveryData.invoiceNumber ? this.props.task.deliveryData.invoiceNumber : ''} onChange={(e) => 
                        this.props.onInvoiceNumberChange(this.props.task._id, e.target.value)} 
                        onKeyUp={ async (e) => {
                                if(e.keyCode === 13) {    // On enter
                                    this.props.onUpdateTasks(this.props.task._id, field, e.target.value);
                                }
                        }}/>
                    </div>
                );
            
            case 'attorneyPaid':
                return (
                    // Yes or No
                    <select className="opsSelect" value={this.props.task.deliveryData.attorneyPaid} onChange={(e) => {
                        this.props.onUpdateTasks(this.props.task._id, field, e.target.value);
                    }}>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select>
                );

            case 'followUpDate':
                // No follow up required if attorney paid already
                if (this.props.task.deliveryData.attorneyPaid)
                    return

                return (
                    <div className="followUpDate" title="Set follow up date">
                         <div onClick={() => this.followUpDateClickHandler()}>{new Date(this.props.task.deliveryData.followUpDate).toLocaleDateString("en-US", {year: 'numeric', month:'2-digit', day:'2-digit'})}</div>
                         {this.state.calendarDialogOpen ?
                            <div className='followUpDateContainer' style={{position: 'fixed', zIndex: 5}}>
                                <SingleDatePicker
                                    date={this.state.calendarDate}
                                    onDateChange={this.setFollowUpDate}
                                    focused={this.state.calendarDialogOpen}
                                    onFocusChange={this.onFollowUpDatesFocusChange}
                                    id={"calendar-" + this.props.task._id}
                                />
                            </div>
                        : ''}
                    </div>
                )

            default:
                return TasksPortal.getDisplayValueFromKey(this.props.task, field, true);

        }            

    }
    
    render() {

        const task = this.props.task;

        return (
            <div className={"opsLienTaskRow"} ref={'task-' + task._id} style={TasksPortal.getNotesStyleModifier(task.deliveryData.attorneyPaid, (this.props.openedNotes === '' || this.props.openedNotes === task._id) ? 1 : 0.3)}>
                <div className="opsLienRowTaskContainer" style={{
                    width: 'calc(100% - ' + this.props.iconOffset + ')'
                }}>
                    {Object.keys(this.props.columns).map((key, idx) => <div key={'task-' + task._id + '_' + key} className="lienTaskRowItem" style={{
                        width: "calc(" + this.props.columns[key].width + "% - 10px)",
                        maxWidth: this.props.columns[key].maxWidth-10 + "px"
                    }}>{this.getFieldDiv(key)}</div>)}
                </div>

                {this.state.loading ? <div ref="loadingIcon" style={{
                    float: 'right',
                    marginTop: '1px',
                    marginLeft: '10px'
                }}>
                    <Loading type="spin" color="rgb(80,80,80)" height={'28px'} width={'28px'} />
                </div> : ''}
                
                {/* Notes Icon */}
                <img src={task.deliveryData.notes ? "/resources/icons/notes.png" : "/resources/icons/notesGrayed.png"} className={task.deliveryData.notes ? "" : "grayedOut"} alt="Jobs" onClick={(e) => {
                        this.props.manageNotes()
                }} title={task.deliveryData.notes ? "Edit notes" : "Add new notes"} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} />

                {/* On hover notes preview */}
                {(this.state.mouseHovered && task.deliveryData.notes) ? <div className="notesPreview">{task.deliveryData.notes}</div> : ''}

                {/* Notes sidebar */}
                <div className="notesContainer" onClick={() => this.props.closeNotes()} style={{left: this.props.openedNotes ? 0 : '100%'}}>
                    <div className="notesManager" style={{right: this.props.openedNotes ? 0 : '-350px'}} onClick={(e) => e.stopPropagation()}>
                        <NotesPosed pose={this.props.searchEnabled ? 'searchEnabled' : 'searchDisabled'}>
                                <textarea className="notesInput" placeholder="Notes" value={task.deliveryData.notes} onChange={(e) => this.props.onNotesChange(this.props.task._id, e.target.value)}/>
                                <div className="notesSave" onClick={() => this.props.onUpdateTasks(this.props.task._id, 'notes', task.deliveryData.notes)}>Save Notes</div>
                            
                        </NotesPosed>
                        
                    </div>
                </div>

            </div>
        );
    }
    
}

export default withRouter(OpsTasks);