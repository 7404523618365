// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from '../../../api';

import CenterCard from './CenterCard';

import '../../../styles/Console.css';
import '../../../styles/Centers.css';

export default class CenterConsole extends Component {

    state = {
        centers: [],
        centersChanged: {},
        filteredCenters: [],
        clients: [],
        filterGroup: '',
        newCenter: null
    }

    componentDidMount = async () => {

        await this.fetchCenters();

        // Get clients
        let res = await api.post('getClients');

        if (!res || !res.data)
            return;
        
        this.setState({
            clients: res.data
        });

    }

    fetchCenters = async () => {

        // Get centers
        let res = await api.post('getCenters');

        if (!res || !res.data)
            return;

        await this.setState({
            centers: res.data
        });

        await this.applyCentersFilter();

    }

    applyCentersFilter = async () => {

        let unfiltered = [...Array(this.state.centers.length).keys()];

        let filtered = [];

        for (let centerIdx of unfiltered) {
            if (this.state.centers[centerIdx].group.toLowerCase().includes(this.state.filterGroup))
                filtered.push(centerIdx);
        }

        this.setState({
            filteredCenters: filtered 
        });

    }

    updateCenterInfo = async (centerId, field, value) => {

        let centers = [...this.state.centers];

        let center = centers.find(center => center._id === centerId);

        center[field] = value;

        this.setState({
            centers: centers,
            centersChanged: {
                ...this.state.centersChanged,
                [centerId]: true
            }
        });

    }

    saveCenterInfo = async (centerId) => {

        if (!this.state.centersChanged[centerId])
            return;

        let center = this.state.centers.find(center => center._id === centerId);
        
        let res = await api.post('syncCenter', {
            centerId: centerId,
            centerDiff: center
        });

        if (!res || !res.data)
            return;
        
        let centers = [...this.state.centers];

        for (let idx=0; idx<centers.length; idx++) {
            if (centers[idx]._id === centerId) {
                centers[idx] = res.data;
                break;
            }
        }

        this.setState({
            centers: centers,
            centersChanged: {
                ...this.state.centersChanged,
                [centerId]: null
            }
        });

        toast.info('Saved center rule: ' + center.group);
        
    }

    deleteCenter = async (centerId) => {

        let center = this.state.centers.find(center => center._id === centerId);

        let res = await api.post('deleteCenter', {
            centerId: centerId
        });

        if (!res)
            return;

        let centers = [...this.state.centers];

        for (let idx=0; idx<centers.length; idx++) {
            if (centers[idx]._id === centerId) {
                centers.splice(idx,1);
                break;
            }
        }

        this.setState({
            centers: centers
        });

        toast.info('Deleted center rule for: ' + center.group);

    }

    createNewCenterRule = async () => {

        let newCenterGroup = this.state.newCenter.group;

        if (!newCenterGroup || newCenterGroup === "")
            return toast.error("Cannot create a center rule without a center group!");

        await api.post('createCenter', {
            prepareHCFA: this.state.newCenter.prepareHCFA,
            prepareMRA: this.state.newCenter.prepareMRA,
            prepareMBA: this.state.newCenter.prepareMBA,
            group: this.state.newCenter.group,
            generateBillingDocs: this.state.newCenter.generateBillingDocs,
            defaultClient: this.state.newCenter.defaultClient
        });

        this.setState({
            newCenter: null
        });

        this.fetchCenters();

        toast.info('Created new rule for center: ' + newCenterGroup);

    }

    render() {
        return (
            <div>
                <div className="consoleHeader">
                    <input className="centerFilterSearch" type="text" name="filter" placeholder="Filter..." value={this.state.filterGroup} onChange={async (e) => {
                        await this.setState({ filterGroup: e.target.value });
                        this.applyCentersFilter();
                    }} />

                    {this.state.newUser ? '' : <div className="consoleHeaderButton" onClick={() => this.setState({
                        newCenter: {
                            group: "",
                            generateBillingDocs: false,
                            defaultClient: "",
                            prepareHCFA: true,
                            prepareMRA: false,
                            prepareMBA: false,
                        }
                    })}>New Rule</div>}
                </div>

                <div className="consoleContainer">

                    {/* All users */}
                    {this.state.filteredCenters.map((centerIdx, idx) => {

                        let center = this.state.centers[centerIdx];

                        if (center)
                            return (<CenterCard
                                key={idx}
                                center={center}
                                changed={this.state.centersChanged[center._id]}
                                clients={this.state.clients}
                                toast={toast}
                                updateCenterInfo={this.updateCenterInfo}
                                saveCenterInfo={this.saveCenterInfo}    deleteCenter={this.deleteCenter}
                            />);
                        
                        return '';

                    })}

                    {/* New center rule card */}
                    {this.state.newCenter ? <div className="centerCard">

                        <div className="centerCardLabel">Group</div>
                        <input type="text" name="newCenterGroup" placeholder="Enter center group" value={this.state.newCenter.group} onChange={(e) => this.setState({ newCenter: {...this.state.newCenter, group: e.target.value} })} /><br />

                        <div className="centerCardLabel">Client</div>
                        <select className="centerCardSelect" value={this.state.newCenter.defaultClient} onChange={(e) => this.setState({ newCenter : {
                                ...this.state.newCenter,
                                defaultClient: e.target.value
                            }})}>
                            <option value="">Select Client</option>
                            {this.state.clients.map((client,idx) => 
                                <option key={idx} value={client._id}>{client.name}</option>
                            )}
                        </select><br />

                        <div className="centerCardLabel">Docs</div>
                        <div className="switchContainer">
                            <Switch className="switchCheckbox" onChange={checked => this.setState({ newCenter : {
                                ...this.state.newCenter,
                                prepareMBA: checked
                            } })} checked={this.state.newCenter.prepareMBA} height={20} width={40} />
                            <div className="switchLabel">Prepare MBA</div>
                        </div><br />

                        <div className="centerCardLabel" style={{ opacity:0 }}>_</div>
                        <div className="switchContainer">
                            <Switch className="switchCheckbox" onChange={checked => this.setState({ newCenter : {
                                ...this.state.newCenter,
                                prepareHCFA: checked
                            } })} checked={this.state.newCenter.prepareHCFA} height={20} width={40} />
                            <div className="switchLabel">Prepare HCFA</div>
                        </div><br />

                        <div className="centerCardLabel" style={{ opacity:0 }}>_</div>
                        <div className="switchContainer">
                            <Switch className="switchCheckbox" onChange={checked => this.setState({ newCenter : {
                                ...this.state.newCenter,
                                prepareMRA: checked
                            } })} checked={this.state.newCenter.prepareMRA} height={20} width={40} />
                            <div className="switchLabel">Prepare MRA</div>
                        </div>

                        <div className="centerButton centerSaveButton" onClick={() => this.createNewCenterRule()}>Create</div>

                        <div className="centerButton centerDeleteButton" onClick={() => this.setState({ newCenter: null })}>Cancel</div>

                    </div> : ''}
                                        
                </div>
            </div>
        );
    }

}