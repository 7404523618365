// Developed by Josh Stovall
// See LICENSE.md file in project root directory

import React from 'react';

import ProjectsPortal from '../../ProjectsPortal';
import AnimationProject from './AnimationProject';
import JobsManager from '../../JobManager';

import api from '../../../api';
import { toast } from 'react-toastify';

// import { studyTypes } from '../../../constants';

export default class AnimationPortal extends ProjectsPortal {

    constructor(props) {

        super(props);

        this.columns = {
            "case": { label: "Case", width: 8, maxWidth: 60 },
            "patientName": { label: "Patient Name", width: 12, maxWidth: 320 },
            "studyType": { label: "Study Type", width: 6, maxWidth: 140 },
            "animation.procedure": { label: "Procedure", width: 20, maxWidth: 250 },
            "animation.dueDate": { label: "Due Date", width: 11, maxWidth: 120 },
            "animation.hardDeadline": { label: "Hard Deadline", width: 11, maxWidth: 110 },
            "animation.lastAccess": { label: "Modified", width: 7, maxWidth: 100 },
            "animation.assignee": { label: "Assignee", width: 12, maxWidth: 150 },
            "client": { label: "Client", width: 10, maxWidth: 160 },
            "animation.lop": { label: "LOP", width: 15, maxWidth: 100 },
            "animation.status": { label: "Animation Status", width: 15, maxWidth: 160 },
            "animation.notes": { label: "Notes", width: 20, maxWidth: 250 },
        };

        this.iconOffset = '75px';

        this.state.selected = [];

        this.state.jobManager = {
            projectId: '',
            opened: false
        };

        this.state.confirmCaseGroup = false;
        this.state.newCase = false;

        // new case data
        this.state.newCase_patient_name = '';
        this.state.newCase_patient_sex = 'M';
        this.state.newCase_patient_dateOfBirth = '';
        this.state.newCase_studyDate = '';
        this.state.newCase_studyType = 'Brain';


    }

    componentDidMount = async () => {

        // Fetch projects
        let res = await this.fetchProjects();

        if (!res || !res.auth)
            return;

        if (!res.auth._id)
            window.location = "/login";

        // else if (res.auth.type === 'lead')
        //     window.location = "/lead";

        else if (res.auth.type === 'center')
            window.location = "/center";

        else if (res.auth.type === 'endUser')
            window.location = '/centerenduser';

        else if (res.auth.type === 'client' || res.auth.type === 'radiologist' /*|| res.auth.type === 'tech'*/)
            window.location = "/review";

        // Get clients and users
        res = await api.post('getClients');

        if (!res || !res.data)
            return;

        let clients = {};

        for (let client of res.data)
            clients[client._id] = { ...client };

        res = await api.post('getUsers');

        if (!res || !res.data)
            return;

        let users = {};

        for (let user of res.data)
            users[user._id] = { ...user };


        await this.setState({
            clients: clients,
            users: users,
        });
    }

    // Field edit functions
    selectHandler = async (animationId, field, value) => {

        console.log({animationId, field, value})

        let res = await api.post('syncAnimation', {
            aId: animationId,
           // pId: projectId,
            projectDiff: {
                [field]: value
            }
        });

        if (!res || !res.success || !res.data)
            return;

        // Update
        this.updateProjects([res.data]);

    }

    // Update Google Drive link
    googleDriveValueChangeHandler = async (projectId, animationId, value) => {
        let project = this.state.projects[this.getProjectIdx(projectId)];
        project.animation.googleDrive = value;
        this.updateProjects([project]);
    }

    // // Selected?
    // selectionUpdateHandler = (project) => {

    //     let newSelected = [...this.state.selected];
    //     let selectedIdx = newSelected.indexOf(project._id);

    //     if (selectedIdx >= 0) // Exists - remove
    //         newSelected.splice(selectedIdx, 1);

    //     else // Doesn't exist - add
    //         newSelected.push(project._id);

    //     this.setState({
    //         selected: newSelected
    //     });

    // }

    // Confirm/push
    googleDriveConfirmHandler = async (projectId, animationId) => {

        let project = this.state.projects[this.getProjectIdx(projectId)];

        console.log('confirm google drive yay')
        // Set the editor to loading 
        let openedEditors = this.state.openedEditors;
        if (openedEditors[project._id])
            openedEditors[project._id].saving = true;
        await this.setState({
            openedEditors: openedEditors
        });

        let res = await api.post('syncAnimation', {
            pId: projectId,
            aId: animationId,
            projectDiff: {
                'animation.googleDrive': project.animation.googleDrive,
            }
        });

        // Remove pId from opened list
        openedEditors = this.state.openedEditors;
        delete openedEditors[projectId];
        this.setState({
            openedEditors: openedEditors
        })

        // Handle error
        if (!res || !res.success || !res.data) {
            toast.error('Error saving Google Drive link.');
            return;
        }

        // Update
        this.updateProjects([res.data]);

        toast.success("Google Drive link saved!")
    }
    // Confirm/push
    contactConfirmHandler = async (projectId) => {

        let project = this.state.projects[this.getProjectIdx(projectId)];

        // Set the editor to loading 
        let openedEditors = this.state.openedEditors;
        if (openedEditors[project._id])
            openedEditors[project._id].saving = true;
        await this.setState({
            openedEditors: openedEditors
        });

        let res = await api.post('syncProject', {
            pId: projectId,
            projectDiff: {
                attendingPhysician: project.attendingPhysician,
                attorney: project.attorney
            }
        });

        // Remove pId from opened list
        openedEditors = this.state.openedEditors;
        delete openedEditors[projectId];
        this.setState({
            openedEditors: openedEditors
        })

        // Handle error
        if (!res || !res.success || !res.data) {
            toast.error('Error saving contact info.');
            return;
        }

        // Update
        this.updateProjects([res.data]);

        toast.success("Contact info saved!")
    }

    // costConfirmHandler = async (projectId) => {

    //     let project = this.state.projects[this.getProjectIdx(projectId)];

    //     // Set the editor to loading 
    //     let openedEditors = this.state.openedEditors;
    //     if (openedEditors[project._id])
    //         openedEditors[project._id].saving = true;
    //     await this.setState({
    //         openedEditors: openedEditors
    //     });

    //     // Reset cost if we delete the entire string
    //     if (String(project.cost).trim().length === 0 || project.cost === null)
    //         project.cost = '';

    //     let res = await api.post('setCaseCost', {
    //         pId: projectId,
    //         cost: project.cost
    //     });

    //     // Remove pId from opened list
    //     openedEditors = this.state.openedEditors;
    //     delete openedEditors[projectId];
    //     this.setState({
    //         openedEditors: openedEditors
    //     })

    //     // Handle error
    //     if (!res || !res.success || !res.data) {
    //         toast.error('Error saving cost.');
    //         return;
    //     }

    //     // Update
    //     this.updateProjects([res.data]);

    // }

    // areSelectedProjectsGrouped = () => {

    //     if (this.state.selected.length < 2)
    //         return;

    //     let caseNumber = this.getProjectById(this.state.selected[0]).case;

    //     for (let idx = 1; idx < this.state.selected.length; idx++)
    //         if (this.getProjectById(this.state.selected[idx]).case !== caseNumber)
    //             return false;

    //     return true;

    // }

    // Check if all projects in case are approved
    isCaseApproved = (caseNumber) => {

        for (let project of this.state.projects)
            if (project.case === caseNumber && project.status !== 'Approved' && project.status !== 'Delivered' && project.status !== 'Removed')
                return false;

        return true;

    }

    // Case grouping operations
    // groupSelectedProjectsIntoACase = async () => {

    //     let selectedProjects = [];
    //     let smallestCaseNumber = 999999;

    //     // Make sure there are enough cases to create a group
    //     if (this.state.selected.length < 2) {
    //         toast.error("Not enough cases selected to group!")
    //         this.setState({
    //             confirmCaseGroup: false
    //         })
    //         return;
    //     }

    //     for (let projectId of this.state.selected) {
    //         let project = this.getProjectById(projectId);

    //         if (project) {
    //             if (project.case < smallestCaseNumber)
    //                 smallestCaseNumber = project.case;

    //             selectedProjects.push(project);
    //         }
    //     }

    //     // Sync projects
    //     let updatedProjects = [];

    //     for (let project of selectedProjects) {
    //         let res = await api.post('syncProject', {
    //             pId: project._id,
    //             projectDiff: {
    //                 case: smallestCaseNumber
    //             }
    //         })

    //         if (res && res.data)
    //             updatedProjects.push({ ...res.data });
    //     }

    //     // Update local state
    //     this.updateProjects(updatedProjects);

    //     // Clear selection
    //     this.setState({
    //         selected: [],
    //         confirmCaseGroup: false
    //     });

    // }

    // createNewCaseForSelectedProject = async () => {

    //     let res = await api.post('createNewCaseForProject', {
    //         pId: this.state.selected[0]
    //     });

    //     if (!res || !res.data)
    //         return;

    //     // We received the updated project back
    //     this.updateProjects([res.data]);

    //     // Clear selection
    //     this.setState({
    //         selected: []
    //     });

    // }

    // create new animation (using bottom corner button, for animation deliveries)
    createNewAnimation = async () => {

       // return console.log(this.state);
        
        if (!this.state.newCase_project_id) return toast.error('Please select a study type')

        let res = await api.post('createNewAnimation', {
            projectId: this.state.newCase_project_id
        });

        if (!res || !res.data)
            return;

        // We received the updated project back
        this.updateProjects([res.data]);

        // Clear selection
        this.setState({
            selected: [],
           newCase_case_number: null,
           newCase_studyType: null,
           newCase: false
        });

    }

    // selectedCaseInfo = () => {
    //     let selectedProjects = [];
    //     let smallestCaseNumber = 999999;
    //     let projectInfo = [];

    //     for (let projectId of this.state.selected) {
    //         let project = this.getProjectById(projectId);

    //         if (project) {
    //             if (project.case < smallestCaseNumber)
    //                 smallestCaseNumber = project.case;

    //             selectedProjects.push(project);
    //         }
    //     }

    //     for (let project of selectedProjects)
    //         projectInfo.push(
    //             <div key={project.case + project.studyType} className="caseInfoRow">{project.case} {project.patient.name} - {project.studyType}
    //                 <img src='/resources/icons/reject.png' onClick={() => this.selectionUpdateHandler(project)} />
    //             </div>)


    //     return projectInfo;
    // }

    findCaseForNewAnimation = async () =>{

        let res = await api.post('getProjectsByCase', {
            caseNumber: this.state.newCase_case_number,
        });

        this.setState({
            newCase_projects: res.data
        })

    }


    renderProjects = () => {

        const jobProject = this.getProjectById(this.state.jobManager.projectId);

        return (
            <div>
                {/* Sorted/ Filtered projects */}
                {this.state.displayProjects.map(idx => (
                // (this.state.projects[idx].status == 'Animation')? 
                 <AnimationProject
                        key={this.state.projects[idx]._id + '-' + this.state.projects[idx].animation._id}
                        project={this.state.projects[idx]}
                        uniqueIdentifier={idx}
                        users={this.state.users}
                        clients={this.state.clients}
                        columns={this.columns}
                        iconOffset={this.iconOffset}
                        onUpdateProjects={this.updateProjects}
                        onSelect={this.selectHandler}
                        onContactsConfirm={this.contactConfirmHandler}
                        onGoogleDriveConfirm={this.googleDriveConfirmHandler}
                        //onContactsValueChange={this.contactValueChangeHandler}
                        onGoogleDriveValueChange={this.googleDriveValueChangeHandler}
                       // onCostConfirm={this.costConfirmHandler}
                        //onCostValueChange={this.costValueChangeHandler}
                       // selected={this.state.selected.includes(this.state.projects[idx]._id)}
                       // onToggleSelected={this.selectionUpdateHandler} isCaseApproved={this.isCaseApproved}
                        onUpdateEditing={(editing) => this.setState({ editing })}
                        openEditor={this.state.openedEditors[this.state.projects[idx]._id] ? this.state.openedEditors[this.state.projects[idx]._id] : ''}
                        openProjectContacts={(id) => this.setState({ openedEditors: { ...this.state.openedEditors, [id]: { editor: 'contacts', saving: false } } })}
                        openProjectGoogleDrive={(pid,aid) => this.setState({ openedEditors: { ...this.state.openedEditors, [pid]: { editor: 'googleDrive', saving: false } } })}
                        openCostEditor={(id) => this.setState({ openedEditors: { ...this.state.openedEditors, [id]: { editor: 'cost', saving: false } } })}
                        getProjectsByCase={this.getProjectsByCase}
                        manageJobs={() => this.setState({
                            jobManager: {
                                projectId: this.state.projects[idx]._id,
                                opened: true
                            }
                        })}
                        openedJob={this.state.jobManager.opened ? this.state.jobManager.projectId : ''}
                    />
                    // : null
                ))}

                {/* {this.state.selected.length > 1 && !this.areSelectedProjectsGrouped() ? <div ref="caseGrouping" className="caseGrouping">
                    Group selected projects into a single case? <div className="caseGroupButton" onClick={() => this.setState({ confirmCaseGroup: true })}>Group</div>
                </div> : ''}

                {this.state.selected.length === 1 ? <div ref="caseGrouping" className="caseGrouping">
                    Create new case for project? <div className="caseGroupButton" onClick={() => this.createNewCaseForSelectedProject()}>New Case</div>
                </div> : ''}

                {this.state.confirmCaseGroup ? <div className="confirmGroup" onClick={() => this.setState({ confirmCaseGroup: false })}>
                    <div className="confirmGroupDialog" ref={'confirmGroupDialog'} onClick={(e) => e.stopPropagation()}>
                        <div className="confirmGroupDialogTitle"> Confirm Grouping</div>
                        <div>
                            {this.selectedCaseInfo()}
                        </div>
                        <div style={{ width: '100%' }}>
                            {this.state.selected.length > 1 ? <div className="groupDialogBtn" onClick={() => this.setState({ confirmCaseGroup: false })}>No</div> :
                                <div className="groupDialogBtn" style={{ width: '90%', color: 'red' }} onClick={() => this.setState({ confirmCaseGroup: false })}>Not enough projects selected! Click to close</div>}
                            {this.state.selected.length > 1 ? <div className="groupDialogBtn" style={{ marginLeft: 'calc(100% - 80px)' }} onClick={() => this.groupSelectedProjectsIntoACase()}>Yes</div> : ''}
                        </div>
                    </div>
                </div> : ''} */}

                {/* new case button */}
                <button className="newCaseButton" onClick={() => this.setState({ newCase: true })}>
                    +
                </button>

                {/* new animation dialog */}
                {this.state.newCase ? <div className="newCase" onClick={() => this.setState({ newCase: false })}>
                    <div className="newCaseDialog" ref={'newCaseDialog'} onClick={(e) => e.stopPropagation()}>
                        <div className="newCaseDialogTitle">New Animation</div>
                        <div>
                            <label htmlFor="newCase_case_number">Case Number</label>
                            <br />
                            <input type="number" name="newCase_case_number" placeholder="" onInput={(e) => { this.setState({ newCase_case_number: e.target.value }) }} />
                            <button onClick={this.findCaseForNewAnimation}>Find Case</button>
                            <br/>
                            <label htmlFor="newCase_studyType">Study Type</label>
                            <br />
                            <select name="newCase_studyType" 
                            onInput={(e) => { 
                                this.setState({ newCase_project_id: e.target.value })
                             }}
                            >
                                <option value={null} key={'null-studytype'} > </option>
                                {
                                this.state.newCase_projects?.length?  this.state.newCase_projects.map(p => <option value={p._id} key={p.studyType} > {p.studyType} </option>)
                                :<></>
                                }
                            </select>
                            <br />
                           
                        </div>
                        <button onClick={this.createNewAnimation}>Create Animation</button>
                        <div style={{ width: '100%' }}>
                            <div className="groupDialogBtn" style={{ width: '90%', color: 'red' }} onClick={() => this.setState({ newCase: false })}>Click to close</div>
                        </div>
                    </div>
                </div> : ''}

                {/* <JobsManager
                    opened={this.state.jobManager.opened}
                    searchEnabled={this.state.searchEnabled}
                    closeJobManager={() => this.setState({
                        jobManager: {
                            opened: false
                        }
                    })}
                    updateJob={this.updateJob}
                    pId={jobProject ? jobProject._id : null}
                    project={jobProject ? jobProject : null}
                    job={jobProject ? jobProject.ai : null}
                    killTask={this.killJob}
                    users={this.state.users}
                /> */}
            </div>
        );

    }

}