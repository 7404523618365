// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { withRouter } from 'react-router-dom';
import Loading from 'react-loading';

import InjuryLocation from './InjuryLocation';
import {Resizable} from 're-resizable' 

import '../../../styles/InjuryEditor.css'
import api from '../../../api';
import { toast } from 'react-toastify';

const studyLevels = {
    'Lumbar': ["Disc.T12-L1", "Disc.L1-L2", "Disc.L2-L3", "Disc.L3-L4", "Disc.L4-L5", "Disc.L5-S1", "Other"],
    'Thoracic': ["Disc.C7-T1", "Disc.T1-T2", "Disc.T2-T3", "Disc.T3-T4", "Disc.T4-T5", "Disc.T5-T6", "Disc.T6-T7", "Disc.T7-T8", "Disc.T8-T9", "Disc.T9-T10", "Disc.T10-T11", "Disc.T11-T12", "Disc.T12-L1", "Other"],
    'Cervical': ["Disc.C2-C3", "Disc.C3-C4", "Disc.C4-C5", "Disc.C5-C6", "Disc.C6-C7", "Disc.C7-T1", "Other"],
    'LeftShoulder':  ["Bursa", "Labrum", "Infraspinatus", "Subscapularis", "Supraspinatus", "TeresMinor", "Biceps", "Acromioclavicular", "Skeletal", "Ligament", "Other"],
    'RightShoulder': ["Bursa", "Labrum", "Infraspinatus", "Subscapularis", "Supraspinatus", "TeresMinor", "Biceps", "Acromioclavicular", "Skeletal", "Ligament", "Other"],
    'Brain': ["Brain"],
    'LeftKnee': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'RightKnee': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'LeftHip': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'RightHip': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'LeftHand': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'RightHand': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'LeftWrist': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'RightWrist': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'LeftAnkle': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'RightAnkle': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'LeftFoot': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'RightFoot': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'LeftElbow': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
    'RightElbow': ["Bursa", "Muscular", "Skeletal", "Ligament", "Other"],
};

class InjuryEditor extends Component {

    state = {
        pId: null,
        status: null,
        injuries: [],
        saving: true
    }

    componentWillMount = async () => {

        // Fetch injuries
        await this.fetchInjuries(this.props.case, this.props.subdir);

    }

    componentWillReceiveProps = async (nextProps) => {

        if (nextProps.case === this.props.case && nextProps.subdir === this.props.subdir)
            return;

        await this.fetchInjuries(nextProps.case, nextProps.subdir);

    }

    fetchInjuries = async (caseNumber, subdir) => {

        await this.setState({ saving: true, injuries: [] });

        if (!subdir || subdir.length < 5)
            return;

        const studyType = subdir.split('/')[0];

        // Get project _id from case number and study type
        const res = await api.post('getProjectsByCase', { caseNumber });
        if (!res || !res.success) return;

        // Find project associated with study and case
        for (let p of res.data)
            if (p.studyType === studyType)
                return await this.setState({
                    pId: p._id,
                    injuryApplied: false,
                    injuries: p.ai && p.ai.injury && p.ai.injury.injuries ? p.ai.injury.injuries : [],
                    saving: false
                });

    }

    // Save current state of injuries
    saveInjuries = async (setSaving = true) => {

        if (setSaving)
            await this.setState({ saving: true });

        // API update
        const res = await api.post('syncInjuries', {
            pId: this.state.pId,
            injuries: this.state.injuries
        });

        if (!res || !res.success)
            return toast.error("Unable to sync injuries");

        if (setSaving) {
            await this.setState({
                injuries: res.data,
                saving: false
            });
        }

        else
            await this.setState({ injuries: res.data });
            return toast.success("Successfully saved injuries");

    }

    placeInjuries = async () => {

        await this.setState({
            saving: true
        });

        // TODO @Marcel: Verify desired functionality
        await this.saveInjuries(false);

        // Assign injury bot to project
        await api.post('syncProject', {
            pId: this.state.pId,
            projectDiff: {
                status: "Segment",
                assignee: "AI:Injury"
            }
        });

        await this.setState({
            saving: false
        })

    }

    reviewNextRWR = async () => {

        // Get next project assigned to this user
        const res = await api.post('getNextProject', {});

        if (!res || !res.success)
            return toast.error("Unable to retrieve next project");

        if (!res.data)
            return toast.error("No more projects assigned.");

        for (let file of res.data.files) {
            if (file.docRef.includes("RWR - Lumbar")) {
                const nextRWRPath = '/files/' + String(res.data.case) + file.path;
                await this.props.history.push(nextRWRPath);
                await this.props.reload();
            }
        }

        
    }

    updateInjuries = async (level, update, save=true) => {

        // console.log(level, update);

        let injuries = this.state.injuries;

        let foundLoc = false;

        for (let i=0; i<injuries.length; i++)
            if (injuries[i].location === level) {
                injuries[i] = update;
                foundLoc = true;
                break;
            }

        if (!foundLoc)
            injuries.push(update);

        // Local update
        this.setState({ injuries });

    }
    

    render = () => {

        if (!this.props.file)
            return (<div></div>);

        const studyType = Object.keys(studyLevels).find(l => this.props.file.includes(l));

        return (

            <div className={'injuryEditorRoot' + (this.props.hidden ? ' injuryEditorRootHidden' : '')}>

            <Resizable 
                    style = {{backgroundColor: 'rgba(0,0,0,0)'}}
                    defaultSize={{width: this.props.width, height: "100%"}}
                    bounds={'window'}
                    minWidth = {this.props.minWidth}
                    maxWidth = {this.props.maxWidth}
                    onResize={(e) => this.props.updateWidth(e.pageX)}
                    enable = {{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            >

                {/* Editor */}
                <div className={"injuryEditorContainer" + (this.props.hidden ? " injuryEditorContainerHidden" : "")} style = {{width: !this.props.hidden ? '100%' : 0}}>
                    
                    <Scrollbars
                        renderThumbVertical={props => <div {...props} style={{
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            borderRadius: '5px'
                        }} />}
                    >

                        <div className="injuryEditorPane">

                            {studyType && studyLevels[studyType].map((level,idx) => <InjuryLocation
                                key={idx}
                                level={level}
                                injuries={this.state.injuries.find(l => l.location === level)}
                                editInjuries={async (level, update, save) => await this.updateInjuries(level, update, save)}
                            />)}

                        </div>

                    </Scrollbars>

                </div>
            
                {/* Controls */}
                <div className={"injuryEditorControls" + (this.props.hidden ? " injuryEditorControlsHidden" : '')}>
                    
                    {this.state.saving ? <div
                        className="injuryEditorLoading"
                    >
                        <Loading type="spin" color="rgba(230,155,30,0.5)" height={'32px'} width={'32px'} />
                    </div> : <div>
                    
                    {/* Injury Save Button */}
                    <div
                        className="editorControlsButton injuryEditorSave"
                        onClick={() => this.saveInjuries()} >
                        Save
                    </div>

                    {/* Injury Place Button */}
                    <div
                        className="editorControlsButton injuryEditorPlace"
                        onClick={() => this.placeInjuries()} >
                        Place Injuries
                    </div>
                    
                    {/* Next Report Button */}
                    <div
                        className="editorControlsButton injuryEditorNext"
                        onClick={async () => await this.reviewNextRWR()}> 
                        Next
                    </div>

                    </div>}

                </div>
                        
            {/* Toggle Bar */}
            <div
                className={"hideToggleContainer" + (this.props.hidden ? " hideToggleContainerHidden" : "")}
                title = {(this.props.hidden ? "Show" : "Hide") + " Injury Editor"}
                onClick={this.props.toggleOpen}
            > 
                <img className={"hideToggleButton" + (this.props.hidden ? " hideToggleButtonHidden" : "")} src="/resources/icons/left.png"/>
            </div>
            
            </Resizable>

            
            </div>
            );

    }

};

export default withRouter(InjuryEditor);