// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component, useCallback } from 'react';
import posed from 'react-pose';
import Loading from 'react-loading';

import SearchBar from '../../SearchBar';
import DataHeader from '../../DataHeader';
// import OpsTasks from './OpsTasks';

import api from '../../../api';

import '../../../styles/UploadPortal.css';
import { toast } from 'react-toastify';

import Dropzone from 'react-dropzone'



// Posed div for search bar
const TasksPosed = posed.div({
    searchEnabled: { paddingTop: '34px' },
    searchDisabled: { paddingTop: '0px' }
});

const TODAY = new Date();





export default class UploadPortal extends Component {

    constructor(props) {

        super(props);

        // Initial state
        this.state = {
            center:"multus",
            uploading:false
        };

        // // Bind child functions
        // this.onHeaderFieldClick = this.onHeaderFieldClick.bind(this);
        // this.onSearchEvent = this.onSearchEvent.bind(this);
        // this.toggleSearch = this.toggleSearch.bind(this);

        // this.iconOffset = '0px';

    }

  





 handleUpload = async (acceptedFiles) => {
                    
    
    let center = this.state.center



    
        console.log(acceptedFiles)
        console.log(center)


         // Create form data
         let formData = new FormData();

        //  formData.set('uploader', this.props.user._id);
        //  formData.set('uploadingCenter', this.props.user.data.center);
         formData.append('doc', acceptedFiles[0]);
 
         // Set loading
         await this.setState({uploading: true});


 
         let res = await api.post('uploadDicom', formData);
    
         console.log(res)
        // let formData = new FormData();

        // formData.append("files", acceptedFiles[0]);



//  let res = await api.post('uploadDicom', {
//     center: center,
//     files: formData
// });
    

 console.log(res)

            // if (!res || !res.data)
            //     return;
            
            // this.setState({
            //     clients: res.data
            // });


        // fetchClients = async () => {
        //     // Get clients
           
        // }
    



        // todo, end this to backend



        // for (let i=0; i<acceptedFiles.length;i++){
    
    
    
        //     let f = acceptedFiles[i]
    
        //     console.log(f)
    
        //     //store file (f) in a /temp folder using fs
        // }




    
    }




    componentDidMount = async () => {

        // // Fetch tasks
        // let res = await this.fetchTasks();

        // if (!res || !res.auth)
        //     window.location = "/login";

        // if (!res.auth._id)
        //     window.location = "/login";
        
        // else if (res.auth.type !== 'ops')
        //     window.hi
        
        // story.back();
    }


// let a = this
     changeCenter = (e)=>{

        console.log(e.target.value)
        this.setState({center:e.target.value})











        }


    render() {


        // const onDrop = useCallback(acceptedFiles => {
        //     // Do something with the files
        //   }, [])
        //   const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    



        return (


            <div className="uploadRootContainer">
            <div className="uploadContainer">
                <div className="uploadUI">
            <label>Select a center</label><br/>
               <select defaultValue={"multus"} className="center" onChange={this.changeCenter}>
                        <option value="azadvanced">AZ Advanced</option>
                        <option value="cdi">CDI</option>
                        <option value="gomymri">Go MRI</option>
                        <option value="iis">IIS</option>
                        <option value="multus">Multus</option>
                        <option value="multuslossless">Multus (lossless)</option>
                        <option value="openmri">Open MRI</option>
                        <option value="orthopedicspecialists">Orthopedic Specialists</option>
                        <option value="simonmed">Simon Med</option>
                        <option value="sobti">Sobti</option>

                        
                    </select>

                    <Dropzone onDrop={this.handleUpload}>
                {({getRootProps, getInputProps}) => (
                    <section className='dropzone'>


                    

                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>
                           {this.state.uploading?'uploading...': '⬆️ upload DICOM zip here'}
                            </p>
                    </div>
                    </section>
                )}
                </Dropzone>
 </div>
           
           
           </div>
   

            
                </div>
        );

    }

}