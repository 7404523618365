// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Switch from 'react-switch';

import '../../../styles/Clients.css';

export default class ClientCard extends Component {

    uploadLogoAsBase64URL = async () => {

        // Field is either "signature" or "logo"

        let fileUpload = document.createElement('input');
        fileUpload.setAttribute('type', 'file');
        fileUpload.setAttribute('name', 'clientImage');
        fileUpload.setAttribute('accept', 'image/jpeg,image/gif,image/png');

        fileUpload.onchange = async (e) => {

            let reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);

            console.log(e.target.files[0]);

            reader.onload = async () => {
                
                if (reader.result.length > 20000)
                    return this.props.toast.error("Selected file is too large!");

                this.props.updateClientInfo(this.props.client._id, "logo", reader.result);
            }

        }

        // Trigger on upload
        fileUpload.click();

    }

    render() {

        const client = this.props.client;

        return (
            <div className={"clientCard" + (this.props.changed ? " clientInfoChanged" : "")}>

                <div className="clientCardLabel">Name</div>
                <input type="text" name="clientName" placeholder="Enter Client Name" value={client.name} onChange={(e) => this.props.updateClientInfo(client._id, "name", e.target.value)} /><br />

                <div className="clientCardLabel">Cost ($)</div>
                <input type="text" name="clientCost" placeholder="Cost Per Scan ($)" value={client.cost} onChange={(e) => this.props.updateClientInfo(client._id, "cost", e.target.value)} /><br />

                <div className="clientCardLabel">Contact</div>
                <input type="text" name="clientContactName" placeholder="Contact's full name" value={client.contact.name} onChange={(e) => this.props.updateClientInfo(client._id, "contactName", e.target.value)} /><br />

                <div className="clientCardLabel" style={{ opacity:0 }}>_</div>
                <input type="text" name="clientContactEmail" placeholder="Contact's E-mail address" value={client.contact.email} onChange={(e) => this.props.updateClientInfo(client._id, "contactEmail", e.target.value)} /><br />

                <div className="clientCardLabel">Address</div>
                <input type="text" name="clientAddressLine1" placeholder="Street Address, Unit #" value={client.address.line1} onChange={(e) => this.props.updateClientInfo(client._id, "addressLine1", e.target.value)} /><br />

                <div className="clientCardLabel" style={{ opacity:0 }}>_</div>
                <input type="text" name="clientAddressLine2" placeholder="City, State, ZIP, Country" value={client.address.line2} onChange={(e) => this.props.updateClientInfo(client._id, "addressLine2", e.target.value)} /><br />

                <div className="clientCardLabel">Access</div>
                <div className="switchContainer">
                    <Switch className="switchCheckbox" onChange={checked => this.props.updateClientInfo(client._id, "displayAllCases", checked)} checked={client.displayAllCases} height={20} width={40} />
                    <div className="switchLabel">Show all cases</div>
                </div><br />

                <div className="clientCardLabel">Delivery</div>
                <div className="switchContainer">
                    <Switch className="switchCheckbox" onChange={checked => this.props.updateClientInfo(client._id, "showCostInDelivery", checked)} checked={client.showCostInDelivery} height={20} width={40} />
                    <div className="switchLabel">Show costs in delivery</div>
                </div><br />

                <div className="clientCardLabel">Logo</div>
                <div className="clientLogo" style={{
                    width: client.logo ? '125px' : '175px',
                    backgroundImage: 'url("' + client.logo + '")'
                }}>
                    <div className="clientLogoOverlay" onClick={() => this.uploadLogoAsBase64URL()}>{client.logo ? 'Change' : 'Upload'}</div>
                </div>

                {client.logo ? <div className="clientLogoClear" onClick={() => this.props.updateClientInfo(client._id, "logo", null)}>Clear</div> : ''}
                
                {this.props.changed ? <div className="clientButton clientSaveButton" onClick={() => this.props.saveClientInfo(client._id)}>Save</div> : ''}

                <div className="clientButton clientDeleteButton" onClick={() => this.props.deleteClient(client._id)}>Delete</div>
            </div>
        );
    }

}