// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Loading from 'react-loading';
import { withRouter } from 'react-router-dom';

import ProjectsPortal from '../../ProjectsPortal';

import '../../../styles/CenterProject.css';

class CenterProject extends Component {

    state = {
        loading: false,
        uploading: {}
    }

    render() {

        const project = this.props.project;

        return (
            <div className="centerProjectRow" ref={'project-' + project._id} style={ProjectsPortal.getProjectStyleModifier(project.status)}>

                {Object.keys(this.props.columns).map((key, idx) => <div key={'project-' + project._id + '_' + key} className="centerProjectRowItem" style={{
                    width: "calc(" + this.props.columns[key].width + "% - 10px)",
                    maxWidth: this.props.columns[key].maxWidth-10 + "px"
                }}>

                    {ProjectsPortal.getDisplayValueFromKey(project, key, true)}

                </div>)}
                
                {/* View Case files */}
                <div>
                    <img className="centerProjectImg" src={project.filesCount ? "/resources/icons/folder.png" : "/resources/icons/emptyFolder.png"} alt="Files" ref={'files-' + project._id} onClick={() => {
                        window.open('/files/' + project.case, '_blank');
                        }} title="View Case Files" />
                </div>

                {this.state.loading ? <div ref="loadingIcon" style={{
                    float: 'right',
                    marginTop: '1px',
                    marginLeft: '10px'
                }}>
                    <Loading type="spin" color="rgb(80,80,80)" height={'28px'} width={'28px'} />
                </div> : ''}
                
            </div>
        );
    }

}

export default withRouter(CenterProject);