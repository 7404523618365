// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

import '../styles/Header.css'
import api from '../api';

class Header extends Component {

    state = {
        profileOpened: false,
        newPassword: null
    }

    getProfileStyleModifier = () => {
        if (this.state.profileOpened)
            return {
                display: 'block',
                height: this.props.user ? this.props.user.type === "ops" || this.props.user.type === "lead" ? "169px" : "" : ""
            };
        
        else 
            return {
                display: 'none'
            };
    }

    getShortUserName = () => {
        if (!this.props.user)
            return "";
        
        let userName = this.props.user.name;

        if (userName.length <= 4)
            return userName;
        
        let userNameBegin = userName.substring(0,4);
        let userNameEnd = userName.substring(4);
        
        if (!userNameEnd.includes(" "))
            return userName;

        return userNameBegin + userNameEnd.substring(0,userNameEnd.indexOf(" "));
    }

    onChangePassword = async () => {

        if (!this.state.newPassword || this.state.newPassword === "")
            return toast.error("Password cannot be empty!");

        let res = await api.post('resetMyPassword', {
            password: this.state.newPassword
        });

        if (!res || !res.success)
            return toast.error("Unable to change password.");
        
        toast.info("Successfully changed password.");

        this.setState({
            newPassword: null
        });
    }


    allowUserPortal = () => {

        let allowedUserTypes = ["ops", "center"];
        if (this.props.user && allowedUserTypes.includes(this.props.user.type))
            return true;
        else    
            return false;
    }

    getCasesRedirect = () => {

        switch(this.props.user.type) {

            case 'ops':
                return '/';
            case 'lead':
                return '/';
            case 'center':
                return '/center';
            case 'endUser':
                return '/centerenduser';
            default:
                return '/review';

        }
    }

    render() {
        return (
            <div className="header">
                
                {/* Corporate logo */}
				<img onClick={() => window.open("https://www.multusmedical.com", "_blank")} src="/resources/logo.png" alt="logo" />

                {/* Title/heading */}
                <h1 onClick={() => this.props.history.push('/')}><span style={{ fontWeight: '500' }}>Multus</span>Portal</h1>

                {/* Navigation Links */}
                <ul>

                    {this.props.user && ['ops','lead'].includes(this.props.user.type) ? <li><NavLink exact to="/center" activeClassName="activeRoute">Docs</NavLink></li> : ''}

                    {this.props.user ? <li><NavLink exact to={this.getCasesRedirect()} activeClassName="activeRoute">Cases</NavLink></li> : ''}

                    {this.props.user && ['ops','lead'].includes(this.props.user.type) ? <li><NavLink exact to="/animation" activeClassName="activeRoute">Animation</NavLink></li> : ''}

                    {this.props.user && this.props.user.type === 'ops' ? <li><NavLink exact to="/clients" activeClassName="activeRoute">Clients</NavLink></li> : ''}

                    {this.props.user && this.props.user.type === 'ops' ? <li><NavLink exact to="/centers" activeClassName="activeRoute">Centers</NavLink></li> : ''}

                    {/* {this.props.user && this.props.user.type === 'ops' ? <li><NavLink exact to="/stats" activeClassName="activeRoute">Stats</NavLink></li> : ''} */}

                    {this.allowUserPortal() ? <li><NavLink exact to={this.props.user.type === 'ops' ? '/users' : '/center/users'} activeClassName="activeRoute">Users</NavLink></li> : ''}

                    {this.props.user && this.props.user.type === 'ops' ? <li><NavLink exact to="/tasks" activeClassName="activeRoute">Tasks</NavLink></li> : ''}

                    {/* {this.props.user && ['ops','lead'].includes(this.props.user.type) ? <li><NavLink exact to="/upload" activeClassName="activeRoute">Upload</NavLink></li> : ''} */}

                    {this.props.user && this.props.user._id ? <li>
                        <div className={"navLink" + (this.state.profileOpened ? " activeRoute" : "")} onClick={() => {
                            this.setState({
                                profileOpened: !this.state.profileOpened
                            });
                        }}>Profile</div>
                    </li> : ''}

                    <div className="profileContainer" style={this.getProfileStyleModifier()}>

                        <div className="profileUserName">
                            Hi {this.getShortUserName()}...
                        </div>

                        {this.props.user ?
                        <div className="toggleContainer" style={{paddingTop: "5px"}}>
                            <Switch className="toggleCheckbox" onChange={()=>{this.props.toggleNotifications()}} checked={typeof this.props.user.emailNotifications !== 'undefined' ? this.props.user.emailNotifications : false} height={17} width={34} />
                            <div className="toggleLabel">Email Notifications</div>
                        </div> : ""}

                        {this.props.user ? this.props.user.type === "ops" || this.props.user.type === "lead" ?
                        <div className="toggleContainer" style={{paddingTop: "5px", top: "69px"}}>
                            <Switch className="toggleCheckbox" onChange={()=>{this.props.toggleDeliveryNotifications()}} checked={typeof this.props.user.deliveryNotifications !== 'undefined' ? this.props.user.deliveryNotifications : false} height={17} width={34} />
                            <div className="toggleLabel">Delivery Notifications</div>
                        </div> : "" : ""}
                        
                        {/* Password Editor */}
                        {this.state.newPassword !== null ?
                             <input type="password" 
                                    ref="newPassword" 
                                    name="newPassword" 
                                    placeholder="Password" 
                                    style={this.props.user ? this.props.user.type === "ops" || this.props.user.type === "lead" ? {top: "101px"} : null : null} 
                                    value={this.state.newPassword} 
                                    onChange={(e) => this.setState({ newPassword: e.target.value })} 
                            /> 
                        : ''}

                        {/* Change / Confirm Button */}
                        {this.state.newPassword === null ?
                            <div className="profileButton changePasswordButton" 
                                 style={this.props.user ? this.props.user.type === "ops" || this.props.user.type === "lead" ? {top: "101px"} : null : null} 
                                 onClick={() => this.setState({
                                newPassword: ''
                            })}>
                                Change Password
                            </div> 
                            : 
                            <div className="profileButton confirmButton" 
                                 style={this.props.user ? this.props.user.type === "ops" || this.props.user.type === "lead" ? {top: "101px"} : null : null} 
                                 onClick={() => this.onChangePassword()}
                            >
                                Confirm
                            </div>}

                        <div className="profileButton logoutButton" style={this.props.user ? this.props.user.type === "ops" || this.props.user.type === "lead" ? {top: "135px"} : null : null} onClick={() => this.props.onLogout()}>
                            Logout
                        </div>

                    </div>

                </ul>

            </div>
        )
    }
}

export default withRouter(Header);