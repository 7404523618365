// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import posed from 'react-pose';
import Loading from 'react-loading';

import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

import SearchBar from './SearchBar';
import DataHeader from './DataHeader';

import api from '../api';

import { toast } from 'react-toastify';


import '../styles/ProjectsPortal.css';

// Posed div for search bar
const ProjectsPosed = posed.div({
    searchEnabled: { paddingTop: '34px' },
    searchDisabled: { paddingTop: '0px' }
});

const REFRESH_INTERVAL_MS = 15000; // 15 seconds
const PROJECTS_PER_PAGE = 25;

export default class ProjectsPortal extends Component {

    constructor(props) {

        super(props);

        // Initial state
        this.state = {
            projects: [],
            searchEnabled: false,
            sortBy: { field: "case", direction: 0 },
            search: {},
            displayOrder: [],
            displayProjects: [],
            users: {},
            clients: {},
            editing: false,
            openedEditors: {}, // ProjectId => {editor: str, saving: bool}
            fetchDays: 7,
            displayPage: 0,
            loading: true,
            jobManager: {
                projectId: '',
                opened: false
            },
            fetchFiles: false,
            recentDayFilter: true,
            fetchStartDate: null,
            fetchEndDate: null,
            calendarFocusedInput: null,
        };

        // Bind child functions
        this.onHeaderFieldClick = this.onHeaderFieldClick.bind(this);
        this.onSearchEvent = this.onSearchEvent.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);

        this.iconOffset = '0px';

        this.refreshProjects = setInterval(() => {
            if (
                !this.state.editing && 
                Object.keys(this.state.openedEditors).length === 0 &&
                this.state.fetchDays !== 'all'
                )
                this.fetchProjects();
        }, REFRESH_INTERVAL_MS);

    }

    columns = {}

    componentWillUnmount = () => {
        clearInterval(this.refreshProjects);
    }

    // Update job
    updateJob = async () => {

        if (this.state.jobManager.opened && this.state.jobManager.projectId !== '') {
            let res = await api.post('getProjectAIJobs', {
                pId: this.state.jobManager.projectId
            });

            if (!res || !res.success || !res.data)
                return;

            let projects = [...this.state.projects];

            if (projects[this.getProjectIdx(this.state.jobManager.projectId)])
                projects[this.getProjectIdx(this.state.jobManager.projectId)].ai = res.data;

            await this.setState({  projects });

        }

    }

    // Kill job
    killJob = async (job) => {

        this.setState({
            loading: true
        });

        await api.post('killProjectAIJob', {
            pId: this.state.jobManager.projectId,
            job
        });

        await this.updateJob();

        setTimeout(() => this.setState({ loading: false }), 250);

    }

    fetchProjects = async (search) => {

        document.title = "Portal | Multus Medical";

        let res;

        let url =  window.location.href.includes('/animation')?
        'getAnimations' :
         'getProjects';

        // Case: is a custom 'all' search
        if (this.state.fetchDays === 'all' && this.state.recentDayFilter && search){
            res = await api.post(url, {search:search});
            if(!res.success)    return toast.error(res.error);

        }
        // Case: Fetch By Days
        else if (this.state.recentDayFilter)
            res = await api.post(url, {
                "range": "lastAccess",
                "upper": Date.now() + 86400000,
                "lower": Date.now() - this.state.fetchDays*86400000,
                "fetchFiles": this.state.fetchFiles
            });

        // Case: Fetch By Dates
        else if (!this.state.recentDayFilter && (this.state.fetchStartDate || this.state.fetchEndDate)) {
            // Process dates so that we are inclusive
            let upperDate = new Date(this.state.fetchEndDate);
            upperDate.setHours(23,59,59,999);
            let lowerDate = new Date(this.state.fetchStartDate);
            lowerDate.setHours(0);

            res = await api.post(url, {
                "range": "receiveDate",
                "upper": upperDate,
                "lower": lowerDate,
                "fetchFiles": this.state.fetchFiles
            })
        }

        if (!res || !res.data || !res.success)
            return res;

        let projects = res.data;

        // Apply the current search
        let filtered = this.retrieveSearchprojects(projects, this.state.search);

        // Apply the current sort
        filtered = await this.sortProjects([...projects], this.state.sortBy.field, this.state.sortBy.direction, filtered);

        // Set the state
        await this.setState({
            projects: projects,
            displayOrder: filtered,
            displayProjects: filtered.slice(this.state.displayPage*PROJECTS_PER_PAGE, (this.state.displayPage+1)*PROJECTS_PER_PAGE),
        });

        await this.updateJob();

        // Let display render
        setTimeout(() => this.setState({ loading: false }), 250);

        return res;

    }

    getProjectsByCase = (caseNumber) => {

        let caseProjects = [];

        for (let project of this.state.projects) {
            if (project.case === caseNumber)
                caseProjects.push(project);
        }

        return caseProjects;

    }

    // Get project idx in array from _id
    getProjectIdx = (projectId) => {
        for (let idx = 0; idx < this.state.projects.length; idx++)
            if (this.state.projects[idx]._id === projectId)
                return idx;
    }

    // Get project data from _id
    getProjectById = (projectId) => {
        return this.state.projects[this.getProjectIdx(projectId)];
    }

    // Update project data
    updateProjects = async (modifiedProjects) => {

        let projects = [...this.state.projects];

        for (let modifiedProject of modifiedProjects)
            projects[this.getProjectIdx(modifiedProject._id)] = modifiedProject;

        await this.setState({
            projects: projects
        });

    }

    // Hide/ show search bar
    toggleSearch = () => {

        this.setState({
            searchEnabled: !this.state.searchEnabled
        })
        return;

    }

    // Searching Functionality
    onSearchEvent = async (e) => {

        // Add the search to the currentSearch
        // TODO: Be sure to use this naming convention
        await this.setState({
            search: {
                ...this.state.search,
                [e.target.name]: e.target.value
            }
        });

        this.applySearchAndFilter();
    }

    // Searching All Functionality (when enter key is pressed in field - only works in ALL status)
    onSearchAllEvent = async (e) => {
        this.fetchProjects({[e.target.name]: e.target.value})
    }

    // Search and filter
    applySearchAndFilter = async () => {

        // Apply the current search
        var filtered = this.retrieveSearchprojects(this.state.projects, this.state.search);

        // Apply the current sort
        filtered = await this.sortProjects([...this.state.projects], this.state.sortBy.field, this.state.sortBy.direction, filtered);

        // Set the state
        this.setState({
            displayOrder: filtered,
            displayProjects: filtered.slice(this.state.displayPage*PROJECTS_PER_PAGE, (this.state.displayPage+1)*PROJECTS_PER_PAGE)
        })

    }


    retrieveSearchprojects = (projects, curSearch) => {

        let unfiltered = [...Array(projects.length).keys()];

        var filtered = [];
        for (let i = 0; i < unfiltered.length; i++) {

            var add = true;

            // Go through each key
            for (let field of Object.keys(curSearch)) {

                if (curSearch[field] === undefined | curSearch[field] === "")
                    continue;

                let fieldValue = ProjectsPortal.getValueFromKey(projects[unfiltered[i]], this.state.users, this.state.clients, field, true);

                if (!fieldValue.toString().toLowerCase().includes(curSearch[field].toString().toLowerCase()))
                    add = false;

            }

            if (add)
                filtered.push(unfiltered[i]);

        }

        return filtered;
    }


    // Sort Functionality

    // Return an array of sorted case indices
    sortProjects = async (projects, field, direction, allowedIndices) => {

        var unsorted = allowedIndices;

        var sorted = [];

        // Move direction binary from 0/1 to -1/1
        if (direction === 0)
            direction = -1;


        // Sort the projects
        Object.assign(sorted, unsorted.sort(this.sortWrapper(field, direction, projects, this.state.users)));

        if (sorted.length < this.state.displayPage*PROJECTS_PER_PAGE)
            await this.setState({ displayPage: 0 });

        return sorted;

    }

    sortWrapper = (field, sortDirection, projects, users) => {

        // Returns a function of (a,b), that compares based on the value corresponding to the field-key
        return (a, b) => {

            let A = ProjectsPortal.getValueFromKey(projects[a], users, this.state.clients, field);
            let B = ProjectsPortal.getValueFromKey(projects[b], users, this.state.clients, field);

            if (A > B)
                return 1 * sortDirection;
            else if (A < B)
                return -1 * sortDirection;
            else
                return 0;
        }

    }

    // Style changes based on case status
    static getProjectStyleModifier = (status, opacity=1) => {

        let styleModifier = {};

        // styleModifier.width = 'calc(100% - ' + iconOffset + ')';
        styleModifier.opacity = opacity;

        if (!status) {
            styleModifier.backgroundColor = 'rgba(80,80,80,0.3)';
            return
        }

        if (status === "Processing Docs")
            styleModifier.backgroundColor = 'rgba(80,80,80,0.3)';

        else if (status === "Segment")
            styleModifier.backgroundColor = 'rgba(240,200,0,0.3)';

        else if (status.substring(0,9) === "Animation")
            styleModifier.backgroundColor = 'rgba(199,0,239,0.3)';

        else if (status === "Comparison")
            styleModifier.backgroundColor = 'rgba(191,127,63,0.3)';

        else if (status.substring(0,2) === "QC")
            styleModifier.backgroundColor = 'rgba(0,240,120,0.3)';

        else if (status === "Rejected" || status.substring(0,5) === "Error")
            styleModifier.backgroundColor = 'rgba(160,0,0,0.3)';
        
        else if (status === "Approved")
            styleModifier.backgroundColor = 'rgba(0,160,0,0.3)';

        else if (status === "Review Docs" || status === "Review")
            styleModifier.backgroundColor = 'rgba(0,80,160,0.3)';
        
        else if (status === "Removed")
            styleModifier.backgroundColor = 'rgba(40,40,40,0.5)';

        else if (status === "FOR TESTING ONLY")
            styleModifier.opacity = 0.3;
        
        return styleModifier;

    }

    // Style changes based on case status
    static getAnimationProjectStyleModifier = (status, opacity=1) => {

        let styleModifier = {};

        // styleModifier.width = 'calc(100% - ' + iconOffset + ')';
        styleModifier.opacity = opacity;

        if (!status) {
            styleModifier.backgroundColor = 'rgba(80,80,80,0.3)';
            return
        }

        if (status === "Processing Docs")
            styleModifier.backgroundColor = 'rgba(80,80,80,0.3)';

        else if (status === "Storyboard")
            styleModifier.backgroundColor = 'rgba(240,200,0,0.3)';

        else if (status === "Resolve")
            styleModifier.backgroundColor = 'rgba(0,0,200,0.3)';

        else if (status === "Animate")
            styleModifier.backgroundColor = 'rgba(199,0,239,0.3)';

        else if (status === "Render/Review")
            styleModifier.backgroundColor = 'rgba(60,0,80,0.3)';
    
        else if (status === "Revision")
        styleModifier.backgroundColor = 'rgba(239,50,50,0.3)';
    
        else if (status === "Final Review")
            styleModifier.backgroundColor = 'rgba(0,239,50,0.3)';
        
        else if (status === "Revision Fixed")
            styleModifier.backgroundColor = 'rgba(0,239,239,0.3)';

        else if (status.substring(0,9) === "Animation")
            styleModifier.backgroundColor = 'rgba(199,0,239,0.3)';

        else if (status === "Comparison")
            styleModifier.backgroundColor = 'rgba(191,127,63,0.3)';

        else if (status.substring(0,2) === "QC")
            styleModifier.backgroundColor = 'rgba(0,240,120,0.3)';

        else if (status === "Rejected" || status.substring(0,5) === "Error")
            styleModifier.backgroundColor = 'rgba(160,0,0,0.3)';
        
        else if (status === "Approved")
            styleModifier.backgroundColor = 'rgba(0,160,0,0.3)';

        else if (status === "Review Docs" || status === "Review")
            styleModifier.backgroundColor = 'rgba(0,80,160,0.3)';
        
        else if (status === "Removed")
            styleModifier.backgroundColor = 'rgba(40,40,40,0.5)';

        else if (status === "FOR TESTING ONLY")
            styleModifier.opacity = 0.3;
        
        return styleModifier;

    }

    // Format date to how we want to display
    static formatDate = (date) => {

        if (!date || date === "")
            return "N/A";

        let stringDate = date;

        if (typeof stringDate !== "string")
            stringDate = date.toISOString()
        
        date = new Date(Date.parse(stringDate.split('T')[0] + "T00:00"));

        return (date.getMonth()+1).toString().padStart(2, '0') + "/" + date.getDate().toString().padStart(2, '0') + "/" + date.getFullYear().toString();

    }

    // Format time to how we want to display
    static formatTime = (date) => {
        return new Date(Date.parse(date)).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        });
    }

    // Is this date today?
    static isToday = (dateString) => {
        const today = new Date();
        let date = new Date(Date.parse(dateString));
        return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    }

    // Get project column value from field (non-id values)
    static getValueFromKey = (project, users, clients, field, pretty = false) => {
        switch (field) {

            case 'patientName':
                return project.patient.name;

            case 'patientSex':
                return project.patient.sex;

            case 'patientDateOfBirth':
                return pretty ? this.formatDate(project.patient.dateOfBirth) : project.patient.dateOfBirth;

            case 'centerName':
                return project.center.group + "/" + project.center.name;

            case 'studyDate':
                return pretty ? this.formatDate(project.studyDate) : project.studyDate;
            
            case 'receiveDate':
                return pretty ? this.formatDate(project.receiveDate) : project.receiveDate;
                
            case 'lastAccess':
                if (pretty)
                    return this.isToday(project.lastAccess) ? this.formatTime(project.lastAccess) : this.formatDate(project.lastAccess);
                
                else
                    return project.lastAccess;
            
            case 'handle':
                    return (project.status === 'Awaiting Docs' || project.status === 'Processing Docs' || project.status === 'Review Docs') ? "Center" : "Multus";

            case 'client':
                return clients[project.client] ? clients[project.client].name : '';

            case 'assignee':
                return users[project.assignee] ? users[project.assignee].name : '';

            case 'radiologist':
                return users[project.radiologist] ? users[project.radiologist].name : '';

            case 'physician':
                return project.attendingPhysician && project.attendingPhysician.name ? project.attendingPhysician.name : '';

            case 'attorney':
                return project.attorney && project.attorney.name ? project.attorney.name : '';

            default:
                return field in project ? project[field] : "";

        }
    }

    // Get project column value from field (mapping to database)
    static getDisplayValueFromKey = (project, field, pretty = false) => {
        switch (field) {

            case 'patientName':
                return project.patient.name;

            case 'patientSex':
                return project.patient.sex;

            case 'patientDateOfBirth':
                return pretty ? this.formatDate(project.patient.dateOfBirth) : project.patient.dateOfBirth;

            case 'centerName':
                return (<span><u>{project.center.group.toUpperCase()}</u>: {project.center.name}</span>);

            case 'studyDate':
                return pretty ? this.formatDate(project.studyDate) : project.studyDate;
            
            case 'receiveDate':
                return pretty ? this.formatDate(project.receiveDate) : project.receiveDate;
            
            case 'lastAccess':
                    if (pretty)
                        return this.isToday(project.lastAccess) ? this.formatTime(project.lastAccess) : this.formatDate(project.lastAccess);
                    
                    else
                        return project.lastAccess;
            
            case 'handle':
                return (project.status === 'Awaiting Docs' || project.status === 'Processing Docs' || project.status === 'Review Docs') ? "Center" : "Multus";

            default:
                return field in project ? project[field] : "";

        }
    }

    // Handle header field clicks
    onHeaderFieldClick = async (e, field) => {

        // Check if disabled
        const disabled = []
        if (disabled.includes(field))
            return;

        var sortDirection = 0;

        // If this is the active sort: 
        if (field === this.state.sortBy.field)
            // Switch the direction of the sort
            sortDirection = (this.state.sortBy.direction + 1) % 2;


        // Sort the projects
        const sorted = await this.sortProjects([...this.state.projects], field, sortDirection, this.state.displayOrder);

        this.setState({
            sortBy: { field: field, direction: sortDirection },
            displayOrder: sorted,
            displayProjects: sorted.slice(this.state.displayPage*PROJECTS_PER_PAGE, (this.state.displayPage+1)*PROJECTS_PER_PAGE)
        })

    }

    setPage = async (page) => {

        if (page >= 0 && page*PROJECTS_PER_PAGE < this.state.displayOrder.length)
            this.setState({
                displayPage: page,
                displayProjects: this.state.displayOrder.slice(page*PROJECTS_PER_PAGE, (page+1)*PROJECTS_PER_PAGE)
            });

    }

    setFilterDates = async ({startDate, endDate}) => {
        
        // Update start and end dates
        await this.setState({
            fetchStartDate: startDate,
            fetchEndDate: endDate
        });

        // Dates not complete
        if (!startDate || !endDate)
            return;

        await this.setState({loading: true});
        await this.fetchProjects();
    }

    toggleFetchMode = async (refetch = true) => {

        // Toggle mode
        await this.setState({
            recentDayFilter: !this.state.recentDayFilter,
            // Also reset the calendar
            fetchStartDate: null,
            fetchEndDate: null,
        })

        if (refetch) {

            // Set loading
            await this.setState({ loading: true })

            // Refetch projects
            this.fetchProjects();
        }

    }

    onCalendarFocusChange = (focusedInput) => {
      
        this.setState({ calendarFocusedInput: focusedInput });

    }

    // Virtual...
    renderProjects() {

        return (
            <div>
                {/* Inherit this class to create views */}
            </div>
        );

    }

    render() {

        return (
            <div className="projectsRootContainer">

                {/* Field Header */}
                <DataHeader currentSort={this.state.sortBy} columns={this.columns} disabledColumns={[]} iconOffset={this.iconOffset} clickHandler={this.onHeaderFieldClick} toggleSearch={this.toggleSearch} page={this.state.displayPage} numInPage={PROJECTS_PER_PAGE} totalCount={this.state.displayOrder.length} setPage={this.setPage} />

                {/* Search Bar */}
                <SearchBar searchEnabled={this.state.searchEnabled} columns={this.columns} iconOffset={this.iconOffset} searchHandler={this.onSearchEvent} searchAllHandler={this.onSearchAllEvent} />

                {/* projects */}
                <ProjectsPosed className="projectsContainer" pose={this.state.searchEnabled ? 'searchEnabled' : 'searchDisabled'}>
                    {this.renderProjects()}
                   
                    {this.state.recentDayFilter ? 
                        // Last "X" Days Filter
                        <div className="lastAccessFilter">Showing cases {this.state.displayPage*PROJECTS_PER_PAGE+1} to {Math.min((this.state.displayPage+1)*PROJECTS_PER_PAGE,this.state.displayOrder.length)} of {this.state.displayOrder.length} accessed within the last 
                        <select className="lastAccessFilterSelect" value={this.state.fetchDays} onChange={async (e) => {
                            await this.setState({
                                fetchDays: e.target.value,
                                loading: true
                            });

                            await this.fetchProjects();
                        }}>
                            <option value="7">7</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                            <option value="60">60</option>
                            <option value="all">all</option>
                        </select>
                        days only.</div>
                        
                        :

                        // Date Range Filter
                        <div className="lastAccessFilter">
                            <DateRangePicker
                                startDate={this.state.fetchStartDate}
                                startDateId={'0'}
                                endDate={this.state.fetchEndDate}
                                endDateId={'1'}
                                onDatesChange={this.setFilterDates}
                                focusedInput={this.state.calendarFocusedInput}
                                onFocusChange={this.onCalendarFocusChange}

                                noBorder={true}
                                small={true}
                                showClearDates={true}

                                minDate={moment().subtract(10, 'year')}
                                maxDate={moment().add(1, 'year').subtract(1, 'M')}
                                numberOfMonths={2}
                                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment().add(1, 'day'))}
                            />
                        </div>
                    }

                    {/* Switch Fetch Method  */}
                    <div className="lastAccessFilter">
                        {this.state.recentDayFilter ? 
                            <div className="switchFetchLink" onClick={(e)=>this.toggleFetchMode(false)}>
                                Click to switch to calendar filter
                            </div> 
                                : 
                            <div className="switchFetchLink" onClick={this.toggleFetchMode}>
                                Click to switch to recent access filter
                            </div>
                        }
                    </div>

                </ProjectsPosed>

                {/* Loading overlay */}
                {this.state.loading ? <div style={{
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    position: 'fixed',
                    left: '0',
                    right: '0',
                    top: '40px',
                    bottom: '0',
                    zIndex: '50'
                }}>
                    <div className="loadingIconContainer">
                        <Loading type="spin" color="rgba(230,155,30,0.5)" height={'120px'} width={'120px'} />
                    </div>
                </div> : ''}
                
            </div>
        );

    }

}