// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import posed from 'react-pose';
import Loading from 'react-loading';

import SearchBar from '../../SearchBar';
import DataHeader from '../../DataHeader';
import OpsTasks from './OpsTasks';

import api from '../../../api';

import '../../../styles/TasksPortal.css';
import { toast } from 'react-toastify';

// Posed div for search bar
const TasksPosed = posed.div({
    searchEnabled: { paddingTop: '34px' },
    searchDisabled: { paddingTop: '0px' }
});

const TODAY = new Date();

export default class TasksPortal extends Component {

    constructor(props) {

        super(props);

        // Initial state
        this.state = {
            tasks: [],
            searchEnabled: false,
            sortBy: { field: "deliverDate", direction: 0 },
            search: {},
            displayOrder: [],
            fetchMonth: TODAY.getMonth(),
            fetchYear: TODAY.getFullYear(),
            loading: true,
            notesBarOpened: false,
            notesBarId: '',
        };

        // Bind child functions
        this.onHeaderFieldClick = this.onHeaderFieldClick.bind(this);
        this.onSearchEvent = this.onSearchEvent.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);

        this.iconOffset = '0px';

    }

    columns = {
        "facility": { label: "MRI Facility", width: 8, maxWidth: 150},
        "patientName": { label: "Patient Name", width: 12, maxWidth: 320},
        "patientDateOfBirth": { label: "DoB", width: 6, maxWidth: 100},
        "studyDate": { label: "Study Date", width: 6, maxWidth: 100},
        "studyType": { label: "Study Type", width: 6, maxWidth: 140},
        "attorney": { label: "Attorney", width: 8, maxWidth: 100},
        "attorneyContact": { label: "Contact", width: 12, maxWidth: 320},
        "invoiceNumber": { label: "Invoice Number", width: 8, maxWidth: 120},
        "attorneyPaid": { label: "Paid?", width: 4, maxWidth: 100},
        "deliverDate": { label: "Deliver Date", width: 6, maxWidth: 100},
        "followUpDate": { label: "Follow Up Date", width: 6, maxWidth: 100},
    }

    componentDidMount = async () => {

        // Fetch tasks
        let res = await this.fetchTasks();

        if (!res || !res.auth)
            window.location = "/login";

        if (!res.auth._id)
            window.location = "/login";
        
        else if (res.auth.type !== 'ops')
            window.history.back();
    }

    fetchTasks = async () => {

        let res = await api.post('getLienTasks', {
            year: this.state.fetchYear,
            month: this.state.fetchMonth
        })

        if (!res || !res.data || !res.success)
            return;

        let tasks = res.data;

        // Apply the current search
        let filtered = this.retrieveSearchtasks(tasks, this.state.search);

        // Apply the current sort
        filtered = await this.sortTasks([...tasks], this.state.sortBy.field, this.state.sortBy.direction, filtered);

        // Set the state
        await this.setState({
            tasks: tasks,
            displayOrder: filtered,
        })

        setTimeout(() => this.setState({ loading:false }), 250);

        return res;
    }

    // Get project idx in array from _id
    getTaskIdx = (taskId) => {
        for (let idx = 0; idx < this.state.tasks.length; idx++)
            if (this.state.tasks[idx]._id === taskId)
                return idx;
    }

    updateTasks = async (modifiedTasks) => {

        let tasks = [...this.state.tasks];

        for (let modifiedTask of modifiedTasks)
            tasks[this.getTaskIdx(modifiedTask._id)] = modifiedTask;

        await this.setState({
            tasks: tasks
        });
    }

    updateTaskValues = async (projectId, field, value) => {

        let res = await api.post('syncLienTask', {
            pId: projectId,
            field: field,
            value: value
        });

        if (!res || !res.success || !res.data) {
            toast.error(field.charAt(0).toUpperCase() + field.slice(1) + " failed to update")
            return;
        }

        // Update
        this.updateTasks([res.data]);

        toast.success(field.charAt(0).toUpperCase() + field.slice(1) + " updated successfully")
        
    }

    invoiceNumberInput = async (projectId, value) => {

        let task = this.state.tasks[this.getTaskIdx(projectId)];

        if (!task.deliveryData.invoiceNumber)
            task.deliveryData.invoiceNumber = '';
        task.deliveryData.invoiceNumber = value;

        this.updateTasks([task])
    }

    notesInput = async (projectId, value) => {

        let task = this.state.tasks[this.getTaskIdx(projectId)];

        if (!task.deliveryData.notes)
            task.deliveryData.notes = '';
        task.deliveryData.notes = value;

        this.updateTasks([task])
    }

    // Hide/ show search bar
    toggleSearch = () => {

        this.setState({
            searchEnabled: !this.state.searchEnabled
        })
        return;

    }

    // Searching Functionality
    onSearchEvent = async (e) => {

        // Add the search to the currentSearch
        // TODO: Be sure to use this naming convention
        await this.setState({
            search: {
                ...this.state.search,
                [e.target.name]: e.target.value
            }
        });

        this.applySearchAndFilter();
    }

    // Search and filter
    applySearchAndFilter = async () => {

        // Apply the current search
        var filtered = this.retrieveSearchtasks(this.state.tasks, this.state.search);

        // Apply the current sort
        filtered = await this.sortTasks([...this.state.tasks], this.state.sortBy.field, this.state.sortBy.direction, filtered);

        // Set the state
        this.setState({
            displayOrder: filtered,
        })

    }

    retrieveSearchtasks = (tasks, curSearch) => {

        let unfiltered = [...Array(tasks.length).keys()];

        var filtered = [];
        for (let i = 0; i < unfiltered.length; i++) {

            var add = true;

            // Go through each key
            for (let field of Object.keys(curSearch)) {

                if (curSearch[field] === undefined | curSearch[field] === "")
                    continue;

                let fieldValue = TasksPortal.getValueFromKey(tasks[unfiltered[i]], field, true);

                if (!fieldValue.toString().toLowerCase().includes(curSearch[field].toString().toLowerCase()))
                    add = false;

            }

            if (add)
                filtered.push(unfiltered[i]);

        }

        return filtered;
    }


    // Sort Functionality

    // Return an array of sorted case indices
    sortTasks = async (tasks, field, direction, allowedIndices) => {

        var unsorted = allowedIndices;

        var sorted = [];

        // Move direction binary from 0/1 to -1/1
        if (direction === 0)
            direction = -1;


        // Sort the tasks
        Object.assign(sorted, unsorted.sort(this.sortWrapper(field, direction, tasks)));

        return sorted;

    }

    sortWrapper = (field, sortDirection, tasks) => {

        // Returns a function of (a,b), that compares based on the value corresponding to the field-key
        return (a, b) => {

            let A = TasksPortal.getValueFromKey(tasks[a], field);
            let B = TasksPortal.getValueFromKey(tasks[b], field);

            if (A > B)
                return 1 * sortDirection;
            else if (A < B)
                return -1 * sortDirection;
            else
                return 0;
        }

    }

    // Style changes based on status
    static getNotesStyleModifier = (paid, opacity=1) => {

        let styleModifier = {};

        styleModifier.opacity = opacity;

        if (paid)
            styleModifier.backgroundColor = 'rgba(0,160,0,0.3)';
        else
            styleModifier.backgroundColor = 'rgba(230,155,30,0.5)';

        return styleModifier;
    }

    // Format date to how we want to display
    static formatDate = (date) => {
        
        if (!date || date === "")
            return "N/A";

        let stringDate = date;

        if (typeof stringDate !== "string")
            stringDate = date.toISOString()

        date = new Date(Date.parse(stringDate.split('T')[0] + "T00:00"));

        return (date.getMonth()+1).toString().padStart(2, '0') + "/" + date.getDate().toString().padStart(2, '0') + "/" + date.getFullYear().toString();
    }

    // Get task column value from field (non-id values)
    static getValueFromKey = (tasks, field, pretty = false) => {
        switch (field) {
            case 'facility':
                return tasks.center.group;

            case 'patientName':
                return tasks.patient.name;

            case 'patientDateOfBirth':
                return pretty ? this.formatDate(tasks.patient.dateOfBirth) : tasks.patient.dateOfBirth;

            case 'studyDate':
                return pretty ? this.formatDate(tasks.studyDate) : tasks.studyDate;

            case 'studyType':
                return tasks.studyType;

            case 'attorney':
                return tasks.attorney ? tasks.attorney.name : '';

            case 'attorneyContact':
                return tasks.attorney ? tasks.attorney.contact : '';

            case 'invoiceNumber':
                return tasks.deliveryData.invoiceNumber;

            case 'attorneyPaid':
                return tasks.deliveryData.attorneyPaid ? "Yes" : "No";

            case 'deliverDate':
                return pretty ? this.formatDate(tasks.deliveryData.deliverDate) : tasks.deliveryData.deliverDate;

            case 'followUpDate':
                return pretty ? this.formatDate(tasks.deliveryData.followUpDate) : tasks.deliveryData.followUpDate;

            default: 
                return

        }
    }

    // Get task column value from field (mapping to database)
    static getDisplayValueFromKey = (tasks, field, pretty = false) => {
        switch (field) {
            case 'facility':
                return tasks.center.group.toUpperCase();

            case 'patientName':
                return tasks.patient.name;

            case 'patientDateOfBirth':
                return pretty ? this.formatDate(tasks.patient.dateOfBirth) : tasks.patient.dateOfBirth;

            case 'studyDate':
                return pretty ? this.formatDate(tasks.studyDate) : tasks.studyDate;

            case 'studyType':
                return tasks.studyType;

            case 'attorney':
                return tasks.attorney ? tasks.attorney.name : '';

            case 'attorneyContact':
                return tasks.attorney ? tasks.attorney.contact : '';

            case 'deliverDate':
                return pretty ? this.formatDate(tasks.deliveryData.deliverDate) : tasks.deliveryData.deliverDate;

            default: 
                return

        }
    }

    // Handle header field clicks
    onHeaderFieldClick = async (e, field) => {

        // Check if disabled
        const disabled = []
        if (disabled.includes(field))
            return;

        var sortDirection = 0;

        // If this is the active sort: 
        if (field === this.state.sortBy.field)
            // Switch the direction of the sort
            sortDirection = (this.state.sortBy.direction + 1) % 2;


        // Sort the tasks
        const sorted = await this.sortTasks([...this.state.tasks], field, sortDirection, this.state.displayOrder);

        this.setState({
            sortBy: { field: field, direction: sortDirection },
            displayOrder: sorted,
        })

    }

    generateYearSelectOptions = () => {

        let selectOptions = [];
        for (let i = 2020; i <= TODAY.getFullYear(); i++)
            selectOptions.push(<option key={"year" + i} value={i}>{i}</option>)

        return selectOptions;
    }

    generateMonthSelectOptions = () => {

        let selectOptions = [];
        for (let i = 0; i < 12; i++)
            selectOptions.push(<option key={"month" + i} value={i}>{new Date(2020, i, 1).toLocaleString('default', { month: 'long' })}</option>)

        return selectOptions;
    }

    renderTasks = () => {

        return (
            <div>
                {this.state.displayOrder.map(idx => (
                    <OpsTasks
                        key={this.state.tasks[idx]._id}
                        task={this.state.tasks[idx]}
                        uniqueIdentifier={idx}
                        searchEnabled={this.state.searchEnabled}
                        columns={this.columns}
                        iconOffset={this.iconOffset}
                        onUpdateTasks={this.updateTaskValues}
                        onInvoiceNumberChange={this.invoiceNumberInput}
                        onNotesChange={this.notesInput}
                        manageNotes={() => this.setState({notesBarOpened: true, notesBarId: this.state.tasks[idx]._id})}
                        openedNotes={this.state.notesBarOpened ? this.state.notesBarId : ''}
                        closeNotes={() => this.setState({notesBarOpened: false})}
                    />
                ))}
            </div>
        )
    }

    render() {

        return (
            <div className="tasksRootContainer">

                {/* Field Header */}
                <DataHeader currentSort={this.state.sortBy} columns={this.columns} disabledColumns={[]} iconOffset={this.iconOffset} clickHandler={this.onHeaderFieldClick} toggleSearch={this.toggleSearch} page={0} numInPage={9999} totalCount={this.state.displayOrder.length} setPage={null} />

                {/* Search Bar */}
                <SearchBar searchEnabled={this.state.searchEnabled} columns={this.columns} iconOffset={this.iconOffset} searchHandler={this.onSearchEvent} />

                <TasksPosed className="tasksContainer" pose={this.state.searchEnabled ? 'searchEnabled' : 'searchDisabled'}>
                    {this.renderTasks()}

                    <div className="lastAccessFilter">Showing {this.state.displayOrder.length} tasks for cases delivered in
                        <select className="taskDateSelect" value={this.state.fetchMonth} onChange={async (e) => {
                            await this.setState({
                                fetchMonth: parseInt(e.target.value),
                                loading: true
                            });

                            await this.fetchTasks();
                        }}>
                            {this.generateMonthSelectOptions()}
                        </select>
                        <select className="taskDateSelect" value={this.state.fetchMonth} onChange={async (e) => {
                            await this.setState({
                                fetchYear: parseInt(e.target.value),
                                loading: true
                            });

                            await this.fetchTasks();
                        }}>
                            {this.generateYearSelectOptions()}
                        </select>                   
                    </div>
                </TasksPosed>

                {/* Loading overlay */}
                {this.state.loading ? <div style={{
                    backgroundColor: 'rgba(0,0,0,0.7)',
                    position: 'fixed',
                    left: '0',
                    right: '0',
                    top: '40px',
                    bottom: '0',
                    zIndex: '50'
                }}>
                    <div className="loadingIconContainer">
                        <Loading type="spin" color="rgba(230,155,30,0.5)" height={'120px'} width={'120px'} />
                    </div>
                </div> : ''}
                
            </div>
        );

    }

}