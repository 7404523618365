// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Loading from 'react-loading';
import { withRouter } from 'react-router-dom';

import ProjectsPortal from '../../ProjectsPortal';
import DropZone from 'react-dropzone';

import '../../../styles/CenterProject.css';

import api from '../../../api';
import path from 'path';

const docTypes = ["Combined", "Lien", "Intake", "Script", "RWR"];

class CenterProject extends Component {

    state = {
        loading: false,
        uploading: {}
    }

    // Click handler for upload document button
    uploadDocument = async (project, docType, files) => {


        console.log("UPLOAD DOCUMENT")

        let formData = new FormData();
        formData.set('pId', project._id);

        // Show loading icon
        await this.setState({
            uploading: {
                ...this.state.uploading,
                [project._id+"_"+docType]: true
            }
        })

        let res;

        if (docType === 'Combined') {

            formData.append('doc', files[0]);

            res = await api.post('uploadCombinedDoc', formData);

        }

        // All others
        else {

            formData.append('docRef', docType);
            formData.append('caseFile', files[0]);

            res = await api.post('uploadCaseFile', formData);


            console.log('resres',res)

        }

        // Hide loading icon
        await this.setState({
            uploading: {
                ...this.state.uploading,
                [project._id+"_"+docType]: false
            }
        })

        if (docType === 'Combined') {
            // Hide upload block
            this.refs['project-' + project._id].style.height = '20px';
        }
        
        // Sync project
        res = await api.post('getProject', {
            pId: project._id
        });

        if (!res || !res.success || !res.data)
            return;

        // Update project in state
        await this.props.onUpdateProjects([res.data]);
    }

    // Get specific document file
    getProjectDocument = (project, docType) => {

        // Go through project documents to find specific type
        if (project.files) {
            for (let file of project.files)
                if (file.docRef.substring(0,3) === docType.substring(0,3))
                    return path.basename(file.path);
        }
        
        
        switch (docType) {

            case "RWR":
                return "(Radiology Report)";
            
            case "Script":
                return "(Script/RX)";
            
            case "Intake":
                return "(Intake Form)";

            case "Lien":
                return "(Multus Lien)";

            case "Combined":
                return "(Combined File)";

            default:
                return "(File)";

        }

    }

    // Click handler for add case documents button
    addCaseDocuments = async () => {

        console.log("ADD CASE DOCS")

        let project = this.props.project;

        let projectDiv = this.refs['project-' + project._id];
        let confirmImg = this.refs['confirm-' + project._id];

        if (projectDiv === undefined || confirmImg === undefined)
            return;

        if (projectDiv.style.height === '120px') {

            // Confirm
            confirmImg.src = "/resources/icons/addFile.png";
            confirmImg.style.marginTop = '-6px';
            projectDiv.style.height = '20px';

            // If no RWR, return
            if (!project.files)
                return;
            
            let foundRWR = false;

            for (let file of project.files) {
                if (file.docRef === 'RWR - ' + project.studyType + ' - ' + ProjectsPortal.formatDate(project.studyDate).replace(/\//g, "-")) {
                    foundRWR = true;
                    break;
                }
            }

            if (!foundRWR)
                return;

            // Show loading screen
            await this.setState({
                loading: true
            });

            // Make confirm call
            await api.post('confirmDocsReview', {
                pId: project._id
            });

            // Sync project
            let res = await api.post('getProject', {
                pId: project._id
            });

            if (res && res.data) // Update project in state
                await this.props.onUpdateProjects([res.data]);

            // Hide loading screen
            await this.setState({
                loading: false
            });

        }
        
        else {
            
            // Add files
            confirmImg.src = "/resources/icons/confirm.png";
            confirmImg.style.marginTop = 0;
            projectDiv.style.height = '120px';

        }
    }

    // render field div
    getFieldDiv = (field) => {

        if (!this.props.physicians || !this.props.attorneys)
            return;
        
        if (field === 'status' && this.props.project.status === 'Review Docs') {
            return <div className="reviewDocsButton" onClick={() => this.props.history.push("/center/review/" + this.props.project._id + "/1")}>Review</div>
        }

        else if (field === 'status' && this.props.project.status === 'Processing Docs') {

            // Processing icon
            return ( <div>
                <div style={{
                    float: 'left',
                    marginRight: '6px'
                }}>
                    <Loading type="spin" color="rgb(80,80,80)" height={'20px'} width={'20px'} />
                </div>
                Processing...
            </div>)
        }

        else if (field === 'physician') {

            let physicianName = this.props.project['attendingPhysician'] &&  this.props.project['attendingPhysician'] ? this.props.project['attendingPhysician'].name : '';

            return (
                <select className="endUserSelect" value={{value: true, label: physicianName? physicianName: ''}} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                    e.stopPropagation();
                    this.props.updateAdressBook('physician', e.target.value, this.props.project._id);
                }}>

                    {/* Current name value */}
                    <option hidden value >{physicianName}</option>

                    {/* Option values */}
                    {Object.keys(this.props.physicians).map(clientId => {
                        if (this.props.physicians[clientId].data.group === this.props.project.center.group)
                            return (
                                <option key={'option-'+clientId} value={clientId}>{this.props.physicians[clientId].name}</option>
                            );
                        else
                            return '';
                        })
                    }
                </select>
            );
        }

        else if (field === 'attorney') {

            let attorneyName = this.props.project['attorney'] && this.props.project['attorney'].name ? this.props.project['attorney'].name : '';

            return (
                <select className="endUserSelect" defaultValue={{ value: true, label: attorneyName? attorneyName: ''}} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                    e.stopPropagation();
                    this.props.updateAdressBook('attorney', e.target.value, this.props.project._id)
                }}>

                    {/* Current name value */}
                    <option hidden value>{attorneyName}</option>
                    
                    {/* Option values */}
                    {Object.keys(this.props.attorneys).map(clientId => {
                         if (this.props.attorneys[clientId].data.group === this.props.project.center.group)
                            return (
                                <option key={'option-'+clientId} value={clientId}>{this.props.attorneys[clientId].name}</option>
                            );
                        else
                            return '';
                        })
                    }
                </select>
            );
        }
    
        else
            // Other statuses
            return ProjectsPortal.getDisplayValueFromKey(this.props.project, field, true);

    }

    render() {

        const project = this.props.project;

        return (
            <div className="centerProjectRow" ref={'project-' + project._id} style={ProjectsPortal.getProjectStyleModifier(project.status)}>

                {Object.keys(this.props.columns).map((key, idx) => <div key={'project-' + project._id + '_' + key} className="centerProjectRowItem" style={{
                    width: "calc(" + this.props.columns[key].width + "% - 10px)",
                    maxWidth: this.props.columns[key].maxWidth-10 + "px"
                }}>
                    {this.getFieldDiv(key)}
                    
                </div>)}
                
                {(this.props.user && this.props.user.data && this.props.user.data.caseFileAccess)? <div>
                    <img className="centerProjectImg" src={project.filesCount ? "/resources/icons/folder.png" : "/resources/icons/emptyFolder.png"} alt="Files" ref={'files-' + project._id} onClick={() => {
                        // this.props.history.push('/files/' + project.case);
                        window.open('/files/' + project.case, '_blank');
                        }} title="View Case Files" />
                    </div>
                    : ""}

                {project.status === 'Awaiting Docs' && !this.state.loading ? <img src="/resources/icons/addFile.png" alt="Upload" ref={'confirm-' + project._id} onClick={() => this.addCaseDocuments()} title="Add file to study" /> : ''}

                {this.state.loading ? <div ref="loadingIcon" style={{
                    float: 'right',
                    marginTop: '1px',
                    marginLeft: '10px'
                }}>
                    <Loading type="spin" color="rgb(80,80,80)" height={'28px'} width={'28px'} />
                </div> : ''}

                <div className="centerProjectRowUpload">
                    {docTypes.map(docType => <DropZone key={'project-' + project._id + '_upload-' + docType} onDrop={files => this.uploadDocument(project, docType, files)}>
                        {({getRootProps, getInputProps, isDragActive}) => (
                            <div {...getRootProps({className: 'documentUploadBox'})} style={isDragActive ? { opacity: '1' } : {}} >
                                <input {...getInputProps()} />

                                {!this.state.uploading[project._id+'_'+docType] ? <img src="/resources/icons/upload.png" alt="Select File" /> : <div className="loadingIcon">
                                    <Loading type="spin" color="rgb(80,80,80)" height={'24px'} width={'24px'} style={{
                                        marginLeft: '50px',
                                        marginRight: '50px',
                                        marginTop: '18px'
                                    }} />
                                </div>}
                        
                                {!this.state.uploading[project._id+'_'+docType] ? <div className="documentType">{this.getProjectDocument(project, docType)}</div> : ''}

                                
                            </div>
                        )}
                    </DropZone>)}
                </div>

            </div>
        );
    }

}

export default withRouter(CenterProject);