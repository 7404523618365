// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import './style.css';

// Transition system "borrowed" from Stax.ai (dev & owned by Aptus)

export default class OnePortalAccessRoot extends React.Component {

    state = {
        visible: false,
        stage: 0,
    }

    componentDidMount = () => {
        // return this.nextStage(null,2); // For testing only!
        setTimeout(() => this.setState({
            stage: 1,
            visible: true
        }), 1);
        setTimeout(() => this.setState({
            visible: false
        }), 2000);
        setTimeout(() => this.setState({
            stage: 2,
            visible: true
        }), 2500);
    }

    nextStage = (e, stage) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({ visible: false });
        setTimeout(() => this.setState({
            stage: stage ? stage : this.state.stage+1,
            visible: true
        }),1000);
    }

    render = () => {
        return (<div
            className="modal-container"
            // style={{ animation: "fadein 0.3s ease-in-out 0s 1" }}
        >
            <div className={"modal-content" + (this.state.visible ? "" : "modal-content-hidden")}>
                
                {/* Multus logo */}
                {this.state.stage === 1 ? <img
                    className="multus-logo"
                    src="/thumb/Multus.png"
                    alt="Multus Medical"
                /> :
                
                // Study types
                this.state.stage === 2 ? <div className="case-study-types">
                    {this.props.studyTypes.map((studyType,idx) => <div
                        className="case-study-type-thumb"
                        key={idx}
                        onClick={async () => {
                            
                            // If Comparison or Animation: Enter File Viewer
                            if (studyType === 'Comparison' || studyType === 'Surgical Animation')
                                this.props.unsetOverlay();
                                
                            this.props.history.push(this.props.buildURLPathToFolder(studyType))
                        }}
                    >
                        <img
                            src={"/thumb/" + studyType + ".png"}
                            alt={studyType}
                        />
                        <h1>{studyType}</h1>
                    </div>)}

                    <div style={{ clear: 'both', height: 150 }} />

                    <div className="case-menu-options">
                        <div
                            className="case-menu-link"
                            onClick={this.props.unsetOverlay}
                        >Navigate Case Files</div>

                        {this.props.zipExists && <div
                            className="case-menu-link"
                            onClick={this.props.onDownloadZip}
                        >Download Case Folder</div>}
                    </div>
                </div> :
                
                ""}

            </div>
        </div>);
    }

};