// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Loading from 'react-loading';

import ProjectsPortal from '../../ProjectsPortal';

import { studyTypes, statusWorkflow, statusWorkflowAnimation} from '../../../constants';

import '../../../styles/AnimationProject.css';

import api from '../../../api';

// Radiologist DICOM Viewer URL
const dicomUrl = window.location.href.includes('localhost') ? 'http://localhost:8080/report/' : window.location.href.includes('staging') ? 'https://rad-staging.multusmedical.com/report/' : 'https://rad.multusmedical.com/report/';

class AnimationProject extends Component {

    state = {
        loading: false
    }

    // Get ops users
    getTechOpsUsers = () => {
        let techOpsUsers = [];

        for (let userId in this.props.users) {
            if ((["ops", "tech", "lead"]).includes(this.props.users[userId].type))
                techOpsUsers.push(userId);
        }

        return techOpsUsers;
    }

    // Format date to how we want to display
     formatDate = (date) => {
        if (!date || date === "") return "N/A";
        let stringDate = date;
        if (typeof stringDate !== "string") stringDate = date.toISOString();
        date = new Date(Date.parse(stringDate.split('T')[0] + "T00:00"));
        return (date.getMonth()+1).toString().padStart(2, '0') + "/" + date.getDate().toString().padStart(2, '0') + "/" + date.getFullYear().toString();
    }

    // Format time to how we want to display
    formatTime = (date) => {
        return new Date(Date.parse(date)).toLocaleTimeString("en-US", {hour: "2-digit",minute: "2-digit",});
    }

    // Is this date today?
     isToday = (dateString) => {
        const today = new Date();
        let date = new Date(Date.parse(dateString));
        return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    }

    // render field div
    getFieldDiv = (field) => {

        if (!this.props.users || !this.props.clients)
            return;

        switch (field) {

            case 'animation.assignee':

                return (
                    <div>
                        <select className="animationSelect" defaultValue={this.props.project.animation.assignee ? this.props.project.animation.assignee : ''}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                                e.stopPropagation();
                                this.props.onSelect(this.props.project.animation._id, field, e.target.value);
                            }}
                            style={{float: 'right'}}
                        >
                            <option value=""></option>
                            {this.getTechOpsUsers().map(userId => {
                                return (
                                    <option key={'option-' + userId} value={userId}>
                                        {this.props.users[userId].name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                );

            case 'client':
                return (
                    <span>
                        {
                        this.props.clients[this.props.project[field]]?
                        this.props.clients[this.props.project[field]].name:
                        this.props.project[field]
                        }
                    </span>
                );

            case 'animation.lastAccess':
                return (
                    <span>
                        {
                        this.isToday(this.props.project.animation.lastAccess) ? this.formatTime(this.props.project.animation.lastAccess) : this.formatDate(this.props.project.animation.lastAccess)
                        }
                    </span>
                );
    
            case 'animation.lop':

                return (
                    <select className="animationSelect" defaultValue={this.props.project.animation?.lop} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                        e.stopPropagation();
                        this.props.onSelect(this.props.project.animation._id, field, e.target.value);
                    }}>
                        <option key={'option-not-sent'} value={'Not Sent'}>Not Sent</option>
                        <option key={'option-sent'} value={'Sent'}>Sent</option>
                        <option key={'option-received'} value={'Received'}>Received</option>

                    </select>
                );

            case 'animation.status':

                return (
                    <select className="animationSelect" defaultValue={this.props.project.animation?.status} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                        e.stopPropagation();
                        this.props.onSelect(this.props.project.animation._id, field, e.target.value);
                    }}>
                        {statusWorkflowAnimation.map(status => {
                            return <option key={'option-' + status} value={status}>{status}</option>;
                        })}
                    </select>
                );

            case 'animation.procedure':

                return (
                    <input className="animationSelect" defaultValue={this.props.project.animation?.procedure} onClick={(e) => e.stopPropagation()} 
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            //e.preventDefault();
                            this.props.onSelect(this.props.project.animation._id, field, e.target.value);
                            e.target.blur();
                        }
                        }}
                    >
                    </input>
                );
            
            case 'animation.notes':

                return (
                    <input className="animationSelect" defaultValue={this.props.project.animation?.notes} onClick={(e) => e.stopPropagation()} 
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            //e.preventDefault();
                            this.props.onSelect(this.props.project.animation._id, field, e.target.value);
                            e.target.blur();
                        }
                        }}
                    >
                    </input>
                );

            case 'animation.dueDate':

                let d = new Date(this.props.project.animation?.dueDate);
                var day = ("0" + (d.getDate()+1) ).slice(-2);
                var month = ("0" + (d.getMonth()+1)).slice(-2);
                var due = d.getFullYear()+"-"+(month)+"-"+(day) ;
            
                return (
                    <input type="date" className="animationSelect" 
                    defaultValue={due} 
                    onClick={(e) => e.stopPropagation()} 
                    onChange={(e) => {
                        e.stopPropagation();
                        console.log('e.target.value',e.target.value)
                        this.props.onSelect(this.props.project.animation._id, field, e.target.value);
                    }}/>
                );

            case 'animation.hardDeadline':
                
                return (
                    <input type="checkbox" className="animationSelect" 
                    defaultChecked={this.props.project.animation?.hardDeadline} 
                    onClick={(e) => e.stopPropagation()} onChange={(e) => {
                        e.stopPropagation();
                        this.props.onSelect(this.props.project.animation._id, field, e.target.checked);
                    }}/>
                );
    

                
            case 'studyType':
                return (
                    <span>{this.props.project[field]}</span>
                    // <select className="animationSelect" value={this.props.project[field]} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                    //     e.stopPropagation();
                    //     this.props.onSelect(this.props.project._id, field, e.target.value);
                    // }}>

                    //     {/* If not found! */}
                    //     {!studyTypes.includes(this.props.project[field]) ? <option value={this.props.project[field]}>{this.props.project[field]}</option> : ''}

                    //     {/* Standard types */}
                    //     {studyTypes.map(studyType => {
                    //         return <option key={'option-' + studyType} value={studyType}>{studyType}</option>;
                    //     })}

                    // </select>
                );

            default:
                return ProjectsPortal.getDisplayValueFromKey(this.props.project, field, true);

        }

    }


    getEndUserOptions = (centerName, endUserType) => {

        let endUsers = Object.keys(this.props.users).map((key, idx) => {
            let curUser = this.props.users[key];
            if (curUser.type === "endUser" && curUser.data.type === endUserType && curUser.data.group === centerName)
                return curUser;
            else
                return null;
        });

        endUsers = endUsers.filter((elem) => { return elem !== null });
        return endUsers;

    }

    // Get packaged data for a given end user
    getEndUserData = (endUserTag) => {

        let endUser = this.props.project[endUserTag];

        // Note: The code below should not be needed, as physician updates refresh the fields
        // if (endUser && endUser.id)
        // {
        //     let foundEndUser = Object.values(this.props.users).find(elem => elem._id === endUser.id);
        //     if (foundEndUser) 
        //         return {'name': foundEndUser.name, 'contact': foundEndUser.email}
        // }

        let name = endUser && endUser.name ? endUser.name : '';
        let contact = endUser && endUser.contact ? endUser.contact : ''
        return { 'name': name, 'contact': contact };

    }



    render() {

        const project = this.props.project;
        const jobsExist = (project.ai && (project.ai.injury || project.ai.radiologyRWR || project.ai.radiologyMRI || project.ai.segment || project.ai.renderer));
        const physicianOptions = this.getEndUserOptions(project.center.group, 'physician');
        const attorneyOptions = this.getEndUserOptions(project.center.group, 'attorney');

        const physicianData = this.getEndUserData('attendingPhysician');
        const attorneyData = this.getEndUserData('attorney');
        
        return (
            <div className={"animationProjectRow" + (this.props.selected ? " animationProjectRowSelected" : "") + (this.props.openEditor && !this.props.openEditor.saving ? " animationProjectRowOpen" : '')} ref={'project-' + project._id} style={ProjectsPortal.getAnimationProjectStyleModifier(project.animation?.status, (this.props.openedJob === '' || this.props.openedJob === project._id) ? 1 : 0.3)} 
           // onClick={() => this.props.onToggleSelected(project)}
            >

                <div className="animationProjectRowItemContainer" style={{
                    width: 'calc(100% - ' + this.props.iconOffset + ')'
                }}>
                    {Object.keys(this.props.columns).map((key, idx) => <div key={'project-' + project._id + '_' + key} className="animationProjectRowItem" style={{
                        width: "calc(" + this.props.columns[key].width + "% - 10px)",
                        maxWidth: this.props.columns[key].maxWidth - 10 + "px"
                    }}>{this.getFieldDiv(key)}</div>)}
                </div>

                {/* Case Folder */}

                <img src={project.filesCount ? "/resources/icons/folder.png" : "/resources/icons/emptyFolder.png"} alt="Files" ref={'files-' + project._id} onClick={(e) => {
                    e.stopPropagation();
                    window.open('/files/' + project.case, '_blank');
                }} title="View Case Files" />


                {/* Google Drive Button */}
                
                {this.props.openEditor && this.props.openEditor.editor === 'googleDrive' && !this.props.openEditor.saving ?
                    <img src="/resources/icons/confirm.png" alt="Contacts" ref={'googleDrive-' + project._id} 
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.onGoogleDriveConfirm(project._id, project.animation._id );
                    }} />
                    // Saving? 
                    : this.props.openEditor && this.props.openEditor.editor === 'googleDrive' && this.props.openEditor.saving ?
                        <div ref="loadingIconContacts" style={{float: 'right',marginTop: '1px',marginLeft: '10px'}}>
                            <Loading type="spin" color="rgb(80,80,80)" height={'28px'} width={'28px'} />
                        </div>
                        :
                        <img src="/resources/icons/googleDrive.svg" alt="Google Drive" ref={'googleDrive-' + project._id} 
                        style={{
                          opacity:  project.animation.googleDrive?1:0.5
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if(!project.animation.googleDrive) return;
                            window.open(project.animation.googleDrive, "_blank", "noreferrer");
                        }} 
                        onContextMenu={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.props.openProjectGoogleDrive(project._id, project.animation._id);
                        }} />
                }


                {/* <img src="/resources/icons/dicom.png" alt="DICOM" title="View Project DICOM Files" onClick={(e) => {
                    e.stopPropagation();
                    window.open(dicomUrl + project._id)
                }} /> */}
                
                {/* {project.status !== 'Approved' && project.notes && project.notes.length > 0 ?
                    <img src="/resources/icons/notes.png" alt="Notes" title={this.getNotesForProject(project)} onClick={(e) => e.stopPropagation()} ref={'notes-' + project._id} /> :

                    (project.status !== 'Approved' ?
                        <img src="/resources/icons/notesGrayed.png" onClick={(e) => e.stopPropagation()} alt="Notes" title="This case has no notes" className="grayedOut" ref={'notes-' + project._id} /> : '')}

                {project.status === 'Approved' && !this.props.isCaseApproved(project.case) ? <img src="/resources/icons/alert.png" alt="Error" title="Cannot deliver. Other project(s) belonging to this case have not been approved!" /> : ''} */}

                {/* {!this.state.loading && project.status === 'Approved' && this.props.isCaseApproved(project.case) ? <img src="/resources/icons/email.png" title="Deliver case" alt="Deliver case" onClick={(e) => {
                    e.stopPropagation();
                    this.deliverCase();
                }} /> : ''} */}

                {/* {this.state.loading ? <div ref="loadingIcon" style={{
                    float: 'right',
                    marginTop: '1px',
                    marginLeft: '10px'
                }}>
                    <Loading type="spin" color="rgb(80,80,80)" height={'28px'} width={'28px'} />
                </div> : ''} */}

                {/* <img src={jobsExist ? "/resources/icons/jobs.png" : "/resources/icons/jobsGrayed.png"} className={jobsExist ? "" : "grayedOut"} alt="Jobs" onClick={(e) => {
                    e.stopPropagation();
                    if (jobsExist)
                        this.props.manageJobs();
                }} title={jobsExist ? "Manage Project Jobs" : "This study has no jobs"} /> */}


                {/* Editor Div */}
                {
                this.props.openEditor && this.props.openEditor.editor === 'googleDrive' ?
                    // Contact Editor
                    <div className="contactInfo" onClick={(e) => e.stopPropagation()}>

                        <div className="contactInfoRow">
                            Google Drive Link:
                            <input type="text" name="googleDriveLink" className="contactInfoInput" placeholder="Google Drive Link" 
                            defaultValue={this.props.project.animation.googleDrive} 
                            // todo, change this to a onGoogleDriveValueChange
                           onChange={(e) => this.props.onGoogleDriveValueChange(project._id, project.animation._id, e.target.value)} 
                            />
                        </div>

                    </div>
                    : ''
                    // this.props.openEditor && this.props.openEditor.editor === 'cost' ?
                    //     // Cost Editor
                    //     <div className="contactInfo" onClick={(e) => e.stopPropagation()}>
                    //         <div className="contactInfoRow">
                    //             Cost:
                    //             <input type="text" name="caseCost" className="contactInfoInput" placeholder={this.props.clients[project.client] ? this.props.clients[project.client].cost : ''} value={project.cost ? project.cost : ''} onChange={(e) => this.props.onCostValueChange(project._id, e.target.value)}
                    //                 onKeyUp={async (e) => {
                    //                     if (e.keyCode === 13) {    // On enter
                    //                         this.props.onCostConfirm(project._id);
                    //                     }
                    //                 }} />
                    //         </div>

                    //     </div>
                    //     : ''
                }

            </div>
        );
    }

}

export default withRouter(AnimationProject);