// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import Loading from 'react-loading';

import ProjectsPortal from '../../ProjectsPortal';

import { statusWorkflow } from '../../../constants';

import '../../../styles/LeadProject.css';
import '../../../styles/OpsProject.css';
import '../../../styles/ReviewProject.css';

// import api from '../../../api';
// import path from 'path';

class LeadProject extends Component {

    projectHasNotesOrMemos = () => {

        return ((this.props.project.notes && this.props.project.notes.length > 0) || (this.props.project.memos && this.props.project.memos.length > 0));

    }

    toggleProjectNotesAndMemos = () => {

        let project = this.props.project;

        let projectDiv = this.refs['project-' + project._id];
        let notesImg = this.refs['notes-' + project._id];

        if (projectDiv === undefined || notesImg === undefined)
            return;

        if (projectDiv.style.height === '' || projectDiv.style.height === '32px') // Open
            projectDiv.style.height = (48 + ((project.notes ? project.notes.length : 0) + (project.memos ? project.memos.length : 0)) * 32) + 'px';

        else // Open
            projectDiv.style.height = '32px';

    }

    // Get list of radiologists
    getRadiologists = () => {
        let radiologists = [];

        for (let userId in this.props.users) {
            if (this.props.users[userId].type === "radiologist")
                radiologists.push(userId);
        }

        return radiologists;
    }

    // Get ops users
    getTechOpsUsers = () => {
        let techOpsUsers = [];

        for (let userId in this.props.users) {
            if ((["ops", "tech", "lead"]).includes(this.props.users[userId].type))
                techOpsUsers.push(userId);
        }

        return techOpsUsers;
    }

    // Case notes
    getNotesForProject = (project) => {
        
        let notes = "";

        for (let note of project.notes) {

            // Get file path
            let cmp = note.filePath.split("/");
            cmp.splice(0,1);
            
            notes += "(" + cmp.join("/") + ") " + note.note + "\n";
        }

        return notes;

    }

    // Link to file
    getFileLink = (file) => {

        if (!file)
            return;

        let cmp = file.split("/");
        cmp.splice(0,1); // Remove projectId from path

        let filePath = cmp.pop();

        let dirPath = cmp.join("::");

        return (
            <div onClick={() => window.open('/files/' + this.props.project.case + '/' + (dirPath === '' ? 'root' : dirPath) + '/' + filePath)} className="targetFile">{(cmp.length > 0 ? cmp.join('/') + '/' : '') + filePath}</div>
        );

    }

    // render field div
    getFieldDiv = (field) => {

        if (!this.props.users)
            return;

        switch (field) {

            case 'radiologist':

                return (
                    <select className="opsSelect" value={this.props.project[field]} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                        e.stopPropagation();
                        this.props.onSelect(this.props.project._id, field, e.target.value);
                    }}>
                        <option value=""></option>
                        {this.getRadiologists().map(userId => {
                            return (
                                <option key={'option-'+userId} value={userId}>{this.props.users[userId].name}</option>
                            );
                        })}
                    </select>
                );
            
            case 'assignee':

                return (
                    <select className="opsSelect" value={this.props.project[field]} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                        e.stopPropagation();
                        this.props.onSelect(this.props.project._id, field, e.target.value);
                    }}>
                        <option value=""></option>
                        {this.getTechOpsUsers().map(userId => {
                            return (
                                <option key={'option-'+userId} value={userId}>{this.props.users[userId].name}</option>
                            );
                        })}
                    </select>
                );

            case 'status':
                return (
                    <select className="opsSelect" value={this.props.project[field]} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                        e.stopPropagation();
                        this.props.onSelect(this.props.project._id, field, e.target.value);
                    }}>
                        {statusWorkflow.map(status => {
                            return <option key={'option-'+status} value={status}>{status}</option>;
                        })}
                    </select>
                ); 

            default:
                return ProjectsPortal.getDisplayValueFromKey(this.props.project, field, true);

        }            

    }

    render() {

        const project = this.props.project;
        const jobsExist = (project.ai && (project.ai.injury || project.ai.radiologyRWR || project.ai.radiologyMRI || project.ai.segment || project.ai.renderer));

        return (
            <div className="leadProjectRow" ref={'project-' + project._id} style={ProjectsPortal.getProjectStyleModifier(project.status, (this.props.openedJob === '' || this.props.openedJob === project._id) ? 1 : 0.3)}>

                <div className="leadProjectRowItemContainer">
                    {Object.keys(this.props.columns).map((key, idx) => <div key={'project-' + project._id + '_' + key} className="leadProjectRowItem" style={{
                        width: "calc(" + this.props.columns[key].width + "% - 10px)",
                        maxWidth: this.props.columns[key].maxWidth-10 + "px"
                    }}>{this.getFieldDiv(key)}</div>)}
                </div>

                {this.projectHasNotesOrMemos() ? 
                <img src="/resources/icons/notes.png" alt="Notes" title="Case Notes" ref={'notes-' + project._id} onClick={() => this.toggleProjectNotesAndMemos()} /> : 
                <img src="/resources/icons/notesGrayed.png" alt="Notes" title="This case has no notes" className="grayedOut" ref={'notes-' + project._id} />}

                <img src={project.filesCount ? "/resources/icons/folder.png" : "/resources/icons/emptyFolder.png"} alt="Files" ref={'files-' + project._id} onClick={(e) => {
                    e.stopPropagation();
                    // this.props.history.push('/files/' + project.case);
                    window.open('/files/' + project.case, '_blank');
                }} title="View Case Files" />

                <img src={jobsExist ? "/resources/icons/jobs.png" : "/resources/icons/jobsGrayed.png"} className={jobsExist ? "" : "grayedOut"} alt="Jobs" onClick={(e) => {
                    e.stopPropagation();
                    if (jobsExist)
                        this.props.manageJobs();
                }} title={jobsExist ? "Manage Project Jobs" : "This study has no jobs"} />

                <div className="notesMemosContainer">
                    {/* Notes */}
                    {project.notes ? project.notes.map((note, idx) => (
                        <div key={idx} className="projectNote">
                            {this.getFileLink(note.filePath)}
                            <img alt="Note" title={note.note} src="/resources/icons/writtenNote.png" />
                        </div>
                    )) : ''}
                </div>

            </div>
        );
    }

}

export default withRouter(LeadProject);