

export default (date) => {
        
    if (!date || date === "")
        return "N/A";

    let stringDate = date;

    if (typeof stringDate !== "string")
        stringDate = date.toISOString()

    date = new Date(Date.parse(stringDate.split('T')[0] + "T00:00"));

    return (date.getMonth()+1).toString().padStart(2, '0') + "/" + date.getDate().toString().padStart(2, '0') + "/" + date.getFullYear().toString();
}