// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import axios from 'axios';

axios.defaults.withCredentials = true;

// Set API URL based on environment
const apiUrl = window.location.href.includes('localhost') ? 'http://localhost:8080/' : window.location.href.includes('staging') ? 'https://api-staging.multusmedical.com/' : 'https://api.multusmedical.com/';

export default {

    apiUrl, // Return active API URL

    // Get request
    get: async (endpoint, data={}, noAuth=false, config={}) => {

        let res = await axios.get(apiUrl + "api/" + endpoint, data, config);

        // Authorization error - redirect to login page
        if (!res.data.auth && !noAuth)
            return window.location = '/login';
        
        if (res.data.error)
            console.log(res.data.error);

        return res.data;

    },

    // Post request
    post: async (endpoint, data={}, noAuth=false, config={}) => {

        let res = await axios.post(apiUrl + "api/" + endpoint, data, config);
        
        // Authorization error - redirect to login page
        if (!res.data.auth && !noAuth)
            return window.location = '/login';
        
        if (res.data.error)
            console.log(res.data.error);

        return res.data;

    },

    // Download
    download: async (endpoint, data) => {

        let res = await axios.post(apiUrl + "api/" + endpoint, data, { responseType: 'blob' });

        if (res.data.error)
            console.log(res.data.error);

        return res.data;

    },

    // Login
    login: async (username, password) => {

        let res = await axios.post(apiUrl + "auth/local", {
            username: username,
            password: password
        });

        if (res.data.error)
            console.log(res.data.error);

        return res.data;

    },

    // Logout
    logout: async () => {

        let res = await axios.get(apiUrl + "auth/logout");

        if (res.data.error)
            console.log(res.data.error);

        return res.data;

    }

}