// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import ProjectsPortal from '../../ProjectsPortal';
import OpsProject from './OpsProject';
import JobsManager from '../../JobManager';

import api from '../../../api';
import { toast } from 'react-toastify';

import { studyTypes } from '../../../constants';

export default class OpsPortal extends ProjectsPortal {

    constructor(props) {

        super(props);

        this.columns = {
            "case": { label: "Case", width: 8, maxWidth: 60 },
            "patientName": { label: "Patient Name", width: 12, maxWidth: 320 },
            "patientSex": { label: "Sex", width: 4, maxWidth: 100 },
            "patientDateOfBirth": { label: "DOB", width: 6, maxWidth: 100 },
            "centerName": { label: "MRI Center", width: 11, maxWidth: 320 },
            "studyDate": { label: "Study Date", width: 6, maxWidth: 100 },
            "receiveDate": { label: "Received", width: 6, maxWidth: 100 },
            "lastAccess": { label: "Modified", width: 6, maxWidth: 100 },
            "studyType": { label: "Study Type", width: 6, maxWidth: 140 },
            "assignee": { label: "Assignee", width: 9, maxWidth: 160 },
            "radiologist": { label: "Radiologist", width: 9, maxWidth: 160 },
            "client": { label: "Client", width: 9, maxWidth: 160 },
            "status": { label: "Status", width: 9, maxWidth: 160 }
        };

        this.iconOffset = '225px';

        this.state.selected = [];

        this.state.jobManager = {
            projectId: '',
            opened: false
        };

        this.state.confirmCaseGroup = false;
        this.state.newCase = false;

        // new case data
        this.state.newCase_patient_name = '';
        this.state.newCase_patient_sex = 'M';
        this.state.newCase_patient_dateOfBirth = '';
        this.state.newCase_studyDate = '';
        this.state.newCase_studyType = 'Brain';


    }

    componentDidMount = async () => {

        // Fetch projects
        let res = await this.fetchProjects();

        if (!res || !res.auth)
            return;

        if (!res.auth._id)
            window.location = "/login";

        else if (res.auth.type === 'lead')
            window.location = "/lead";

        else if (res.auth.type === 'center')
            window.location = "/center";

        else if (res.auth.type === 'endUser')
            window.location = '/centerenduser';

        else if (res.auth.type === 'client' || res.auth.type === 'radiologist' || res.auth.type === 'tech')
            window.location = "/review";

        // Get clients and users
        res = await api.post('getClients');

        if (!res || !res.data)
            return;

        let clients = {};

        for (let client of res.data)
            clients[client._id] = { ...client };

        res = await api.post('getUsers');

        if (!res || !res.data)
            return;

        let users = {};

        for (let user of res.data)
            users[user._id] = { ...user };


        await this.setState({
            clients: clients,
            users: users,
        });
    }

    // Field edit functions
    selectHandler = async (projectId, field, value) => {

        let res = await api.post('syncProject', {
            pId: projectId,
            projectDiff: {
                [field]: value
            }
        });

        if (!res || !res.success || !res.data)
            return;

        // Update
        this.updateProjects([res.data]);

    }

    // Update contact info
    contactValueChangeHandler = async (projectId, field, value) => {

        let project = this.state.projects[this.getProjectIdx(projectId)];

        switch (field) {

            case 'physicianName':
                if (!project.attendingPhysician)
                    project.attendingPhysician = { name: '', contact: '' };
                project.attendingPhysician.name = value;
                break;

            case 'physicianContact':
                if (!project.attendingPhysician)
                    project.attendingPhysician = { name: '', contact: '' };
                project.attendingPhysician.contact = value;
                break;

            case 'attorneyName':
                if (!project.attorney)
                    project.attorney = { name: '', contact: '' };
                project.attorney.name = value;
                break;

            case 'attorneyContact':
                if (!project.attorney)
                    project.attorney = { name: '', contact: '' };
                project.attorney.contact = value;
                break;

            default:

        }

        this.updateProjects([project]);

    }

    costValueChangeHandler = async (projectId, value) => {

        // Only allow numeric values
        if (isNaN(value))
            return;

        let project = this.state.projects[this.getProjectIdx(projectId)];

        if (!project.cost)
            project.cost = '';
        project.cost = value;

        this.updateProjects([project]);

    }

    // Selected?
    selectionUpdateHandler = (project) => {

        let newSelected = [...this.state.selected];
        let selectedIdx = newSelected.indexOf(project._id);

        if (selectedIdx >= 0) // Exists - remove
            newSelected.splice(selectedIdx, 1);

        else // Doesn't exist - add
            newSelected.push(project._id);

        this.setState({
            selected: newSelected
        });

    }

    // Confirm/push
    contactConfirmHandler = async (projectId) => {

        let project = this.state.projects[this.getProjectIdx(projectId)];

        // Set the editor to loading 
        let openedEditors = this.state.openedEditors;
        if (openedEditors[project._id])
            openedEditors[project._id].saving = true;
        await this.setState({
            openedEditors: openedEditors
        });

        let res = await api.post('syncProject', {
            pId: projectId,
            projectDiff: {
                attendingPhysician: project.attendingPhysician,
                attorney: project.attorney
            }
        });

        // Remove pId from opened list
        openedEditors = this.state.openedEditors;
        delete openedEditors[projectId];
        this.setState({
            openedEditors: openedEditors
        })

        // Handle error
        if (!res || !res.success || !res.data) {
            toast.error('Error saving contact info.');
            return;
        }

        // Update
        this.updateProjects([res.data]);

        toast.success("Contact info saved!")
    }

    costConfirmHandler = async (projectId) => {

        let project = this.state.projects[this.getProjectIdx(projectId)];

        // Set the editor to loading 
        let openedEditors = this.state.openedEditors;
        if (openedEditors[project._id])
            openedEditors[project._id].saving = true;
        await this.setState({
            openedEditors: openedEditors
        });

        // Reset cost if we delete the entire string
        if (String(project.cost).trim().length === 0 || project.cost === null)
            project.cost = '';

        let res = await api.post('setCaseCost', {
            pId: projectId,
            cost: project.cost
        });

        // Remove pId from opened list
        openedEditors = this.state.openedEditors;
        delete openedEditors[projectId];
        this.setState({
            openedEditors: openedEditors
        })

        // Handle error
        if (!res || !res.success || !res.data) {
            toast.error('Error saving cost.');
            return;
        }

        // Update
        this.updateProjects([res.data]);

    }

    areSelectedProjectsGrouped = () => {

        if (this.state.selected.length < 2)
            return;

        let caseNumber = this.getProjectById(this.state.selected[0]).case;

        for (let idx = 1; idx < this.state.selected.length; idx++)
            if (this.getProjectById(this.state.selected[idx]).case !== caseNumber)
                return false;

        return true;

    }

    // Check if all projects in case are approved
    isCaseApproved = (caseNumber) => {

        for (let project of this.state.projects)
            if (project.case === caseNumber && project.status !== 'Approved' && project.status !== 'Delivered' && project.status !== 'Removed')
                return false;

        return true;

    }

    // Case grouping operations
    groupSelectedProjectsIntoACase = async () => {

        let selectedProjects = [];
        let smallestCaseNumber = 999999;

        // Make sure there are enough cases to create a group
        if (this.state.selected.length < 2) {
            toast.error("Not enough cases selected to group!")
            this.setState({
                confirmCaseGroup: false
            })
            return;
        }

        for (let projectId of this.state.selected) {
            let project = this.getProjectById(projectId);

            if (project) {
                if (project.case < smallestCaseNumber)
                    smallestCaseNumber = project.case;

                selectedProjects.push(project);
            }
        }

        // Sync projects
        let updatedProjects = [];

        for (let project of selectedProjects) {
            let res = await api.post('syncProject', {
                pId: project._id,
                projectDiff: {
                    case: smallestCaseNumber
                }
            })

            if (res && res.data)
                updatedProjects.push({ ...res.data });
        }

        // Update local state
        this.updateProjects(updatedProjects);

        // Clear selection
        this.setState({
            selected: [],
            confirmCaseGroup: false
        });

    }

    createNewCaseForSelectedProject = async () => {

        let res = await api.post('createNewCaseForProject', {
            pId: this.state.selected[0]
        });

        if (!res || !res.data)
            return;

        // We received the updated project back
        this.updateProjects([res.data]);

        // Clear selection
        this.setState({
            selected: []
        });

    }

    // create new project (using bottom corner button, for animation deliveries)
    createNewProject = async () => {

        let res = await api.post('createNewProject', {
            patient: {
                name: this.state.newCase_patient_name.toUpperCase(),
                sex: this.state.newCase_patient_sex,
                dateOfBirth: this.state.newCase_patient_dateOfBirth,
            },
            studyDate: this.state.newCase_studyDate,
            studyType: this.state.newCase_studyType
        });

        if (!res || !res.data)
            return;

        // We received the updated project back
        this.updateProjects([res.data]);

        // Clear selection
        this.setState({
            selected: [],
            newCase_patient_name: '',
            newCase_patient_sex: 'M',
            newCase_patient_dateOfBirth: '',
            newCase_studyDate: '',
            newCase_studyType: 'Brain',
            newCase: false
        });

    }

    selectedCaseInfo = () => {
        let selectedProjects = [];
        let smallestCaseNumber = 999999;
        let projectInfo = [];

        for (let projectId of this.state.selected) {
            let project = this.getProjectById(projectId);

            if (project) {
                if (project.case < smallestCaseNumber)
                    smallestCaseNumber = project.case;

                selectedProjects.push(project);
            }
        }

        for (let project of selectedProjects)
            projectInfo.push(
                <div key={project.case + project.studyType} className="caseInfoRow">{project.case} {project.patient.name} - {project.studyType}
                    <img src='/resources/icons/reject.png' onClick={() => this.selectionUpdateHandler(project)} />
                </div>)


        return projectInfo;
    }

    renderProjects = () => {

        const jobProject = this.getProjectById(this.state.jobManager.projectId);

        return (
            <div>
                {/* Sorted/ Filtered projects */}
                {this.state.displayProjects.map(idx => (
                    <OpsProject
                        key={this.state.projects[idx]._id}
                        project={this.state.projects[idx]}
                        uniqueIdentifier={idx}
                        users={this.state.users}
                        clients={this.state.clients}
                        columns={this.columns}
                        iconOffset={this.iconOffset}
                        onUpdateProjects={this.updateProjects}
                        onSelect={this.selectHandler}
                        onContactsConfirm={this.contactConfirmHandler}
                        onContactsValueChange={this.contactValueChangeHandler}
                        onCostConfirm={this.costConfirmHandler}
                        onCostValueChange={this.costValueChangeHandler}
                        selected={this.state.selected.includes(this.state.projects[idx]._id)}
                        onToggleSelected={this.selectionUpdateHandler} isCaseApproved={this.isCaseApproved}
                        onUpdateEditing={(editing) => this.setState({ editing })}
                        openEditor={this.state.openedEditors[this.state.projects[idx]._id] ? this.state.openedEditors[this.state.projects[idx]._id] : ''}
                        openProjectContacts={(id) => this.setState({ openedEditors: { ...this.state.openedEditors, [id]: { editor: 'contacts', saving: false } } })}
                        openCostEditor={(id) => this.setState({ openedEditors: { ...this.state.openedEditors, [id]: { editor: 'cost', saving: false } } })}
                        getProjectsByCase={this.getProjectsByCase}
                        manageJobs={() => this.setState({
                            jobManager: {
                                projectId: this.state.projects[idx]._id,
                                opened: true
                            }
                        })}
                        openedJob={this.state.jobManager.opened ? this.state.jobManager.projectId : ''}
                    />
                ))}

                {this.state.selected.length > 1 && !this.areSelectedProjectsGrouped() ? <div ref="caseGrouping" className="caseGrouping">
                    Group selected projects into a single case? <div className="caseGroupButton" onClick={() => this.setState({ confirmCaseGroup: true })}>Group</div>
                </div> : ''}

                {this.state.selected.length === 1 ? <div ref="caseGrouping" className="caseGrouping">
                    Create new case for project? <div className="caseGroupButton" onClick={() => this.createNewCaseForSelectedProject()}>New Case</div>
                </div> : ''}

                {this.state.confirmCaseGroup ? <div className="confirmGroup" onClick={() => this.setState({ confirmCaseGroup: false })}>
                    <div className="confirmGroupDialog" ref={'confirmGroupDialog'} onClick={(e) => e.stopPropagation()}>
                        <div className="confirmGroupDialogTitle"> Confirm Grouping</div>
                        <div>
                            {this.selectedCaseInfo()}
                        </div>
                        <div style={{ width: '100%' }}>
                            {this.state.selected.length > 1 ? <div className="groupDialogBtn" onClick={() => this.setState({ confirmCaseGroup: false })}>No</div> :
                                <div className="groupDialogBtn" style={{ width: '90%', color: 'red' }} onClick={() => this.setState({ confirmCaseGroup: false })}>Not enough projects selected! Click to close</div>}
                            {this.state.selected.length > 1 ? <div className="groupDialogBtn" style={{ marginLeft: 'calc(100% - 80px)' }} onClick={() => this.groupSelectedProjectsIntoACase()}>Yes</div> : ''}
                        </div>
                    </div>
                </div> : ''}

                {/* new case button */}
                <button className="newCaseButton" onClick={() => this.setState({ newCase: true })}>
                    +
                </button>

                {/* new case dialog */}
                {this.state.newCase ? <div className="newCase" onClick={() => this.setState({ newCase: false })}>
                    <div className="newCaseDialog" ref={'newCaseDialog'} onClick={(e) => e.stopPropagation()}>
                        <div className="newCaseDialogTitle"> New Case</div>
                        <div>
                            <label htmlFor="newCase_patient_name">Patient Name</label>
                            <br />
                            <input type="text" name="newCase_patient_name" placeholder="" onInput={(e) => { this.setState({ newCase_patient_name: e.target.value }) }} />
                            <br />
                            <label htmlFor="newCase_patient_sex">Sex</label>
                            <br />
                            <select name="newCase_patient_sex" onInput={(e) => { this.setState({ newCase_patient_sex: e.target.value }) }} >
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select>
                            <br />
                            <label htmlFor="newCase_patient_dateOfBirth">Date of Birth</label>
                            <br />
                            <input type="date" name="newCase_patient_dateOfBirth" min="1900-01-01" onInput={(e) => { this.setState({ newCase_patient_dateOfBirth: e.target.value }) }} />
                            <br />
                            <label htmlFor="newCase_studyType">Study Type</label>
                            <br />
                            <select name="newCase_studyType" onInput={(e) => { this.setState({ newCase_studyType: e.target.value }) }} >
                                {studyTypes.map(studyType => <option value={studyType} key={studyType} > {studyType} </option>)}
                            </select>
                            <br />
                            <label htmlFor="newCase_studyDate">Study Date</label>
                            <br />
                            <input type="date" name="newCase_studyDate" min="1900-01-01" onInput={(e) => { this.setState({ newCase_studyDate: e.target.value }) }} />
                        </div>
                        <button onClick={this.createNewProject}>Create Case</button>
                        <div style={{ width: '100%' }}>
                            <div className="groupDialogBtn" style={{ width: '90%', color: 'red' }} onClick={() => this.setState({ newCase: false })}>Click to close</div>
                        </div>
                    </div>
                </div> : ''}

                <JobsManager
                    opened={this.state.jobManager.opened}
                    searchEnabled={this.state.searchEnabled}
                    closeJobManager={() => this.setState({
                        jobManager: {
                            opened: false
                        }
                    })}
                    updateJob={this.updateJob}
                    pId={jobProject ? jobProject._id : null}
                    project={jobProject ? jobProject : null}
                    job={jobProject ? jobProject.ai : null}
                    killTask={this.killJob}
                    users={this.state.users}
                />
            </div>
        );

    }

}