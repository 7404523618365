// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { toast } from 'react-toastify';

import api from '../../../api';
import UsersConsole from './BaseUsersConsole';
import UserCard from './UserCard';


const userTypes = {
    "all": "Show all",
    "tech": "Technician",
    "lead": "Lead Tech",
    "ops": "Operations",
    "radiologist": "Radiologist",
    "center": "MRI Center",
    "client": "Client",
    "endUser": "End User"
};

const allowedUserTypes = ['ops'];

// Component to handle functionality for the Ops User Portal

export default class OpsUserConsole extends Component {

    state = {
        users: [],
        userChanged: {},
        filteredUsers: [],
        clients: []
    }


    componentDidMount = async () => {
        await this.fetchClients();
    }


    fetchClients = async () => {
        // Get clients
        let res = await api.post('getClients');

        if (!res || !res.data)
            return;
        
        this.setState({
            clients: res.data
        });
    }

    // Custom function for fetching Ops access users
    fetchUsers = async () => {

        // Get users
        let res = await api.post('getUsers');

        if (!res || !res.data)
            return;

        if (!res.auth._id)
            window.location = "/login";

        else if (!allowedUserTypes.includes(res.auth.type))
            window.location = "/";

        let users = res.data;

        for (let user of users)
            if (!user.data)
                user.data = {};

        await this.setState({
            users: res.data
        });

    }

    // Function to update the filtered users from the baseConsole
    setFilteredUsers = async (filteredUsers) => {

        this.setState({
            filteredUsers: filteredUsers
        });

    }

    // Function to update the state users from the baseConsole
    updateUsers = async (newUsers, updatedId) => {

        console.log("Using Generic function!")

        this.setState({
            users: newUsers,
            userChanged: {
                ...this.state.userChanged,
                [updatedId]: true
            }
        });

    }

    // Function to save user info to cloud
    saveUserInfo = async (userId) => {

        if (!this.state.userChanged[userId])
            return;

        let user = this.state.users.find(user => user._id === userId);

        let res = await api.post('syncUser', {
            userId: userId,
            userDiff: user
        });

        if (!res || !res.data)
            return;
        
        let users = [...this.state.users];

        for (let idx=0; idx<users.length; idx++) {
            if (users[idx]._id === userId) {
                users[idx] = res.data;
                break;
            }
        }

        this.setState({
            users: users,
            userChanged: {
                ...this.state.userChanged,
                [userId]: null
            }
        });

        toast.info('Saved user: ' + user.name);
        
    }

    createNewUser = async (newUser) => {

        await api.post('createUser', {
            name: newUser.name,
            email: newUser.email,
            password: newUser.password,
            type: newUser.type,
            data: {}
        });

        await this.fetchUsers();

    }

    // NOTES: 

    // The UserConsole is capable of taking care of the following:

        // Calling props.fetchUsers when mounted

        // Updating userInfo and calling "updateUsers"
        // Updating userData and calling "updateUsers"

        // Filtering users based on teh userTypes
        // Creating a new User, and passing them to the "createNewUser" prop


    render () {

        return(

            // Render the base user console
            <UsersConsole   users={this.state.users} clients={this.state.clients} userTypes={userTypes}
                            fetchUsers={this.fetchUsers} setFilteredUsers={this.setFilteredUsers}
                            createNewUser={this.createNewUser} updateUsers={this.updateUsers} >

                {/* All existing users as children */}
                {this.state.filteredUsers.map((userIdx, idx) => {

                    let user = this.state.users[userIdx];

                    if (user)
                        return (<UserCard
                            key={idx}
                            user={user}
                            viewMode={"ops"}
                            changed={this.state.userChanged[user._id]}
                            userTypes={userTypes}
                            saveUserInfo={this.saveUserInfo}
                            clients={this.state.clients}
                            toast={toast}
                        />);
                    return '';
                })}

            </UsersConsole>


        )
    }

}