// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Loading from 'react-loading';

import ProjectsPortal from '../../ProjectsPortal';

import { studyTypes, statusWorkflow } from '../../../constants';

import '../../../styles/OpsProject.css';

import api from '../../../api';

// Radiologist DICOM Viewer URL
const dicomUrl = window.location.href.includes('localhost') ? 'http://localhost:8080/report/' : window.location.href.includes('staging') ? 'https://rad-staging.multusmedical.com/report/' : 'https://rad.multusmedical.com/report/';

class OpsProject extends Component {

    state = {
        loading: false
    }

    // Deliver case
    deliverCase = async () => {

        await this.setState({
            loading: true
        });

        let res = await api.post('deliverCase', {
            caseNumber: this.props.project.case,
            pId: this.props.project._id,
        });

        if (res && res.success) {

            let caseProjects = this.props.getProjectsByCase(this.props.project.case);

            let modProjects = [];

            for (let project of caseProjects) {
                res = await api.post('getProject', {
                    pId: project._id,
                    projectDiff: {}
                });

                if (res && res.data)
                    modProjects.push(res.data);
            }

            // Update call to parent
            await this.props.onUpdateProjects(modProjects);

        }

        // Done
        await this.setState({
            loading: false
        });

    }

    // Get list of radiologists
    getRadiologists = () => {
        let radiologists = [];

        for (let userId in this.props.users) {
            if (this.props.users[userId].type === "radiologist")
                radiologists.push(userId);
        }

        return radiologists;
    }

    // Get ops users
    getTechOpsUsers = () => {
        let techOpsUsers = [];

        for (let userId in this.props.users) {
            if ((["ops", "tech", "lead"]).includes(this.props.users[userId].type))
                techOpsUsers.push(userId);
        }

        return techOpsUsers;
    }

    // Case notes
    getNotesForProject = (project) => {

        let notes = "";

        for (let note of project.notes) {

            // Get file path
            let cmp = note.filePath.split("/");
            cmp.splice(0, 1);

            notes += "(" + cmp.join("/") + ") " + note.note + "\n";
        }

        return notes;

    }

    // render field div
    getFieldDiv = (field) => {

        if (!this.props.users || !this.props.clients)
            return;

        switch (field) {

            case 'radiologist':
                return (
                    <select className="opsSelect" value={this.props.project[field]} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                        e.stopPropagation();
                        this.props.onSelect(this.props.project._id, field, e.target.value);
                    }}>
                        <option value=""></option>
                        {this.getRadiologists().map(userId => {
                            return (
                                <option key={'option-' + userId} value={userId}>{this.props.users[userId].name}</option>
                            );
                        })}
                    </select>
                );

            case 'assignee':

                const job = this.props.project.ai;

                let inProgress = false;

                for (let jobType of ['segment', 'injury', 'radiologyRWR', 'radiologyMRI', 'renderer'])
                    if (job && job[jobType] && ['Started', 'Working'].includes(job[jobType].status))
                        inProgress = true;

                return (
                    <div>
                        {inProgress ? <div className="aiProcessing" onClick={(e) => {
                            e.stopPropagation();
                            this.props.manageJobs();
                        }}>
                            <Loading type="spin" color="rgb(80,80,80)" height={'24px'} width={'24px'} />
                        </div> : ""}
                        <select className="opsSelect" value={this.props.project[field] ? this.props.project[field] : ''}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                                e.stopPropagation();
                                this.props.onSelect(this.props.project._id, field, e.target.value);
                            }}
                            style={{
                                width: inProgress ? 'calc(100% - 32px)' : '100%',
                                float: 'right'
                            }}
                        >
                            <option value=""></option>
                            {this.getTechOpsUsers().map(userId => {
                                return (
                                    <option key={'option-' + userId} value={userId}>
                                        {this.props.users[userId].name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                );

            case 'client':
                return (
                    <select className="opsSelect" value={this.props.project[field]} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                        e.stopPropagation();
                        this.props.onSelect(this.props.project._id, field, e.target.value);
                    }}>
                        <option value=""></option>
                        {Object.keys(this.props.clients).map(clientId => {
                            return (
                                <option key={'option-' + clientId} value={clientId}>{this.props.clients[clientId].name}</option>
                            );
                        })}
                    </select>
                );

            case 'status':
                return (
                    <select className="opsSelect" value={this.props.project[field]} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                        e.stopPropagation();
                        this.props.onSelect(this.props.project._id, field, e.target.value);
                    }}>
                        {statusWorkflow.map(status => {
                            return <option key={'option-' + status} value={status}>{status}</option>;
                        })}
                    </select>
                );

            case 'studyType':
                return (
                    <select className="opsSelect" value={this.props.project[field]} onClick={(e) => e.stopPropagation()} onChange={(e) => {
                        e.stopPropagation();
                        this.props.onSelect(this.props.project._id, field, e.target.value);
                    }}>

                        {/* If not found! */}
                        {!studyTypes.includes(this.props.project[field]) ? <option value={this.props.project[field]}>{this.props.project[field]}</option> : ''}

                        {/* Standard types */}
                        {studyTypes.map(studyType => {
                            return <option key={'option-' + studyType} value={studyType}>{studyType}</option>;
                        })}

                    </select>
                );

            default:
                return ProjectsPortal.getDisplayValueFromKey(this.props.project, field, true);

        }

    }


    getEndUserOptions = (centerName, endUserType) => {

        let endUsers = Object.keys(this.props.users).map((key, idx) => {
            let curUser = this.props.users[key];
            if (curUser.type === "endUser" && curUser.data.type === endUserType && curUser.data.group === centerName)
                return curUser;
            else
                return null;
        });

        endUsers = endUsers.filter((elem) => { return elem !== null });
        return endUsers;

    }

    // Get packaged data for a given end user
    getEndUserData = (endUserTag) => {

        let endUser = this.props.project[endUserTag];

        // Note: The code below should not be needed, as physician updates refresh the fields
        // if (endUser && endUser.id)
        // {
        //     let foundEndUser = Object.values(this.props.users).find(elem => elem._id === endUser.id);
        //     if (foundEndUser) 
        //         return {'name': foundEndUser.name, 'contact': foundEndUser.email}
        // }

        let name = endUser && endUser.name ? endUser.name : '';
        let contact = endUser && endUser.contact ? endUser.contact : ''
        return { 'name': name, 'contact': contact };

    }



    render() {

        const project = this.props.project;
        const jobsExist = (project.ai && (project.ai.injury || project.ai.radiologyRWR || project.ai.radiologyMRI || project.ai.segment || project.ai.renderer));
        const physicianOptions = this.getEndUserOptions(project.center.group, 'physician');
        const attorneyOptions = this.getEndUserOptions(project.center.group, 'attorney');

        const physicianData = this.getEndUserData('attendingPhysician');
        const attorneyData = this.getEndUserData('attorney');

        return (
            <div className={"opsProjectRow" + (this.props.selected ? " opsProjectRowSelected" : "") + (this.props.openEditor && !this.props.openEditor.saving ? " opsProjectRowOpen" : '')} ref={'project-' + project._id} style={ProjectsPortal.getProjectStyleModifier(project.status, (this.props.openedJob === '' || this.props.openedJob === project._id) ? 1 : 0.3)} onClick={() => this.props.onToggleSelected(project)}>

                <div className="opsProjectRowItemContainer" style={{
                    width: 'calc(100% - ' + this.props.iconOffset + ')'
                }}>
                    {Object.keys(this.props.columns).map((key, idx) => <div key={'project-' + project._id + '_' + key} className="opsProjectRowItem" style={{
                        width: "calc(" + this.props.columns[key].width + "% - 10px)",
                        maxWidth: this.props.columns[key].maxWidth - 10 + "px"
                    }}>{this.getFieldDiv(key)}</div>)}
                </div>

                {/* Cost Editor Button  */}
                {this.props.openEditor && this.props.openEditor.editor === 'cost' && !this.props.openEditor.saving ?
                    <img src="/resources/icons/confirm.png" alt="Cost" ref={'cost-' + project._id} onClick={(e) => {
                        e.stopPropagation();
                        this.props.onCostConfirm(project._id);
                    }} />
                    // Saving? 
                    : this.props.openEditor && this.props.openEditor.editor === 'cost' && this.props.openEditor.saving ?
                        <div ref="loadingIconContacts" style={{
                            float: 'right',
                            marginTop: '1px',
                            marginLeft: '10px'
                        }}>
                            <Loading type="spin" color="rgb(80,80,80)" height={'28px'} width={'28px'} />
                        </div>
                        // Cost Icon
                        :
                        <img src="/resources/icons/cost.png" alt="Cost" ref={'cost-' + project._id} onClick={(e) => {
                            e.stopPropagation();
                            this.props.openCostEditor(project._id);
                        }} />
                }

                {/* Contact Editor Button */}
                {this.props.openEditor && this.props.openEditor.editor === 'contacts' && !this.props.openEditor.saving ?
                    <img src="/resources/icons/confirm.png" alt="Contacts" ref={'contact-' + project._id} onClick={(e) => {
                        e.stopPropagation();
                        this.props.onContactsConfirm(project._id);
                    }} />
                    // Saving? 
                    : this.props.openEditor && this.props.openEditor.editor === 'contacts' && this.props.openEditor.saving ?
                        <div ref="loadingIconContacts" style={{
                            float: 'right',
                            marginTop: '1px',
                            marginLeft: '10px'
                        }}>
                            <Loading type="spin" color="rgb(80,80,80)" height={'28px'} width={'28px'} />
                        </div>
                        // Contact Icon
                        :
                        <img src="/resources/icons/contacts.png" alt="Contacts" ref={'contact-' + project._id} onClick={(e) => {
                            e.stopPropagation();
                            this.props.openProjectContacts(project._id);
                        }} />
                }

                <img src={project.filesCount ? "/resources/icons/folder.png" : "/resources/icons/emptyFolder.png"} alt="Files" ref={'files-' + project._id} onClick={(e) => {
                    e.stopPropagation();
                    // this.props.history.push('/files/' + project.case);
                    window.open('/files/' + project.case, '_blank');
                }} title="View Case Files" />

                <img src="/resources/icons/dicom.png" alt="DICOM" title="View Project DICOM Files" onClick={(e) => {
                    e.stopPropagation();
                    window.open(dicomUrl + project._id)
                }} />
                {/* <img src="/resources/icons/fileTypes/pdf.png" 
                onClick={(e) => {
                    e.stopPropagation();
                    if(project.status == 'Awaiting Docs') return;
                    
                    const getFormattedDate = (date) => {
                        let year = date.getFullYear();
                        let month = (1 + date.getMonth()).toString().padStart(2, "0");
                        let day = date.getDate().toString().padStart(2, "0");
                        return month + "-" + day + "-" + year;
                    };
                    let t = getFormattedDate(new Date(Date.parse(project.studyDate)))
                    let url = '/files/' + project.case + '/' + project.studyType + '/RWR%20-%20' + project.studyType + '%20-%20' + t + '.pdf'
                    window.open(url)

                }}
                 alt="RWR" title="Open Radiologist Report" className={project.status == 'Awaiting Docs'? 'grayedOut':null}  //ref={'notes-' + project._id}
                /> */}

                {project.status !== 'Approved' && project.notes && project.notes.length > 0 ?
                    <img src="/resources/icons/notes.png" alt="Notes" title={this.getNotesForProject(project)} onClick={(e) => e.stopPropagation()} ref={'notes-' + project._id} /> :

                    (project.status !== 'Approved' ?
                        <img src="/resources/icons/notesGrayed.png" onClick={(e) => e.stopPropagation()} alt="Notes" title="This case has no notes" className="grayedOut" ref={'notes-' + project._id} /> : '')}

                {project.status === 'Approved' && !this.props.isCaseApproved(project.case) ? <img src="/resources/icons/alert.png" alt="Error" title="Cannot deliver. Other project(s) belonging to this case have not been approved!" /> : ''}

                {!this.state.loading && project.status === 'Approved' && this.props.isCaseApproved(project.case) ? <img src="/resources/icons/email.png" title="Deliver case" alt="Deliver case" onClick={(e) => {
                    e.stopPropagation();
                    this.deliverCase();
                }} /> : ''}

                {this.state.loading ? <div ref="loadingIcon" style={{
                    float: 'right',
                    marginTop: '1px',
                    marginLeft: '10px'
                }}>
                    <Loading type="spin" color="rgb(80,80,80)" height={'28px'} width={'28px'} />
                </div> : ''}

                <img src={jobsExist ? "/resources/icons/jobs.png" : "/resources/icons/jobsGrayed.png"} className={jobsExist ? "" : "grayedOut"} alt="Jobs" onClick={(e) => {
                    e.stopPropagation();
                    if (jobsExist)
                        this.props.manageJobs();
                }} title={jobsExist ? "Manage Project Jobs" : "This study has no jobs"} />

                {this.props.openEditor && this.props.openEditor.editor === 'contacts' ?
                    // Contact Editor
                    <div className="contactInfo" onClick={(e) => e.stopPropagation()}>

                        <div className="contactInfoRow">
                            Physician:

                            <input type="text" autoComplete="off" list={"physicianList_" + this.props.uniqueIdentifier} name="physicianName" className="contactInfoInput" placeholder="Contact Name" value={physicianData.name} onChange={(e) => this.props.onContactsValueChange(project._id, "physicianName", e.target.value)} />
                            <datalist id={"physicianList_" + this.props.uniqueIdentifier} type="text" >
                                {physicianOptions.map((physician, idx) => {
                                    return (<option key={idx} value={physician.name} > {physician.email} </option>)
                                })}
                            </datalist>

                            <input type="text" name="physicianContact" className="contactInfoInput" placeholder="Email" value={physicianData.contact} onChange={(e) => this.props.onContactsValueChange(project._id, "physicianContact", e.target.value)} />
                        </div>

                        <div className="contactInfoRow">
                            Attorney:

                            <input type="text" autoComplete="off" list={"attorneyList_" + this.props.uniqueIdentifier} name="attorneyName" className="contactInfoInput" placeholder="Contact Name" value={attorneyData.name} onChange={(e) => this.props.onContactsValueChange(project._id, "attorneyName", e.target.value)} />
                            <datalist id={"attorneyList_" + this.props.uniqueIdentifier} type="text" >
                                {attorneyOptions.map((attorney, idx) => {
                                    return (<option key={idx} value={attorney.name} > {attorney.email} </option>)
                                })}
                            </datalist>


                            <input type="text" name="attorneyContact" className="contactInfoInput" placeholder="Email" value={project.attorney && project.attorney.contact ? project.attorney.contact : ''} onChange={(e) => this.props.onContactsValueChange(project._id, "attorneyContact", e.target.value)} />
                        </div>

                    </div>
                    : this.props.openEditor && this.props.openEditor.editor === 'cost' ?
                        // Cost Editor
                        <div className="contactInfo" onClick={(e) => e.stopPropagation()}>
                            <div className="contactInfoRow">
                                Cost:
                                <input type="text" name="caseCost" className="contactInfoInput" placeholder={this.props.clients[project.client] ? this.props.clients[project.client].cost : ''} value={project.cost ? project.cost : ''} onChange={(e) => this.props.onCostValueChange(project._id, e.target.value)}
                                    onKeyUp={async (e) => {
                                        if (e.keyCode === 13) {    // On enter
                                            this.props.onCostConfirm(project._id);
                                        }
                                    }} />
                            </div>

                        </div>
                        : ''
                }

            </div>
        );
    }

}

export default withRouter(OpsProject);