// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import ProjectsPortal from '../../ProjectsPortal';
import ReviewProject from './ReviewProject';

import api from '../../../api';

export default class ReviewPortal extends ProjectsPortal {

    constructor(props) {

        super(props);

        this.columns = {
            "case": { label: "Case", width: 8, maxWidth: 60},
            "patientName": { label: "Patient Name", width: 12, maxWidth: 300},
            "patientSex": { label: "Sex", width: 3, maxWidth: 100},
            "patientDateOfBirth": { label: "DOB", width: 6, maxWidth: 140},
            "centerName": { label: "MRI Center", width: 13, maxWidth: 300},
            "studyDate": { label: "Study Date", width: 6, maxWidth: 140},
            "receiveDate": { label: "Received", width: 6, maxWidth: 100 },
            "lastAccess": { label: "Modified", width: 6, maxWidth: 100 },
            "studyType": { label: "Study Type", width: 5, maxWidth: 140},
            "status": { label: "Status", width: 27, maxWidth: 320}
        };

        this.state.centerMRARules = {}

    }

    componentDidMount = async () => {

        // Fetch projects
        let res = await this.fetchProjects();

        if (!res || !res.auth)
            return;

        if (!res.auth._id)
            window.location = "/login";

        else if (res.auth.type === 'center')
            window.location = "/center";

        else if (res.auth.type === 'endUser')
            window.location = '/centerenduser';

        await this.fetchCenters()

    }

    fetchCenters = async () => {

        // Get centers
        let res = await api.post('getCenterMRARules');

        if (!res || !res.data)
            return;

        await this.setState({
            centerMRARules: res.data
        });

    }

    // Field edit functions
    selectHandler = async (projectId, field, value) => {

        let res = await api.post('syncProject', {
            pId: projectId,
            projectDiff: {
                [field]: value
            }
        });

        if (!res || !res.success || !res.data)
            return;
        
        // Update
        this.updateProjects([res.data]);

    }

    // Update contact info
    contactValueChangeHandler = async (projectId, field, value) => {

        let project = this.state.projects[this.getProjectIdx(projectId)];

        switch (field) {

            case 'physicianName':
                if (!project.attendingPhysician)
                    project.attendingPhysician = {name: '', contact: ''};
                project.attendingPhysician.name = value;
                break;
            
            case 'physicianContact':
                if (!project.attendingPhysician)
                    project.attendingPhysician = {name: '', contact: ''};
                project.attendingPhysician.contact = value;
                break;
            
            case 'attorneyName':
                if (!project.attorney)
                    project.attorney = {name: '', contact: ''};
                project.attorney.name = value;
                break;
                
            case 'attorneyContact':
                if (!project.attorney)
                    project.attorney = {name: '', contact: ''};
                project.attorney.contact = value;
                break;
            
            default:

        }

        this.updateProjects([project]);

    }

    // Confirm/push
    contactConfirmHandler = async (projectId) => {

        let project = this.state.projects[this.getProjectIdx(projectId)];

        let res = await api.post('syncProject', {
            pId: projectId,
            projectDiff: {
                attendingPhysician: project.attendingPhysician,
                attorney: project.attorney
            }
        });

        if (!res || !res.success || !res.data)
            return;
        
        // Update
        this.updateProjects([res.data]);

    }

    renderProjects = () => {

        return (
            <div>
                {/* Sorted/ Filtered projects */}
                {this.state.displayProjects.map(idx => (
                    <ReviewProject key={this.state.projects[idx]._id}  user={this.props.user} project={this.state.projects[idx]} columns={this.columns} onUpdateProjects={this.updateProjects} centerMRARules={this.state.centerMRARules} />
                ))}
            </div>
        );

    }

}