// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Loading from 'react-loading';
import Switch from 'react-switch';

import ProjectsPortal from '../../ProjectsPortal';

import '../../../styles/ReviewProject.css';

import api from '../../../api';

// Radiologist DICOM Viewer URL
const dicomUrl = window.location.href.includes('localhost') ? 'http://localhost:8080/report/' : window.location.href.includes('staging') ? 'https://rad-staging.multusmedical.com/report/' : 'https://rad.multusmedical.com/report/';

class ReviewProject extends Component {

    state = {
        loading: false,
        generateMRARule: null,
        generateMRA: null,
    }

    componentDidMount = async () => {

        // Wait for the prop data for MRARules to be there
        while(this.props.centerMRARules[this.props.project.center.group] === undefined) {
            await new Promise(resolve => setTimeout(resolve, 50))
        }

        this.setState({
            generateMRARule: this.props.centerMRARules[this.props.project.center.group],
            generateMRA: this.props.centerMRARules[this.props.project.center.group]
        })

    }

    // Approve/reject project
    approveProject = async () => {

        await this.setState({
            loading: true
        });

        let res = await api.post('approveProjectReview', {
            pId: this.props.project._id,
            generateMRA: this.state.generateMRA,
        });

        if (!res || !res.success)
            return;
        
        // if (!res.success && res.error === "Client not set")
        //     return toast.error('Cannot approve project without having a client set. Please ask an operations person to set a client for this case.');

        await this.updateProject();

        await this.setState({
            loading: false
        });

    }

    rejectProject = async () => {

        await this.setState({
            loading: true
        });

        await api.post('rejectProjectReview', {
            pId: this.props.project._id
        });

        await this.updateProject();

        await this.setState({
            loading: false
        });

    }

    updateProject = async () => {

        let res = await api.post('getProject', {
            pId: this.props.project._id,
            projectDiff: {}
        });

        if (!res || !res.success || !res.data)
            return;
        
        // Update call to parent
        await this.props.onUpdateProjects([res.data]);

    }

    getFileLink = (file) => {

        if (!file)
            return;

        let cmp = file.split("/");
        cmp.splice(0,1); // Remove projectId from path

        let filePath = cmp.pop();

        let dirPath = cmp.join("::");

        return (
            <div onClick={() => window.open('/files/' + this.props.project.case + '/' + (dirPath === '' ? 'root' : dirPath) + '/' + filePath)} className="targetFile">{(cmp.length > 0 ? cmp.join('/') + '/' : '') + filePath}</div>
        );

    }

    projectHasNotesOrMemos = () => {

        return ((this.props.project.notes && this.props.project.notes.length > 0) || (this.props.project.memos && this.props.project.memos.length > 0));

    }

    toggleProjectNotesAndMemos = () => {

        let project = this.props.project;

        let projectDiv = this.refs['project-' + project._id];
        let notesImg = this.refs['notes-' + project._id];

        if (projectDiv === undefined || notesImg === undefined)
            return;

        if (projectDiv.style.height === '' || projectDiv.style.height === '32px') // Open
            projectDiv.style.height = (48 + ((project.notes ? project.notes.length : 0) + (project.memos ? project.memos.length : 0)) * 32) + 'px';

        else // Open
            projectDiv.style.height = '32px';

    }

    
    render() {

        const project = this.props.project;

        return (
            <div className="reviewProjectRow" ref={'project-' + project._id} style={this.props.user && this.props.user.type !== 'client' ? ProjectsPortal.getProjectStyleModifier(project.status) : {}}>

                {Object.keys(this.props.columns).map((key, idx) => <div key={'project-' + project._id + '_' + key} className="reviewProjectRowItem" style={{
                    width: "calc(" + this.props.columns[key].width + "% - 10px)",
                    maxWidth: this.props.columns[key].maxWidth-10 + "px"
                }}>

                    {key === 'status' && project.status === 'Review' ?
                    
                    // Review button
                    <div>
                        
                        <div className="reviewRadButton" onClick={() => this.props.history.push('/files/' + project.case + '/' + project.studyType)}>Review</div>
                        
                        <div className="reviewRadButton" onClick={() => window.open(dicomUrl + project._id)}>DICOM</div>

                        {this.state.generateMRA !== null && <div>
                            <div className="switchContainer" style={{height:"24px", width: "90px"}}>
                                <div className="switchLabel" style={{paddingRight: "8px"}}>Prepare MRA</div>
                            </div>
                            <Switch className="switchCheckbox" onChange={checked => this.setState({generateMRA: checked})} checked={this.state.generateMRA} height={22} width={40} />
                        </div>}

                    </div>:
                    
                    // Other statuses
                    ProjectsPortal.getDisplayValueFromKey(project, key, true)}

                </div>)}

                {this.props.user && this.props.user.type !== "client" ? this.projectHasNotesOrMemos() ? 
                <img src="/resources/icons/notes.png" alt="Notes" title="Case Notes" ref={'notes-' + project._id} onClick={() => this.toggleProjectNotesAndMemos()} /> : 
                <img src="/resources/icons/notesGrayed.png" alt="Notes" title="This case has no notes" className="grayedOut" ref={'notes-' + project._id} /> : ''}

                <img src={project.filesCount ? "/resources/icons/folder.png" : "/resources/icons/emptyFolder.png"} alt="Files" ref={'files-' + project._id} onClick={() => {
                    // this.props.history.push('/files/' + project.case);
                    window.open('/files/' + project.case, '_blank');
                }} style={{ marginLeft: '12px' }} title="View Case Files" />

                {project.client && project.client !== "" ?(!this.state.loading && this.props.user && this.props.user.type === 'radiologist' && project.status === 'Review' ? <img src="/resources/icons/confirm.png" alt="Approve" title="Approve Project" ref={'accept-' + project._id} onClick={() => this.approveProject()} /> : '') : <img src="/resources/icons/alert.png" alt="Error" title="Cannot approve case without a client selected. Let an operations person know!" />}

                {!this.state.loading && this.props.user && this.props.user.type === 'radiologist' && this.projectHasNotesOrMemos() && project.status === 'Review' ? <img src="/resources/icons/reject.png" alt="Reject" title="Reject Project" ref={'reject-' + project._id} onClick={() => this.rejectProject()} /> : ''}

                {this.state.loading ? <div ref="loadingIcon" style={{
                    float: 'right',
                    marginTop: '1px'
                }}>
                    <Loading type="spin" color="rgb(80,80,80)" height={'28px'} width={'28px'} />
                </div> : ''}

                {this.props.user && this.props.user.type !== "client" ? <div className="notesMemosContainer">
                    {/* Notes */}
                    {project.notes ? project.notes.map((note, idx) => (
                        <div key={idx} className="projectNote">
                            {this.getFileLink(note.filePath)}
                            <img alt="Note" title={note.note} src="/resources/icons/writtenNote.png" />
                        </div>
                    )) : ''}
                </div> : ''}

            </div>
        );
    }

}

export default withRouter(ReviewProject);