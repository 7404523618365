// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import CenterProject from './CenterProject';
import ProjectsPortal from '../../ProjectsPortal';

import api from '../../../api'

export default class CenterPortal extends ProjectsPortal {

    constructor(props) {

        super(props);

        this.columns = {
            "case": { label: "Case", width: 8, maxWidth: 60},
            "patientName": { label: "Patient Name", width: 12, maxWidth: 320},
            "patientSex": { label: "Sex", width: 4, maxWidth: 100},
            "patientDateOfBirth": { label: "DOB", width: 6, maxWidth: 100},
            "centerName": { label: "MRI Center", width: 11, maxWidth: 320},
            "studyDate": { label: "Study Date", width: 6, maxWidth: 100},
            "receiveDate": { label: "Received", width: 6, maxWidth: 100 },
            "lastAccess": { label: "Modified", width: 6, maxWidth: 100 },
            "studyType": { label: "Study Type", width: 6, maxWidth: 120},
            "handle": {label: "Handler", width: 6, maxWidth: 140},
            "physician": { label: "Physician", width: 9, maxWidth: 160},
            "attorney": {label: "Attorney", width: 9, maxWidth: 160},
            "status": { label: "Status", width: 9, maxWidth: 200}
        };
        
        this.state.sortBy = {
            field: "handle",
            direction: 1
        };

        this.state.fetchFiles = true;

        this.state.attorneys = {};
        this.state.physicians = {};

    }

    // Custom function for fetching Center access users
    fetchUsers = async () => {

        // Get users
        let res = await api.post('getUsers');

        if (!res || !res.data)
            return;

        let users = res.data;

        for (let user of users)
            if (!user.data)
                user.data = {};

        // Break users into attorneys and physicians
        let physicians = {};
        let attorneys = {};
        for (let curUser of users) {
            if (curUser.data.type === 'physician')
                physicians[curUser._id] = curUser;
            else if (curUser.data.type === 'attorney')
                attorneys[curUser._id] = curUser;
        }

        await this.setState({
            physicians: physicians,
            attorneys: attorneys
        });

    }

    componentDidMount = async () => {

        // Fetch projects
        let res = await this.fetchProjects();

        if (!res || !res.auth)
            return;

        if (!res.auth._id)
            window.location = "/login";

        if (['center','ops','lead'].includes(res.auth.type)){
            // continue
        }

        else if (res.auth.type === 'endUser')
            window.location = '/centerenduser';
        
        else // Tech/radiologist
            window.location = "/review";
 
        // Get physician / attorney users
        await this.fetchUsers();

    }

    updateAdressBook = async (userType, userId, projectId) => {

        let reqData = {userId: userId, pId: projectId};

        if (userType === 'physician')
            reqData.field = 'attendingPhysician';

        else if (userType === 'attorney')
            reqData.field = 'attorney';

        if (reqData.field) {
            
            // Update the projects contact
            let res = await api.post('updateProjectContact', reqData);

            if (!res || !res.success || !res.data)
            return;
        
            // Update project
            this.updateProjects([res.data]);

        }
    }

    renderProjects = () => {

        return (
            <div>
                {/* Sorted/ Filtered projects */}
                {this.state.displayProjects.map(idx => (
                    <CenterProject  user={this.props.user} 
                                    key={this.state.projects[idx]._id} 
                                    project={this.state.projects[idx]} 
                                    columns={this.columns}
                                    physicians={this.state.physicians} 
                                    attorneys={this.state.attorneys} 
                                    onUpdateProjects={this.updateProjects}
                                    updateAdressBook={this.updateAdressBook} />
                ))}
            </div>
        );

    }

}