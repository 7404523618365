// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

// React
import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styles
import './styles/App.css';

// Components
import Header from './components/Header';
import Login from './components/Login';
import CenterPortal from './components/PortalViews/Center/CenterPortal';
import DocsReview from './components/PortalViews/Center/DocsReview';
import AnimationPortal from './components/PortalViews/Animation/AnimationPortal';
import OpsPortal from './components/PortalViews/Ops/OpsPortal';
import AccessPortal from './components/PortalViews/Access/AccessPortal';
import ReviewPortal from './components/PortalViews/Review/ReviewPortal';
import EndUserPortal from './components/PortalViews/EndUser/EndUserPortal';
import LeadPortal from './components/PortalViews/Lead/LeadPortal';
import ClientsConsole from './components/ConsoleViews/Clients/ClientsConsole';
import OpsUsersConsole from './components/ConsoleViews/Users/OpsUsersConsole';
import CenterUsersConsole from './components/ConsoleViews/Users/CenterUsersConsole';
import CentersConsole from './components/ConsoleViews/Centers/CenterConsole';
import TasksPortal from './components/PortalViews/Task/TasksPortal';
import UploadPortal from './components/PortalViews/Upload/UploadPortal';
import StatsPortal from './components/PortalViews/Stats/StatsPortal';

import api from './api';

export default class App extends Component {

	// Initial state
	state = {
		
		// Portal view mode
		portal: "", // ops/center/access - empty string when not authenticated

		// Authenticated user
		user: null
	}

	// Initialization
	componentDidMount = async () => {

		// Fetch logged in user data
		let res = await api.get('whoami', {}, true); // Don't need auth to get auth data ;)

		if (!res || !res.auth._id)
			return;

		// Set authenticated user in state
		this.setState({
			user: res.auth
		});

	}

	// Update state if a child component requests so
	userChangeHandler = (user) => {
		
		this.setState({
			user: user
		});

	}

	toggleUserNotifications = async () => {

		let res = await api.post('updateNotificationSettings', {
			userId: this.state.user._id,
			deliveryNotifications: this.state.user.deliveryNotifications,
            emailNotifications: !this.state.user.emailNotifications
		});

		if (!res || !res.data)
			return;

		this.setState({
			user: res.data
		});
	}

	toggleDeliveryNotifications = async () => {

		let res = await api.post('updateNotificationSettings', {
			userId: this.state.user._id,
			deliveryNotifications: !this.state.user.deliveryNotifications,
			emailNotifications: this.state.user.emailNotifications
		});

		if (!res || !res.data)
			return

		this.setState({
			user: res.data
		});
	}

	// Logout handler
	logoutHandler = async () => {

		// Logout user using API
		await api.logout();

		// Re-direct to login screen
		window.location = '/login';

	}

	render() {

		return (
			// <HttpsRedirect>
				<Router>

					<div className="app">

						<ToastContainer
							position="bottom-right"
							autoClose={3000}
						/>

						{/* Navigation bar/header */}
						<Header user={this.state.user} toggleNotifications={this.toggleUserNotifications} toggleDeliveryNotifications={this.toggleDeliveryNotifications} onLogout={this.logoutHandler} />

						{/* App routes */}
						<Route path="/login" render={(props) => <Login {...props} onUserChange={this.userChangeHandler} />} />

						{/* Ops Portal */}
						<Route path="/" exact render={(props) => <OpsPortal {...props} user={this.state.user} /> } />

						{/* Animation Portal */}
						<Route path="/animation" exact render={(props) => <AnimationPortal {...props} user={this.state.user} /> } />

						{/* Lead Tech Portal */}
						<Route path="/lead" exact render={(props) => <LeadPortal {...props} user={this.state.user} /> } />

						{/* Center Portal */}
						<Route path="/center" exact render={(props) => <CenterPortal {...props} user={this.state.user} /> } />
						<Route path="/center/review/:project/:page" exact render={(props) => <DocsReview {...props} user={this.state.user} /> } />

						{/* Reviewer Portal */}
						<Route path="/review" exact render={(props) => <ReviewPortal {...props} user={this.state.user} /> } />

						{/* Physician Portal */}
						<Route path="/centerenduser" exact render={(props) => <EndUserPortal {...props} user={this.state.user} /> } />

						{/* File Access Portal */}
						<Route path="/files/:case/:subdir?/:file?" render={(props) => <AccessPortal {...props} portalEndpoint={"/files/"} user={this.state.user} /> } />

						{/* Doctor Access Portal */}
						<Route path="/medical/:case/:subdir?/:file?" render={(props) => <AccessPortal {...props} portalEndpoint={"/medical/"} user={this.state.user} /> } />

						{/* Consoles */}
						<Route path="/clients" exact render={(props) => <ClientsConsole {...props} user={this.state.user} /> } />

						<Route path="/users" exact render={(props) => <OpsUsersConsole {...props} user={this.state.user} /> } />
						<Route path="/center/users" exact render={(props) => <CenterUsersConsole {...props} user={this.state.user} /> } />


						<Route path="/centers" exact render={(props) => <CentersConsole {...props} user={this.state.user} /> } />

						{/* Tasks */}
						<Route path="/tasks" exact render={(props) => <TasksPortal {...props} user={this.state.user} /> } />

						{/* Upload */}
						{/* <Route path="/upload" exact render={(props) => <UploadPortal {...props} user={this.state.user} /> } /> */}

						{/* Stats */}
						<Route path="/stats" exact render={(props) => <StatsPortal {...props} user={this.state.user} /> } />

					</div>

				</Router>
			// </HttpsRedirect>
		);
	}
	
}