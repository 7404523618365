import React, { Component } from 'react';
import Loading from 'react-loading';

import '../../../styles/DocsReview.css';
import api from '../../../api';

export default class DocsReview extends Component {

    state = {
        pages: [],
        options: [],
        localPath: ''
    }

    componentWillMount = async () => {

        await this.fetchPageList(this.props.match.params.project);
        await this.downloadPage(this.props.match.params.page);

    }

    componentWillReceiveProps = async (nextProps) => {

        await this.setState({
            localPath: ''
        });

        await this.fetchPageList(nextProps.match.params.project);
        await this.downloadPage(nextProps.match.params.page);

    }

    // Get page list and docRef options
    fetchPageList = async (pId) => {

        // Get page data and options
        let res = await api.post('getPagesForReview', {
            pId: pId
        });

        if (!res || !res.data || !res.data.pages || !res.data.refOptions)
            return;

        await this.setState({
            pages: res.data.pages,
            options: res.data.refOptions
        });
        
    }

    // Download page data
    downloadPage = async (page) => {

        if (this.state.pages.length === 0)
            return;

        let res = await api.download('downloadCasePage', {
            filePath: this.state.pages[page-1].path,
            pId: this.props.match.params.project
        });

        if (!res) // Error
            return;
        
        this.setState({
            localPath: window.URL.createObjectURL(new Blob([res]))
        });

    }

    // Set docRef for page
    setPageDocRef = async (docRef) => {

        let pageIdx = this.props.match.params.page-1;

        // Local state update
        let pages = [...this.state.pages];
        pages[pageIdx].docRef = docRef;

        await this.setState({
            pages: pages
        });

        await api.post('setPageDocRef', {
            pId: this.props.match.params.project,
            filePath: this.state.pages[pageIdx].path,
            docRef: docRef
        });
    }

    onConfirm = async () => {

        await this.setState({
            localPath: '' // To bring the loading screen up
        });

        let res = await api.post('confirmDocsReview', {
            pId: this.props.match.params.project
        });

        if (!res || !res.success)
            return;

        // Go to cases page
        this.props.history.push('/center');
    }

    render() {
        return (
            <div>
                <div className="docsReviewHeader">
                    Review

                    &nbsp;‣&nbsp;

                    Page {this.props.match.params.page} of {this.state.pages.length}

                    {this.state.pages.length > 0 && this.state.pages[this.props.match.params.page-1].docRef !== '' && parseInt(this.props.match.params.page) === this.state.pages.length ? 

                    // Confirm
                    (this.state.localPath !== '' ? <div className="docsReviewButton docsReviewNextButton" onClick={() => this.onConfirm()}>Confirm</div> : '') : 

                    this.state.pages.length > 0 && this.state.pages[this.props.match.params.page-1].docRef !== '' && this.props.match.params.page <= this.state.pages.length ?

                    // Next
                    <div className="docsReviewButton docsReviewNextButton" onClick={() => this.props.history.push('/center/review/' + this.props.match.params.project + '/' + (parseInt(this.props.match.params.page) + 1))}>Next</div> : ''}

                    {this.props.match.params.page > 1 ? <div className="docsReviewButton docsReviewBackButton" onClick={() => this.props.history.push('/center/review/' + this.props.match.params.project + '/' + (parseInt(this.props.match.params.page) - 1))}>Back</div> : ''}
                        
                    {/* Doc ref options */}
                    <select className="docsReviewSelect" onChange={(e) => this.setPageDocRef(e.target.value)} value={this.state.pages.length > 0 && this.state.pages[this.props.match.params.page-1].docRef}>
                        <option value=""></option>
                        {this.state.options.map(option => {
                            return (
                                <option key={'option-'+option} value={option}>{option}</option>
                            );
                        })}
                    </select>

                </div>

                <div className="docsReviewContainer">
                    {this.state.localPath !== '' ? <img src={this.state.localPath} alt="File" /> : <div className="loadingIconContainer">
                        <Loading type="spin" color="rgba(230,155,30,0.5)" height={'120px'} width={'120px'} />
                    </div>}
                </div>
            </div>
        );
    }

}