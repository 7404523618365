// Keep up to date!
export const studyTypes = [
    "Brain",
    "Cervical",
    "Lumbar",
    "Thoracic",
    "RightShoulder",
    "LeftShoulder",
    "RightKnee",
    "LeftKnee",
    "RightAnkle",
    "LeftAnkle",
    "RightFoot",
    "LeftFoot",
    "RightHip",
    "LeftHip",
    "RightWrist",
    "LeftWrist",
    "RightElbow",
    "LeftElbow",
    "RightHand",
    "LeftHand"
];

export const statusWorkflow = [
    "Awaiting Docs",
    "Processing Docs",
    "Review Docs",
    "Segment",
    "Comparison",
    "Animation",
    "Animation: QC",
    "Animation: Render",
    "Animation: Review",
    "Rejected",
    "QC",
    "QC: Review",
    "Review",
    "Approved",
    "Delivered",
    "Removed",

    // Error statuses
    "Error: Alignment",
    "Error: No Injury",
    "Error: Classifier",
    "Error: Other",
    "Error: Segment",
    "Error: Injury",
    "Error: AI:Segment",
    "Error: AI:Radiology",
    "Error: AI:Injury",
    "Error: AI:Renderer",

    // Testing statuses
    "FOR TESTING ONLY"
];



export const statusWorkflowAnimation = [
    "",
    "Storyboard",
    "Animate",
    "Render/Review",
    "Resolve",
    "Final Review",
    "QC",
    "Revision",
    "Revision Fixed",
    "Approved",
    "Delivered"
];