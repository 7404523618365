// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import DropZone from 'react-dropzone';
import Loading from 'react-loading';
import { toast } from 'react-toastify';

import '../styles/DicomUploadConsole.css'
import formatDate from '../utilities/formatDate';
import formatTime from '../utilities/formatTime';
import api from '../api';


const allowedUserTypes = ['endUser']

const statusColors = {
    'Error': 'red',
    'Started': 'green',
    'Queued': 'rgb(255, 140, 26)'
}


export default class DicomUpload extends Component {

    state = {
        uploading: false,
        verified: false,
        consoleOpen: false,
        runningJobs: []
    }

    componentDidMount = async () => {

        // Get running jobs associated with this user
        let res = await api.post('getDicomUploadJobs');

        if (!res || !res.auth)
            return;

        if (allowedUserTypes.includes(res.auth.type)) 
            await this.setState({verified: true});
    
        // Now populate the running jobs.
        this.setState({
            runningJobs: res.data
        });

    }


    onDicomUpload = async (files) => {

        // Create form data
        let formData = new FormData();

        formData.set('uploader', this.props.user._id);
        formData.set('uploadingCenter', this.props.user.data.center);
        formData.append('doc', files[0]);

        // Set loading
        await this.setState({uploading: true});

        let res = await api.post('uploadDicomZip', formData);

        // Remove loading
        await this.setState({uploading: false});

        if (!res.success)
            toast.error(res.error);
        else
            toast.info("File upload successful. Queued for proccessing");

        return
    }

    toggleUploadConsole = () => {

        this.setState({
            consoleOpen: !this.state.consoleOpen
        });
    }


    render () {

        if (!this.state.verified)
            return ''

        return ( 

            <div className='dicomUploadContainer'>


            {/* Thumbnail */}
            <div className='dicomUploadThumbnail' onClick={()=> {this.toggleUploadConsole()}}>

                    <img src="/resources/icons/upload.png" alt=''/>
                    <p>Upload Dicom</p>

            </div>

            {/* Console */}
            {this.state.consoleOpen ?  <div className='dicomUploadConsole' style={this.state.runningJobs.length === 0 ? {height: 210}: {}}>

                    {/* Tracker for running jobs */}
                    {this.state.runningJobs.length > 0 ? <div className='dicomUploadTracker'>
                        {this.state.runningJobs.map((job, idx) => {
                            
                            let jobDate = formatDate(job.timeStamp.queued);
                            let jobTime = formatTime(job.timeStamp.queued, false);
                            let statusColor = Object.keys(statusColors).includes(job.status) ? statusColors[job.status]: 'black';

                            return (<p key={idx}> {jobDate + ' - ' + jobTime + ' : '} <span style={{color:statusColor}}> {job.status}</span> </p>)
                        })}

                    </div> : <div className="dicomUploadTracker"> <p style={{opacity: 0.5}}>No running jobs </p></div>}
                    
                    {/* Dicom Upload DropZone */}
                    <div className='dicomUploadDrop'>
                        <DropZone accept='application/zip' onDrop = {(files) => {this.onDicomUpload(files)}}>
                            {({getRootProps, getInputProps, isDragActive}) => (
                        
                                <div {...getRootProps({className:"dicomUploadBox"})}  style={isDragActive ? { opacity: '1' } : {}}>

                                    <input {...getInputProps()} />

                                    {/* Loading / Upload display */}
                                    {!this.state.uploading ?  <p >Upload Dicom Zip</p>  : <div className='loadingIcon'>
                                        
                                        <Loading type="spin" color="rgb(80,80,80)" height={'24px'} width={'24px'} style={{
                                            marginLeft: '50px',
                                            marginRight: '50px',
                                            marginTop: '18px'
                                        }} />
                                        
                                    </div>}
                                </div>)}
                        </DropZone>
                    </div>

                    <div className="dicomUploadNotice"> <p style={{opacity: 0.4}}> * Zip files must contain a single study </p></div>

                </div> 
            : ''}


            </div>

        )
    }
}