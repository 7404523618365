// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from 'react-loading';
import { Player } from 'video-react';
import Watson from 'watson-speech';
import { ContextMenu, MenuItem,  ContextMenuTrigger } from 'react-contextmenu';
import { toast } from 'react-toastify';

import InjuryEditor from './InjuryEditor';
import ModelView from '../3DView/ModelView';

import 'react-toastify/dist/ReactToastify.css';

import '../../../styles/AccessPortal.css'
import '../../../../node_modules/video-react/dist/video-react.css'; // For video player
import '../../../styles/ContextMenu.css'

import api from '../../../api';

import UTIF from 'utif';
import UPNG from 'upng';
import path from 'path';
import Dropzone from 'react-dropzone';

import OnePortalAccessRoot from '../OnePortalAccessRoot';
import OnePortalAccessStudy from '../OnePortalAccessRoot/Study.js';

import formatDate from '../../../utilities/formatDate';

const supportedFiles = ["avi", "doc", "jpg", "mp3", "mp4", "pdf", "png", "txt", "zip", "tif", "tiff"];
const imageFormats = ["jpg", "png", "tif", "tiff"];
const videoFormats = ["avi", "mp4"];

const MIN_EDITOR_WIDTH = 250;
const MAX_EDITOR_WIDTH = 650;

export default class AccessPortal extends Component {

    state = {
        // Navigator
        caseFiles: [],
        folders: [],
        files: [],
        newFolderName: null,
        renamedFolderOld: null,
        renamedFolderNew: null,
        movedFolderOld: null,
        movedFolderNew: null,
        renamedFileOld: null,
        renamedFileNew: null,
        movedFileOld: null,
        movedFileNew: null,

        // Sidebar
        projectMap: new Map(),
        previewPath: '',

        // Viewer
        pages: null,
        pageNumber: 1,
        localPath: '',
        rawFilePath: '', // For downloads only
        caseFileBlob: '',
        loadedCaseFile: '',

        // File memos/notes
        newNote: false,
        note: '',
        recording: null,

        // Access data
        accessKey: '',
        authSuccess: true, // While loading...

        compressing: false,
        zipExists: false, 

        editorHidden: false,
        editorWidth: 650,

        modalOverlay: true,
        videoBlobPath: undefined,
        animVideoBlobPath: undefined
    }

    unsetOverlay = () => {
        if (this.state.modalOverlay)
            this.setState({modalOverlay: false})
    }

    setOverlay = () => {
        if (!this.state.modalOverlay)
            this.setState({modalOverlay: true})
    }

    speechToTextToken = null
    checkInterval = null

    // On mounting
    componentDidMount = async () => {

        if (this.props.match.params.file)
            this.unsetOverlay();

        await this.loadCaseFiles();

    }

    // On loading props
    componentWillReceiveProps = (nextProps) => {
        
        // File
        if (nextProps.match.params.file) {
            this.unsetOverlay();
            this.getTargetFile(nextProps.match.params.subdir, nextProps.match.params.file);
        }
        
        // Directory
        else {
            this.enterDirectory(nextProps.match.params.subdir);
            
            if (nextProps.match.params.subdir &&
                (!this.state.previewPath ||
                 this.props.match.params.file ||
                 !this.props.match.params.subdir ||
                 nextProps.match.params.subdir.split("::", 1)[0] !== this.props.match.params.subdir.split("::", 1)[0])) {      
                this.setState({
                    previewPath: ''
                });
                
                let projectDir = nextProps.match.params.subdir.split("::", 1)[0];        
                let imgPath = "/" + projectDir + "/Images/Front.jpg";
                for (let file of this.state.caseFiles) {
                    if (file.path === "/" + projectDir + "/Images/Front.jpg") {
                        imgPath = file.path;
                        break;
                    } else if (file.path.match("/" + projectDir + "/Images/[^/]*.jpg")) {
                        imgPath = file.path;
                    }
                }
                this.updatePreview(imgPath);
            }
            // Reset local file path
            this.setState({
                localPath: ''
            });
        }
        
    }

    // Show injury editor?
    shouldShowInjuryEditor = () => {

        const file = this.props.match.params.file;
        const user = this.props.user;
        
        return file && file.includes("RWR - ") && user && user._id && (["ops", "radiologist", "tech", "lead"].includes(user.type)) ? true : false;

    }

    // Speech to text
    // Start session
    startSpeechToTextSession = async () => {

        // Initialize a Watson stream object
        this.watsonStream = Watson.SpeechToText.recognizeMicrophone({ access_token: this.speechToTextToken });

        this.watsonStream.setEncoding('utf-8');

        // Set stream data handler
        this.watsonStream.on('data', data => {

            // Append recording to the user's note
            this.setState({
                note: this.state.note + data
            });

        });

        // Set stream error handler
        this.watsonStream.on('error', async err => {
            
            if (err.name === "Websocket connection error") {
                // Need to reset token
                this.watsonStream.stop();
                await this.refreshWatsonToken();
                this.startSpeechToTextSession(); // Restart
            }

        });

    }

    // Handle mic button click
    micClicked = async () => {

        // If already recording, stop...
        if (this.state.recording) {

            // Terminate
            this.watsonStream.stop();

            this.setState({
                recording: false
            });

        }

        // Else, start recording...
        else {

            if (!this.speechToTextToken)
                await this.refreshWatsonToken();

            // Try to establish a connection with the API token
            try {
                this.startSpeechToTextSession();
            }

            catch (err) {
                // Get a new token
                await this.refreshWatsonToken();
                this.startSpeechToTextSession();
            }

            // Set state to recording...
            this.setState({
                recording: true
            });

        }

    }

    refreshWatsonToken = async () => {

        let res = await api.get('watsonAuth');

        if (!res || !res.data)
            return;

        this.speechToTextToken = res.data;

    }

    onSubmitAccessKey = async () => {

        await this.loadCaseFiles();

    }

    checkCompressionStatus = async () => {

        let res = await api.post('getFilesForCaseAuth', {
            case: this.props.match.params.case
        });

        this.setState({
            zipExists: res.zip,
            compressing: res.zip ? false : true
        });

        if (!res.zip)
            setTimeout(this.checkCompressionStatus, 5000);

    }

    loadCaseFiles = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const urlParamAccessKey = urlParams.get('accessKey');
        
        let accessKey = '';

        if(urlParamAccessKey) accessKey= urlParamAccessKey
        else accessKey = this.state.accessKey

        // Load project map, use to get project based on subdir
        let map = new Map();
        let res = accessKey === '' ? await api.post('getProjectsByCase', {
            caseNumber: this.props.match.params.case,
            excludeInvisibleProjs: true,
        }) : await api.post('getProjectsByCaseKey', {
            caseNumber: this.props.match.params.case,
            accessKey: accessKey,
            excludeInvisibleProjs: true,
        });

        if (res && res.success && res.data) {
            for (let proj of res.data) {
                map.set(proj.studyType, proj);
            }
        }

        // If we're not authorized, then we need to request using an accessKey
        res = accessKey === '' ? await api.post('getFilesForCaseAuth', {
            case: this.props.match.params.case
        }) : await api.post('getFilesForCaseKey', {
            case: this.props.match.params.case,
            accessKey: accessKey
        });
        
        if (!res || !res.success || !res.data) {

            if (this.state.accessKey !== '')
                this.refs.accessKeyInput.placeholder = "Invalid key!";
            
                // remove the URL param if wrong
                if(urlParamAccessKey)
                window.history.replaceState(null, null, window.location.pathname);

            // Clear access key
            return this.setState({
                accessKey: '',
                authSuccess: false
            });
        }

        let caseFiles = [];
        let filePaths = [];

        // Ignore duplicates
        for (let file of res.data) {
            if (!filePaths.includes(file.path)) {

                // Extract the video files for each project
                if (file.path.includes(".mp4")) {
                    
                    for (let [studyType, proj] of map){
                        if (file.path.includes(studyType)) {

                            // Case: Study => Animation Video
                            if (file.path.includes('Animation')) {
                                let fileArray = file.path.split("/")
                                proj.animVideoBlobPath = fileArray[fileArray.length - 1]
                            }

                            // Case: Study => StarkVideo
                            else {
                                let fileArray = file.path.split("/")
                                proj.videoBlobPath = fileArray[fileArray.length - 1]
                            }
                        }
                    }

                }

                caseFiles.push(file);
                filePaths.push(file.path);
            }
        }

        await this.setState({
            caseFiles: caseFiles,
            projectMap: map,
            newFolderName: null,
            zipExists: res.zip,
            authSuccess: true, // Managed to get in if we're here! :)
        });

        // File
        if (this.props.match.params.file)
            this.getTargetFile(this.props.match.params.subdir, this.props.match.params.file);

        // Directory
        else 
            this.enterDirectory(this.props.match.params.subdir);

    }

    getTargetFile = async (subdir, file) => {

        document.title = file + " | Multus Medical";

        // Convert URL to path
        let relativePath = (subdir === 'root' ? '' : subdir.replace(new RegExp(/::/g), "/") + "/") + file;

        if (file === 'view.3d') {
            await this.setState({
                localPath: 'view.3d',
                rawFilePath: '',
                caseFileBlob: '',
                newNote: false,
                note: '',
                loadedCaseFile: ''
            });
            return;
        }

        // Find path in caseFiles
        for (let caseFile of this.state.caseFiles) {
            let cmp = caseFile.path.split("/");
            cmp.splice(0,1);

            if (relativePath === cmp.join("/")) {

                let blob, localPath, rawFilePath = null;

                // Video files are streamed
                if (videoFormats.includes(path.extname(file).substring(1)))

                    localPath = api.apiUrl + "api/streamVideo?filePath=" + caseFile.path + "&caseNumber=" + this.props.match.params.case + (this.state.accessKey !== '' ? ("&accessKey=" + this.state.accessKey) : '');
                
                // All other files
                else {
                    // Download file
                    let res = await api.download('downloadCaseFile', {
                        filePath: caseFile.path,
                        caseNumber: this.props.match.params.case,
                        accessKey: this.state.accessKey !== '' ? this.state.accessKey : null
                    });

                    // For troubleshooting...
                    // let reader = new FileReader();
                    // reader.onload = (e) => console.log(reader.result);
                    // reader.readAsText(res);

                    if (!res) // Error
                        return;

                        let extension = path.extname(caseFile.path).toLowerCase();

                    // PDF Files
                    if (extension === '.pdf')
                        blob = new Blob([res], {type: 'application/pdf'});
                    
                    // TIFF Files
                    else if (extension === '.tif' || extension === 'tiff') {
                        
                        rawFilePath = window.URL.createObjectURL(new Blob([res]));

                        let tiffData = await new Response(res).arrayBuffer();
                        
                        let ifds = UTIF.decode(tiffData);
                        UTIF.decodeImage(tiffData, ifds[0]);

                        // Convert to PNG
                        let pngData = UPNG.encode(UTIF.toRGBA8(ifds[0]), ifds[0].width, ifds[0].height, 0);

                        blob = new Blob([pngData]);

                    }

                    // All other files (standard image files)
                    else
                        blob = new Blob([res]);


                    localPath = window.URL.createObjectURL(blob);

                    if (!rawFilePath)
                        rawFilePath = localPath;

                }

                // Get note associated with file
                let noteRes = await api.post('getCaseFileNote', {
                    case: this.props.match.params.case,
                    filePath: caseFile.path
                });

                await this.setState({
                    localPath: localPath,
                    rawFilePath: rawFilePath,
                    caseFileBlob: blob,
                    newNote: false,
                    note: noteRes && noteRes.data && noteRes.data.note ? noteRes.data.note : '',
                    loadedCaseFile: caseFile.path
                });

                return;
            }
        }

    }

    enterDirectory = async (dir) => {

        document.title = "Case #" + this.props.match.params.case + " | Multus Medical";

        // Go through each file, remove duplicates and compile into relative paths
        let files = [];
        let folders = [];

        // Get sub directory components
        let pwd = this.getSubDirComponents(dir).join("/");

        for (let file of this.state.caseFiles) {

            let cmp = file.path.split("/");

            // Remove root
            cmp.splice(0,1);

            let filePath = cmp.join("/");

            // Only show folders and files at this path
            if (filePath.substring(0,pwd.length) === pwd) {

                let relativePath = filePath.substr(pwd.length);
                if (relativePath[0] === '/')
                    relativePath = relativePath.substring(1);

                if (relativePath === "")
                    continue;

                let relativePathCmp = relativePath.split("/");

                // File or directory?
                if (file.docRef === 'Folder' && relativePathCmp.length === 1) {
                    if (!folders.includes(relativePathCmp[0]))
                        folders.push(relativePathCmp[0]);
                }

                else if (relativePathCmp.length > 1) {
                    if (!folders.includes(relativePathCmp[0]))
                        folders.push(relativePathCmp[0]);
                }

                else if (!files.includes(relativePath)) {
                    let extension = path.extname(relativePathCmp[0]).substring(1).toLowerCase();

                    if ( (this.props.portalEndpoint==="/medical/") && !imageFormats.includes(extension) && !videoFormats.includes(extension))
                        continue;

                    files.push(relativePath);
                }

            }

        }

        await this.setState({
            folders: folders,
            files: files
        });
    }

    // Get sub directory components from URL
    getSubDirComponents = (filePath) => {
        if (!filePath || filePath === "root")
            return [];

        return filePath.split("::");
    }

    buildURLPathBackTo = (idx) => {

        let cmp = this.getSubDirComponents(this.props.match.params.subdir);

        let filePath = "";

        for (let i=0; i<=idx; i++)
            filePath += "::" + cmp[i];

        // Remove first __

        return this.props.portalEndpoint + this.props.match.params.case + "/" + filePath.substring(2);
    }

    getFileIconPath = (file) => {

        let fileType = path.extname(file).substring(1);

        return "/resources/icons/fileTypes/" + (supportedFiles.includes(fileType) ? fileType : 'default') + ".png";
    }

    buildURLPathToFolder = (target) => {
        return this.props.portalEndpoint + this.props.match.params.case + "/" + (this.props.match.params.subdir ? this.props.match.params.subdir + "::" : "") + target;
    }

    buildURLPathToFile = (target) => {
        return this.props.portalEndpoint + this.props.match.params.case + "/" + (this.props.match.params.subdir ? this.props.match.params.subdir : "root") + "/" + target;
    }

    buildURLPathTo3dViewer = (target) => {
        return this.props.portalEndpoint + this.props.match.params.case + "/" + (this.props.match.params.subdir ? this.props.match.params.subdir.split(':')[0] : "root") + "/" + target;
    }
    
    onSaveNote = async () => {

        await api.post('setCaseFileNote', {
            case: this.props.match.params.case,
            filePath: this.state.loadedCaseFile,
            note: this.state.note
        });

    }

    onNewFolder = async () => {

        if (this.state.newFolderName === '')
            return this.refs.newFolderName.placeholder = 'Cannot be empty!';

        // Show loading icon
        this.refs['coverOverlay'].style.display = 'block';

        let cmp = this.getSubDirComponents(this.props.match.params.subdir);

        let newFolderPath = "";
        
        if (cmp.length > 0)
            newFolderPath = cmp.join("/") + "/";

        toast.info('Created folder: ' + this.state.newFolderName);

        newFolderPath += this.state.newFolderName;

        let res = await api.post('newCaseFolder', {
            caseNumber: this.props.match.params.case,
            folderPath: newFolderPath
        });

        if (!res || !res.success)
            return;

        await this.loadCaseFiles();

        // Hide loading icon
        this.refs['coverOverlay'].style.display = 'none';

    }

    getAbsolutePath = (fileOrFolder) => {
        return (this.props.match.params.subdir ? "/" + this.getSubDirComponents(this.props.match.params.subdir).join("/") : '') + "/" + fileOrFolder;
    }

    onMoveFolder = async (oldFolder, newFolder) => {

        // Show loading icon
        this.refs['coverOverlay'].style.display = 'block';

        let res = await api.post('moveCaseFolder', {
            caseNumber: this.props.match.params.case,
            oldFolderName: oldFolder,
            newFolderName: newFolder
        });

        if (!res || !res.success)
            return;

        this.setState({
            newFolderName: null,
            renamedFolderNew: null,
            movedFolderNew: null,
            renamedFileNew: null,
            movedFileNew: null
        });

        await this.loadCaseFiles();

        // Hide loading icon
        this.refs['coverOverlay'].style.display = 'none';

    }

    onMoveFile = async (oldFile, newFile) => {

        // Show loading icon
        this.refs['coverOverlay'].style.display = 'block';

        let res = await api.post('moveCaseFile', {
            caseNumber: this.props.match.params.case,
            oldFileName: oldFile,
            newFileName: newFile
        });

        if (!res || !res.success)
            return;

        this.setState({
            newFolderName: null,
            renamedFolderNew: null,
            movedFolderNew: null,
            renamedFileNew: null,
            movedFileNew: null
        });

        await this.loadCaseFiles();

        // Hide loading icon
        this.refs['coverOverlay'].style.display = 'none';

    }

    onDeleteFolder = async (folder) => {

        // Show loading icon
        this.refs['coverOverlay'].style.display = 'block';

        let res = await api.post('deleteCaseFolder', {
            caseNumber: this.props.match.params.case,
            folderPath:  this.getAbsolutePath(folder)
        });

        if (!res || !res.success)
            return;

        await this.loadCaseFiles();

        // Hide loading icon
        this.refs['coverOverlay'].style.display = 'none';

        // this.props.history.push('/files/' + this.props.match.params.case);

    }

    // Download case directory as ZIP file
    onDownloadZip = async () => {

        let elem = document.createElement("a");
        document.body.appendChild(elem);
        elem.style.display = "none";
        elem.download = "Case " + this.props.match.params.case + ".zip";
        elem.target = '_blank';

        elem.href = api.apiUrl + "api/downloadCaseZip?caseNumber=" + this.props.match.params.case + (this.state.accessKey !== '' ? ("&accessKey=" + this.state.accessKey) : '');
        
        elem.click();

    }

    // Generate ZIP file
    onCompressCaseFiles = async () => {

        this.setState({
            compressing: true
        });

        let res = await api.post('compressCaseFiles', {
            caseNumber: this.props.match.params.case
        });

        if (!res || !res.success)
            this.setState({
                compressing: false
            });
        
        setTimeout(this.checkCompressionStatus, 5000);

    }

    // Download opened file
    onDownloadFile = async () => {

        let elem = document.createElement("a");
        document.body.appendChild(elem);
        elem.style.display = "none";
        elem.download = this.props.match.params.file;
        elem.target = '_blank';

        // Video files are streamed, so we need to request a download
        if (videoFormats.includes(path.extname(this.props.match.params.file).substring(1))) {
            elem.href = api.apiUrl + "api/downloadVideo?filePath=" + this.state.loadedCaseFile + "&caseNumber=" + this.props.match.params.case + (this.state.accessKey !== '' ? ("&accessKey=" + this.state.accessKey) : '');
            elem.click();
        }

        // All other files have already been downloaded
        else{
            // Handle IE download
            if (window.navigator.msSaveOrOpenBlob) 
                window.navigator.msSaveOrOpenBlob(this.state.caseFileBlob, this.props.match.params.file)
            else {
                elem.href = this.state.rawFilePath;
                elem.click();
            }
        }

    }

    onUploadFile = async () => {

        // Create new fileUpload object
        let fileUpload = document.createElement('input');
        fileUpload.setAttribute('type', 'file');
        fileUpload.setAttribute('name', 'caseFile');

        // Upload handler
        fileUpload.onchange = async (e) => {

            let formData = new FormData();
            formData.set('caseNumber', this.props.match.params.case);

            // Show loading icon
            this.refs['coverOverlay'].style.display = 'block';


            formData.append('docRef', "Other");
            formData.append('caseFile', e.target.files[0]);
            formData.append('folderPath', this.getSubDirComponents(this.props.match.params.subdir).join("/"));

            let res = await api.post('uploadCaseFile', formData);

            console.log('res',res)

            if (!res || !res.success)
                return;
            
            // Reload case files
            await this.loadCaseFiles();
            
            // Hide loading icon
            this.refs['coverOverlay'].style.display = 'none';

        }

        // Trigger an upload
        fileUpload.click();

    }

    onDeleteFile = async (file) => {
        
        // console.log(file);
        // console.log((this.props.match.params.subdir ? "/" + this.getSubDirComponents(this.props.match.params.subdir).join("/") : '') + "/" + file);

        // Show loading icon
        this.refs['coverOverlay'].style.display = 'block';

        let res = await api.post('deleteCaseFile', {
            caseNumber: this.props.match.params.case,
            filePath: this.getAbsolutePath(file)
        });

        if (!res || !res.success){
            // return;
        }

        await this.loadCaseFiles();

        // Hide loading icon
        this.refs['coverOverlay'].style.display = 'none';

        // this.props.history.push('/files/' + this.props.match.params.case);

    }

    onRegenerateDocs = async () => {
        await this.setState({
            loading: true
        });

        let res = await api.post('regenerateCaseDocs', {
            cId: this.props.match.params.case,
        });

        if (res.error) {
            toast.error(res.error + ". Rule for center most likely does not exist. Try checking centers tab.");
            return;
        }
        if (!res || !res.success)
            return;
        else
            toast.success("Docs regenerated successfully");

        await this.setState({
            loading: false
        });
    }

    userAllowedToEdit = () => {
        const user = this.props.user;
        return user && user._id && (user.type === 'ops' || user.type === 'radiologist' || user.type === 'lead');
    }

    userAllowedToAddNote = () => {
        const user = this.props.user;
        return user && user._id && (user.type === 'ops' || user.type === 'radiologist' || user.type === 'lead');
    }

    userAllowedToDelete = () => {
        const user = this.props.user;
        return user && user._id && (user.type === 'ops' || user.type === 'radiologist' || user.type === 'lead');
    }

    // Common Context menu functions
    // Re-used functions only - else, see the context menu definition in the render funciton
    onOpen = (e, data) => this.props.history.push(data.url)
    onNewTab = (e, data) => window.open(data.url, "_blank");

    renderZipHandler = () => {

        return (<div className="accessControls">
            {this.props.user && this.props.user.type === "ops" ? (this.state.compressing ? <div className="accessControlItem" title="Case is queued for compression. Check back later!" >Compressing...</div> :
            <div className="accessControlItem" onClick={() => this.onCompressCaseFiles()}>(Re)-Compress</div>) : ''}

            {this.state.zipExists ? <div className="accessControlItem" onClick={() => this.onDownloadZip()}>Download ZIP</div> : ''}
        </div>);

    }

    renderControls = () => {
    
        if (this.props.match.params.file) {
            if (this.props.match.params.file === 'view.3d') {
                return "";
            }

            if (this.state.localPath !== '')
                return (
                    <div className="accessControls">
                        <div className="accessControlItem" onClick={() => this.onDownloadFile()}>Download</div>

                        {this.userAllowedToAddNote() && !this.state.newNote && this.state.note === '' ? <div className="accessControlItem" onClick={() => this.setState({ newNote: true })}>Suggest Edit</div> : ''}
                    </div>
                );

        }

        // Folder controls
        else if (this.userAllowedToEdit()) // Make sure user is allowed to edit
            return (<div className="accessControls">

                {this.renderZipHandler()}

                <div className="accessControlItem" onClick={() => this.onUploadFile()}>Upload File</div>
                
                <div className="accessControlItem" onClick={() => {
                    this.setState({
                        renamedFolderNew: null,
                        movedFolderNew: null
                    });

                    if (this.state.newFolderName === null)
                        this.setState({
                            newFolderName: ''
                        });
                    
                    else this.onNewFolder();
                }}>New Folder</div>

                {this.props.user && this.props.user.type === "ops" ? <div className="accessControlItem" onClick={() => this.onRegenerateDocs()}>(Re)-Generate Case Docs</div> : ''}

                {this.state.newFolderName !== null ? <input type="text" ref="newFolderName" name="newFolderName" placeholder="Folder name..." value={this.state.newFolderName} onChange={(e) => this.setState({ newFolderName: e.target.value })} /> : ''}

                {/* Folder move/rename */}
                {this.state.renamedFolderNew ? <div className="accessControlItem" onClick={() => this.onMoveFolder(this.getAbsolutePath(this.state.renamedFolderOld), this.getAbsolutePath(this.state.renamedFolderNew))}>Rename Folder</div> : ''}

                {this.state.renamedFolderNew ? <input type="text" ref="renamedFolderNew" name="renamedFolderNew" value={this.state.renamedFolderNew} onChange={(e) => this.setState({ renamedFolderNew: e.target.value })} /> : ''}

                {this.state.movedFolderNew ? <div className="accessControlItem" onClick={() => this.onMoveFolder(this.state.movedFolderOld, this.state.movedFolderNew)}>Move Folder</div> : ''}

                {this.state.movedFolderNew ? <input type="text" ref="movedFolderNew" name="movedFolderNew" style={{ width: '240px' }} value={this.state.movedFolderNew} onChange={(e) => this.setState({ movedFolderNew: e.target.value })} /> : ''}

                {/* File move/rename */}
                {this.state.renamedFileNew ? <div className="accessControlItem" onClick={() => this.onMoveFile(this.getAbsolutePath(this.state.renamedFileOld), this.getAbsolutePath(this.state.renamedFileNew))}>Rename File</div> : ''}

                {this.state.renamedFileNew ? <input type="text" ref="renamedFileNew" name="renamedFileNew" value={this.state.renamedFileNew} onChange={(e) => this.setState({ renamedFileNew: e.target.value })} /> : ''}

                {this.state.movedFileNew ? <div className="accessControlItem" onClick={() => this.onMoveFile(this.state.movedFileOld, this.state.movedFileNew)}>Move File</div> : ''}

                {this.state.movedFileNew ? <input type="text" ref="movedFileNew" name="movedFileNew" style={{ width: '240px' }} value={this.state.movedFileNew} onChange={(e) => this.setState({ movedFileNew: e.target.value })} /> : ''}

            </div>);
        
        // Universal folder controls
        else 

            return this.renderZipHandler();

    }

    // File viewer
    // PDF
    onPDFLoadSuccess = ({ numPages }) => {
        this.setState({
            pages: numPages
        });
    }

    renderFile = () => {

        this.unsetOverlay();

        // Based on file type...
        let fileType = path.extname(this.props.match.params.file).substring(1).toLowerCase();
        
        // PDF Viewer
        if (fileType === 'pdf') {

            return (
                <object width="100%" height="100%" type="application/pdf" data={this.state.localPath}>
                    Browser does not support viewing PDF files.
                </object>
            );

        }

        // Image Viewer
        else if (imageFormats.includes(fileType)) {

            return (
                <div className="imageViewer">
                    <img src={this.state.localPath} alt="File" />
                </div>
            );

        }

        // Video stream
        else if (videoFormats.includes(fileType)) {

            return (
                <Player width={'100%'} fluid={false} height={'100%'}>
                    <source src={this.state.localPath} />
                </Player>
            );
        }

        // 3D Viewer
        else if (fileType === "3d") {

            if (this.state.authSuccess && this.state.projectMap.size > 0)
            {
                let studyType = this.props.match.params.subdir
                let project = this.state.projectMap.get(studyType)
                let projectID = project._id
                let labels = project.labels.map(rawLabel => {
                    return {
                        loc: [rawLabel.position.x, rawLabel.position.y, rawLabel.position.z],
                        text: rawLabel.label
                    }
                })
                return (
                    <ModelView
                        studyType={studyType}
                        case={this.props.match.params.case}
                        accessKey={this.state.accessKey}
                        projectID={projectID}
                        project={project}
                        patientSex={project.patient.sex}
                        labels={labels}
                        history={this.props.history}
                    />
                );
            }
        }

        // Unsupported type - open in new tab
        else {

            return 'unsupported';
        }

    }

    updatePreview = async (imgPath) => {
        let res = await api.download('downloadCaseFile', {
            filePath: imgPath,
            caseNumber: this.props.match.params.case,
            accessKey: this.state.accessKey !== '' ? this.state.accessKey : null
        });

        if (!res || res.type === 'application/json') {
            await this.setState({
                previewPath: null
            });
            return;
        }

        let localPath = window.URL.createObjectURL(new Blob([res]));
        await this.setState({
            previewPath: localPath
        });
    }

    renderSidebar = () => {
        if (!this.props.match.params.subdir) {
            return "";
        }

        let projectDir = this.props.match.params.subdir.split("::", 1)[0];
        if (!this.state.projectMap.has(projectDir)) {
            return "";
        }

        let project = this.state.projectMap.get(projectDir);

        return (
            <div className="accessSideView">
                <div className="imageContainer">
                    <h1>{project.studyType}</h1>
                    <div className="placeHolder">
                        { this.state.previewPath ? 
                            <img src={this.state.previewPath}
                                width="100%" height="200px" style={{objectFit: "cover", objectPosition: "0 0"}} alt="Preview from 3D view" />
                        : (this.state.previewPath === null) ?
                            <h2>NO PREVIEW</h2> :
                            <Loading type="spin" color="rgba(230,155,30,0.5)" height={'60px'} width={'60px'} />
                        }
                    </div>
                    {project.viewer3d && <button className="btn" onClick={() => this.props.history.push(this.buildURLPathTo3dViewer("view.3d"))}>
                        <img src="/resources/icons/cube.svg" alt="cube" /> <p>3D View</p></button>}
                </div>
                <div className="listGroup">
                    <label htmlFor="patient">Patient</label>
                    <h2 id="patient">{project.patient.name}</h2>
                    <h3>Patient Sex: <u>{project.patient.sex === "M" ? "Male" : (project.patient.sex === "F" ? "Female" : "U")}</u></h3>
                    <h3>Date of Birth: <u>{new Date(formatDate(project.patient.dateOfBirth)).toLocaleDateString("en-US")}</u></h3>
                </div>
                <div className="listGroup">
                    <label htmlFor="study">Study</label>
                    <h2 id="study">{project.studyType}</h2>
                    <h3>Date of Exam: <u>{new Date(formatDate(project.studyDate)).toLocaleDateString("en-US")}</u></h3>
                    <h3>Case Status: <u>{project.status}</u></h3>
                </div>
                <div className="listGroup">
                    <label htmlFor="center">Facility</label>
                    <h2>{project.center.name}</h2>
                    <h3>Physician: <u>{project.attendingPhysician?.name}</u></h3>
                    <h3>Center: <u>{project.center.group}</u></h3>
                </div>
                {this.state.zipExists && <button className="downloadAll accessControlItem" onClick={() => this.onDownloadZip()}>Download Case Folder</button>}
            </div>
        );
    }

    uploadMultipleFiles = async (files) => {
        // Show loading icon
        this.refs['coverOverlay'].style.display = 'block';

        for (let file of files) {
            let formData = new FormData();
            formData.set('caseNumber', this.props.match.params.case);

            formData.append('docRef', "Other");
            formData.append('caseFile', file);
            formData.append('folderPath', this.getSubDirComponents(this.props.match.params.subdir).join("/"));
            
            let res = await api.post('uploadCaseFile', formData);

            console.log('res',res)

            if (!res || !res.success)
                return;
        }
        
        // Reload case files
        await this.loadCaseFiles();

        // Hide loading icon
        this.refs['coverOverlay'].style.display = 'none';

    }

    // Toggle injury editor
    toggleEditor = () => {

        this.setState({editorHidden: !this.state.editorHidden});

    }

    // Update injury editor width
    updateEditorWidth = (newWidth) => {

        // Closing drag?  
        if (newWidth < MIN_EDITOR_WIDTH - 30) {
            this.setState({
                editorWidth: MIN_EDITOR_WIDTH,
                editorHidden: true,
            });
        }

        // Too small? 
        else if (newWidth < MIN_EDITOR_WIDTH)
            this.setState({ editorWidth: MIN_EDITOR_WIDTH });

        // To large? 
        else if (newWidth > MAX_EDITOR_WIDTH)
            this.setState({editorWidth: MAX_EDITOR_WIDTH});

        // Update width
        else {
            this.setState({ editorWidth: newWidth });
        }

    }

    render() {

        // if (!this.props.location.pathname.includes("::Images") && this.state.localPath === "" && this.state.modalOverlay === false)
        //     this.setOverlay()

        return (
            <div>

                <div className="accessHeader">
                    {/* Root */}
                    <Link to={this.props.portalEndpoint + this.props.match.params.case}>Case #{this.props.match.params.case}</Link>

                    {this.getSubDirComponents(this.props.match.params.subdir).map((dir, idx) => <Link key={idx} to={this.buildURLPathBackTo(idx)}>
                        &nbsp;&nbsp;&nbsp;‣&nbsp;&nbsp;&nbsp;
                        {dir}
                    </Link>)}

                    {this.renderControls()}
                </div>

                {/* Loading overlay */}
                <div className="coverOverlay" ref="coverOverlay">
                    <div className="loadingIconContainer">
                        <Loading type="spin" color="rgba(230,155,30,0.5)" height={'120px'} width={'120px'} />
                    </div>
                </div>

                {this.props.match.params.file ? 

                    // File view container
                    <div className='fileViewerRoot'> 

                        {/* Injury Editor */}
                        {this.shouldShowInjuryEditor() && <InjuryEditor
                            case={this.props.match.params.case}
                            subdir={this.props.match.params.subdir}
                            file={this.props.match.params.file}
                            reload={this.componentDidMount}
                            hidden={this.state.editorHidden}
                            width={this.state.editorHidden ? 0 : this.state.editorWidth}
                            minWidth={MIN_EDITOR_WIDTH}
                            maxWidth={MAX_EDITOR_WIDTH}
                            toggleOpen={this.toggleEditor}
                            updateWidth={this.updateEditorWidth}
                        />}

                        {/* File Display */}
                        <div className={"accessContainer"}>
                            {this.state.localPath !== '' ? this.renderFile() : <div className="loadingIconContainer">
                                <Loading type="spin" color="rgba(230,155,30,0.5)" height={'120px'} width={'120px'} />
                            </div>}

                            {this.state.note !== '' || this.state.newNote ? <div className="noteMemoContainer">

                                <textarea placeholder="Enter suggestion here..." value={this.state.note} onChange={(e) => this.setState({ note: e.target.value })}></textarea>

                                <img className="noteMemoMic" src="/resources/icons/voiceMemo.png" alt="Record..." title="Start/Pause recording" onClick={() => this.micClicked()} />

                                {this.state.recording ? <div className="noteMemoRecording">Transcribing...</div> : ''}

                                <div className="noteMemoSaveButton" onClick={() => this.onSaveNote()}>Save</div>
                            </div> : ''}
                            
                        </div>

                    </div> :
                
                // Directory navigator
                <div className="accessDirectory">
                    <div className="accessContentView">

                    {/* Folders first */}
                    {this.state.folders.map((folder,idx) => <ContextMenuTrigger key={"folder-" + idx} id={"context-trigger-folder-"+idx}>
                        <div title={folder} key={idx} className="accessFile" onClick={() => this.props.history.push(this.buildURLPathToFolder(folder))}>
                            <img src="/resources/icons/fileTypes/folder.png" alt={folder} /> <br />
                            {folder}
                        </div>
                    </ContextMenuTrigger>)}

                    {/* Files */}
                    {this.state.files.map((file,idx) => <ContextMenuTrigger key={"file-" + idx} id={"context-trigger-file-"+idx}>
                        <div title={file} key={idx} className="accessFile" onClick={() => this.props.history.push(this.buildURLPathToFile(file))}>
                            <img src={this.getFileIconPath(file)} alt={file} /> <br />
                            {file}
                        </div>
                    </ContextMenuTrigger>)}

                    {/* Upload Files */}
                    {(this.props.user && (this.props.user.type === "ops" || "lead") && this.props.match.params.subdir) ? 
                        <div className="accessFile">
                            <Dropzone 
                                key={'case-' + this.props.match.params.case + '_upload' + this.props.match.params.subdir} 
                                onDrop={files => this.uploadMultipleFiles(files)}
                            >
                                {({getRootProps, getInputProps, isDragActive}) => (
                                    <div {...getRootProps({className: 'dropzone'})} style={isDragActive ? { opacity: '1' } : {}} >
                                        <input {...getInputProps()} />
                                        <img src="/resources/icons/upload.png" alt="Select File" />

                                        <div className="documentType">Upload Files</div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    : ""}

                    {/* Folder context menus */}
                    {this.state.folders.map((folder,idx) => <ContextMenu key={"folder-" + idx} id={"context-trigger-folder-"+idx}>

                        {/* Universial folder menus */}
                        <MenuItem data={{
                            url: this.buildURLPathToFolder(folder)
                        }} onClick={this.onOpen}>
                            Open folder
                        </MenuItem>
                        <MenuItem data={{
                            url: this.buildURLPathToFolder(folder)
                        }} onClick={this.onNewTab}>
                            Open in new tab
                        </MenuItem>

                        {this.userAllowedToEdit() ? <div>

                            <MenuItem data={{
                                folder: folder
                            }} onClick={async (e, data) => {
                                this.setState({
                                    renamedFolderOld: data.folder,
                                    renamedFolderNew: data.folder,
                                    movedFolderNew: null,
                                    newFolderName: null,
                                    movedFileNew: null,
                                    renamedFileNew: null
                                });
                            }}>
                                Rename folder
                            </MenuItem>
                            <MenuItem data={{
                                folder: folder
                            }} onClick={async (e, data) => {
                                this.setState({
                                    movedFolderOld: this.getAbsolutePath(data.folder),
                                    movedFolderNew: this.getAbsolutePath(data.folder),
                                    renamedFolderNew: null,
                                    newFolderName: null,
                                    movedFileNew: null,
                                    renamedFileNew: null
                                });
                            }}>
                                Move folder
                            </MenuItem>
                        </div> : ""}

                        {this.userAllowedToDelete() ? <div>

                            <MenuItem data={{
                                folder: folder
                            }} onClick={async (e, data) => {
                                await this.onDeleteFolder(data.folder);
                                toast.info("Deleted folder: " + data.folder)
                            }}>
                                Delete folder
                            </MenuItem>
                        </div> : ""}


                    </ContextMenu>)}

                    {/* File context menus */}
                    {this.state.files.map((file,idx) => <ContextMenu key={"file-" + idx} id={"context-trigger-file-"+idx}>

                        {/* Universial file menus */}
                        <MenuItem data={{
                            url: this.buildURLPathToFile(file)
                        }} onClick={this.onOpen}>
                            Open file
                        </MenuItem>
                        <MenuItem data={{
                            url: this.buildURLPathToFile(file)
                        }} onClick={this.onNewTab}>
                            Open in new tab
                        </MenuItem>

                        {this.userAllowedToEdit() ? <div>

                            <MenuItem data={{
                                file: file
                            }} onClick={async (e, data) => {
                                this.setState({
                                    renamedFileOld: data.file,
                                    renamedFileNew: data.file,
                                    movedFolderNew: null,
                                    newFolderName: null,
                                    renamedFolderNew: null,
                                    movedFileNew: null
                                });
                            }}>
                                Rename file
                            </MenuItem>
                            <MenuItem data={{
                                file: file
                            }} onClick={async (e, data) => {
                                this.setState({
                                    movedFileOld: this.getAbsolutePath(data.file),
                                    movedFileNew: this.getAbsolutePath(data.file),
                                    renamedFolderNew: null,
                                    newFolderName: null,
                                    movedFolderNew: null,
                                    renamedFileNew: null
                                });
                            }}>
                                Move file
                            </MenuItem>
                        </div> : ""}

                        {this.userAllowedToDelete() ? <div>
                            <MenuItem data={{
                                file: file
                            }} onClick={async (e, data) => {
                                await this.onDeleteFile(data.file);
                                toast.info("Deleted file: " + data.file)
                            }}>
                                Delete file
                            </MenuItem> 

                        </div> : ""}


                    </ContextMenu>)}
                    </div>
                    { this.renderSidebar() }
                </div>
                }

                {/* Enter access key */}
                {!this.state.authSuccess ? <div ref="accessKeyOverlay" className="coverOverlay" style={{ display: 'block' }}>
                    <div className="accessKeyForm">
                        
                        <input ref="accessKeyInput" type="text" name="accessKey" value={this.state.accessKey} onChange={(e) => this.setState({ accessKey: e.target.value })} placeholder="Enter Access Key..." />

                        <div className="accessKeySubmitButton" onClick={() => this.onSubmitAccessKey()}>Submit</div>

                    </div>
                </div> : ''}

                {this.state.modalOverlay && this.props.match.params.subdir === undefined && this.state.accessKey !== "" && this.state.authSuccess && this.state.modalOverlay && <OnePortalAccessRoot
                    studyTypes={this.state.folders}
                    unsetOverlay = {this.unsetOverlay}
                    buildURLPathToFolder ={this.buildURLPathToFolder}
                    history={this.props.history}
                    onDownloadZip={this.onDownloadZip}
                    zipExists={this.state.zipExists}
                />}

                {this.state.modalOverlay && this.props.match.params.subdir !== undefined && this.state.accessKey !== "" && this.state.authSuccess && this.state.modalOverlay && <OnePortalAccessStudy
                    studyType={this.props.match.params.subdir}
                    unsetOverlay = {this.unsetOverlay}
                    history={this.props.history}
                    buildURLPathToFolder ={this.buildURLPathToFolder}
                    buildURLPathToFile ={this.buildURLPathToFile}
                    project={this.state.projectMap.get(this.props.match.params.subdir)}
                    rwrPath={(() => {
                        const rwr = this.state.files.find(f => f.includes("RWR"));
                        return rwr ? this.buildURLPathToFile(rwr) : null
                    })()}
                /> }

                {!this.state.modalOverlay  && this.state.localPath === "" && <button className="endUserBackButton" onClick={()=>{this.setState({modalOverlay: true})}}>
                    Back to Menu
                </button>}

                {/* <div className="credits" onClick={() => window.open('https://aptus.aero', '_blank')}>
                    <div className="creditsText">Developed by</div>
                    <img src="/resources/aptus/logo.png" alt="Aptus Engineering, Inc." />
                </div> */}

            </div>
        );
    }

}