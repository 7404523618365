// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Switch from 'react-switch';
import Loading from 'react-loading';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { toast } from 'react-toastify';
import api from '../../../api';

import moment from 'moment';

import '../../../styles/Users.css';

export default class CenterCard extends Component {


    state = {

        // Report Generation
        reportDialogOpen : false,
        reportFocusedInput: null,
        reportStartDate: null,
        reportEndDate: null,
        downloadingReport: false,
    }

    toggleReportDialog = () => {

        // Set the initial focused date
        if (!this.state.reportDialogOpen) {
            this.setState({
                reportFocusedInput: 'startDate',
            })
        }

        // Toggle open setting
        this.setState(prevState => ({
            reportDialogOpen : !prevState.reportDialogOpen
        }))
        
    }

    // Handle change of calendar focus
    onReportDatesFocusChange = (focusedInput) => {

        // Set the new focused input? 
        this.setState({reportFocusedInput: focusedInput})

        // Set the opened dialog flag
        if (focusedInput === null)
            this.setState({reportDialogOpen: false});

    }

    // Setting Report Date State
    setReportDates = async ({startDate, endDate}) => {

        // Update start and end dates
        await this.setState({
            reportStartDate: startDate, 
            reportEndDate: endDate
        });

        // Dates not complete
        if (!startDate || !endDate) 
            return;

        // Generate the report!
        else 
            this.generateReport(startDate, endDate, this.props.center.group);


    }

    generateReport = async (startDate, endDate, centerGroup) => {

        // Set downloading, and clear state
        this.setState({
            reportStartDate: null,
            reportEndDate: null,
            reportDialogOpen: false,
            downloadingReport: true
        })


        // Download report from API
        let res = await api.download('/generateCenterReport', {
            center: centerGroup,
            startDate: startDate,
            endDate: endDate
        })

        if (res && res.type === 'text/csv') {

            // Create CSV URL
            let blob = new Blob([res], {type: 'text/csv'});
            let csvURL = window.URL.createObjectURL(blob);

            let elem = document.createElement("a");
            document.body.appendChild(elem);
            elem.style.display = "none";
            elem.download = this.props.center.group +  '.csv';
            elem.target = '_blank';
            elem.href = csvURL;

            // Download
            elem.click();

            // Revoke URL
            window.URL.revokeObjectURL(csvURL);
        }

        else {
            toast.error("Could not find projects in the given date range.");
        }

        // Finished loading 
        this.setState({
            downloadingReport: false
        })
    }


    render() {

        const center = this.props.center;

        return (
            
            <div className={"centerCard"  + (this.props.changed ? " centerInfoChanged" : "")}>

                <div className="centerCardLabel">Group</div>
                <input type="text" name="newCenterGroup" placeholder="Enter center group" value={center.group} onChange={(e) => this.props.updateCenterInfo(center._id, "group", e.target.value)} /><br />

                <div className="centerCardLabel">Client</div>
                <select className="centerCardSelect" value={center.defaultClient} onChange={(e) => this.props.updateCenterInfo(center._id, "defaultClient", e.target.value)}>
                    <option value="">Select Client</option>
                    {this.props.clients.map((client,idx) => 
                        <option key={idx} value={client._id}>{client.name}</option>
                    )}
                </select><br />

                <div className="centerCardLabel">Docs</div>
                <div className="switchContainer">
                    <Switch className="switchCheckbox" onChange={checked => this.props.updateCenterInfo(center._id, "prepareMBA", checked)} checked={center.prepareMBA} height={20} width={40} />
                    <div className="switchLabel">Prepare MBA</div>
                </div><br />

                <div className="centerCardLabel" style={{ opacity:0 }}>_</div>
                <div className="switchContainer">
                    <Switch className="switchCheckbox" onChange={checked => this.props.updateCenterInfo(center._id, "prepareHCFA", checked)} checked={center.prepareHCFA} height={20} width={40} />
                    <div className="switchLabel">Prepare HCFA</div>
                </div><br />

                <div className="centerCardLabel" style={{ opacity:0 }}>_</div>
                <div className="switchContainer">
                    <Switch className="switchCheckbox" onChange={checked => this.props.updateCenterInfo(center._id, "prepareMRA", checked)} checked={center.prepareMRA} height={20} width={40} />
                    <div className="switchLabel">Prepare MRA</div>
                </div>

                {this.props.changed ? <div className="centerButton centerSaveButton" onClick={() => this.props.saveCenterInfo(center._id)}>Save</div> : ''}

                <div className="centerButton centerReportButton" onClick={this.toggleReportDialog}>
                    {this.state.downloadingReport ? <Loading className='reportLoadingIcon' type="spin" color="rgb(80,80,80)" height={'18px'} width={'17px'} /> :  'Report'}
                </div>  

                <div className="centerButton centerDeleteButton" onClick={() => this.props.deleteCenter(center._id)}>Delete</div>

                {/* Report Generation Dialog */}
                {this.state.reportDialogOpen ? 
                
                    <div className='reportDateContainer' style={{position: 'absolute', zIndex: 5}}>
                        <DateRangePicker 

                            startDate={this.state.reportStartDate}
                            startDateId={'0'}
                            endDate={this.state.reportEndDate}
                            endDateId={'1'}
                            onDatesChange={this.setReportDates}
                            focusedInput={this.state.reportFocusedInput}
                            onFocusChange={this.onReportDatesFocusChange}

                            minDate={moment().subtract(10, 'year')}
                            maxDate={moment().add(1, 'year').subtract(1, 'M')}
                            numberOfMonths={2}
                            isOutsideRange={day => !isInclusivelyBeforeDay(day, moment().add(1, 'day'))}
                        />
                    </div>

                : ''}

            </div>
        );
    }

}