// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import ProjectsPortal from '../../ProjectsPortal';
import EndUserProject from './EndUserProject';
import DicomUploadConsole from '../../DicomUpload';

export default class EndUserPortal extends ProjectsPortal {


    constructor(props) {

        super(props);

        this.columns = {
            "case": { label: "Case", width: 8, maxWidth: 60},
            "patientName": { label: "Patient Name", width: 16, maxWidth: 300},
            "patientSex": { label: "Sex", width: 4, maxWidth: 100},
            "patientDateOfBirth": { label: "DOB", width: 7, maxWidth: 140},
            "centerName": { label: "MRI Center", width: 16, maxWidth: 300},
            "studyDate": { label: "Study Date", width: 7, maxWidth: 140},
            "receiveDate": { label: "Received", width: 7, maxWidth: 100 },
            "lastAccess": { label: "Modified", width: 7, maxWidth: 100 },
            "studyType": { label: "Study Type", width: 7, maxWidth: 140},
            "status": { label: "Status", width: 10, maxWidth: 140}
        };

    }


    componentDidMount = async () => {

        // Fetch projects
        let res = await this.fetchProjects();

        if (!res || !res.auth)
            return;

        if (!res.auth._id)
            window.location = "/login";

        else if (res.auth.type !== 'endUser')
            window.location = '/';

    }


    renderProjects = () => {

        return (
            <div>
                {/* Sorted/ Filtered projects */}
                {this.state.displayProjects.map(idx => (
                    <EndUserProject user={this.props.user} key={this.state.projects[idx]._id} project={this.state.projects[idx]} columns={this.columns} onUpdateProjects={this.updateProjects} />
                ))}

                <DicomUploadConsole user={this.props.user} />

            </div>
        );

    }


}