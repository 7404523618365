// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import ProjectsPortal from '../../ProjectsPortal';
import LeadProject from './LeadProject';
import JobsManager from '../../JobManager';

import api from '../../../api';

export default class LeadPortal extends ProjectsPortal {

    constructor(props) {

        super(props);

        this.columns = {
            "case": { label: "Case", width: 8, maxWidth: 60},
            "patientName": { label: "Patient Name", width: 12, maxWidth: 320},
            "patientSex": { label: "Sex", width: 4, maxWidth: 100},
            "patientDateOfBirth": { label: "DOB", width: 6, maxWidth: 100},
            "centerName": { label: "MRI Center", width: 14, maxWidth: 320},
            "studyDate": { label: "Study Date", width: 6, maxWidth: 100},
            "receiveDate": { label: "Received", width: 6, maxWidth: 100 },
            "lastAccess": { label: "Modified", width: 6, maxWidth: 100 },
            "studyType": { label: "Study Type", width: 6, maxWidth: 140},
            "radiologist": {label: "Radiologist", width: 10, maxWidth: 160},
            "assignee": { label: "Assignee", width: 10, maxWidth: 160},
            "status": { label: "Status", width: 10, maxWidth: 160}
        };

        this.iconOffset = '80px';

        this.state.selected = [];

        this.state.jobManager = {
            projectId: '',
            opened: false
        };


    }

    componentDidMount = async () => {

        // Fetch projects
        let res = await this.fetchProjects();

        if (!res || !res.auth)
            return;

        if (!res.auth._id)
            return window.location = "/login";

        if (res.auth.type !== 'lead')
            return window.location = "/";
        
        // Get users
        res = await api.post('getUsers');

        if (!res || !res.data)
            return;

        let users = {};

        for (let user of res.data)
            users[user._id] = {...user};


        await this.setState({
            users: users,
        });
    }

    // Field edit functions
    selectHandler = async (projectId, field, value) => {

        let res = await api.post('syncProject', {
            pId: projectId,
            projectDiff: {
                [field]: value
            }
        });

        if (!res || !res.success || !res.data)
            return;
        
        // Update
        this.updateProjects([res.data]);

    }

    renderProjects = () => {

        const jobProject = this.getProjectById(this.state.jobManager.projectId);

        return (
            <div>
                {/* Sorted/ Filtered projects */}
                {this.state.displayProjects.map(idx => (
                    <LeadProject
                        key={this.state.projects[idx]._id}
                        project={this.state.projects[idx]}
                        users={this.state.users}
                        columns={this.columns}
                        onUpdateProjects={this.updateProjects}
                        onSelect={this.selectHandler}
                        manageJobs={() => this.setState({
                            jobManager: {
                                projectId: this.state.projects[idx]._id,
                                opened: true
                            }
                        })}
                        openedJob={this.state.jobManager.opened ? this.state.jobManager.projectId : ''}
                    />
                ))}

                <JobsManager
                    opened={this.state.jobManager.opened}
                    searchEnabled={this.state.searchEnabled}
                    closeJobManager={() => this.setState({
                        jobManager: {
                            opened: false
                        }
                    })}
                    updateJob={this.updateJob}
                    pId={jobProject ? jobProject._id : null}
                    project={jobProject ? jobProject : null}
                    job={jobProject ? jobProject.ai : null}
                    killTask={this.killJob}
                    users={this.state.users}
                />
            </div>
        );

    }

}